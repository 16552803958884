import { FirestoreClient, useMount } from '@client';
import { Button, Loading, Pressable, ScrollView, Text, View } from '@client/elements';
import DecisionTree from '@client/steps/DecisionTree';
import RichText from '@client/steps/RichText';
import * as eventTypes from '@shared/event/types';
import * as stepTypes from '@shared/step/types';
import { useCallback, useState } from 'react';

const HEIGHT_OFFSET = 110;

export function Step({
  onStepBack,
  onStepNext,
  onStepStart,
  ...props
}: Omit<stepTypes.Props, 'onPrepareCompletion'> & {
  onStepBack?: () => void;
  onStepNext?: () => void;
  onStepStart: () => Promise<void>;
}) {
  const { companyId } = props;
  const { subtitle, title } = props.step.props;

  const [completion, setCompletion] = useState<stepTypes.Completion>();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<eventTypes.EventFirestore[]>();

  const onPrepareCompletion = useCallback(
    (payload: Partial<stepTypes.Completion>) => setCompletion((c) => ({ ...c, ...payload })),
    []
  );

  useMount(async () => {
    await onStepStart();
    setLoading(false);
    FirestoreClient.companyEvents({ companyId })
      .query({
        where: [
          {
            path: 'type',
            filter: '==',
            value: 'user_step_session_completed',
          },
        ],
      })
      .onSnapshot((snap) => setEvents(snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
  });

  if (loading || !events) return <Loading />;

  console.log('EVENTS: ', events);

  return (
    <ScrollView sx={{ maxHeight: `calc(100vh - ${HEIGHT_OFFSET}px)` }}>
      <View sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Button
          disabled={!onStepBack}
          label="Back"
          onPress={onStepBack}
          sx={{ width: 100 }}
          variant="outlined"
        />
        <Button
          disabled={!onStepNext}
          label="Next"
          onPress={onStepNext}
          sx={{ width: 100 }}
          variant="outlined"
        />
        <Pressable disabled={!onStepNext} onPress={onStepNext}>
          <Text>Next</Text>
        </Pressable>
      </View>
      <View sx={{ display: 'flex', flexDirection: 'column' }}>
        <Text variant="h5">{title}</Text>
        {subtitle && <Text variant="p">{subtitle}</Text>}
      </View>
      {renderContent({ ...props, onPrepareCompletion })}
      {completion && (
        <Button label="Complete Step" onPress={() => props.onStepComplete(completion)} />
      )}
    </ScrollView>
  );
}

function renderContent({ step, ...props }: stepTypes.Props) {
  switch (step.name) {
    case 'DecisionTree': {
      return <DecisionTree step={step} {...props} />;
    }
    case 'RichText': {
      return <RichText step={step} {...props} />;
    }
    default:
      return <Text>Step Type Not Supported Yet</Text>;
  }
}
