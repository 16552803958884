import { handleError, SalesforceClient } from '@client';
import * as connectionTypes from '@shared/connection/types';
import * as connectionUtils from '@shared/connection/utils';
import * as environmentTypes from '@shared/environment/types';
import * as userTypes from '@shared/user/types';
import * as variableTypes from '@shared/variable/types';
import * as variableUtils from '@shared/variable/utils';
import _ from 'lodash';
import { DateTime } from 'luxon';

// export function handleRequestOptions({
//   environment,
//   sources,
// }: {
//   environment: environmentTypes.Environment;
//   sources: variableTypes.Sources;
// }) {
//   switch (environment) {
//     case 'zendesk':
//     default:
//   }
// }

function checkCache({
  accessor,
  cache,
  useLabel,
}: {
  accessor: string;
  cache: variableTypes.Cache;
  useLabel: boolean;
}) {
  const label = _.get(cache.labels, accessor);
  const value = _.get(cache.values, accessor);
  if (useLabel && label) return { cache, data: label, error: null };
  if (!useLabel && value) return { cache, data: value, error: null };
}

function updateCache({
  cache,
  path,
  update,
}: {
  cache: variableTypes.Cache;
  path: string;
  update: Partial<variableTypes.Cache>;
}) {
  if (update.fields) _.set(cache, `fields.${path}`, update.fields);
  if (update.values) _.set(cache, `labels.${path}`, update.labels);
  if (update.labels) _.set(cache, `values.${path}`, update.values);
}

async function handleFetchResult({
  accessor,
  cache,
  companyId,
  connectionKey,
  environment,
  format,
  useLabel,
  userId,
}: {
  accessor: string;
  cache: variableTypes.Cache;
  companyId: string;
  connectionKey: string;
  environment: environmentTypes.Environment;
  format: variableTypes.Format;
  // instance?: string;
  useLabel: boolean;
  userId: string;
}): Promise<{
  cache: variableTypes.Cache;
  data: variableTypes.Result | null;
  error: { details?: any; message: string } | null;
}> {
  try {
    // First try to grab the result from cache
    checkCache({ accessor, cache, useLabel });

    const { integration, object } = variableUtils.getAccessorSource(accessor);
    const path = `${integration}.${object}`;

    if (connectionKey) {
      const connection = connectionUtils.getElements(connectionKey);

      switch (integration) {
        // case 'freshchat': {
        //   switch (object) {
        //     case 'agent':
        //     case 'conversation':
        //     case 'customer': {
        //       await freshchatServices.handleVariableResults({
        //         agentId,
        //         cache,
        //         companyId,
        //         connection,
        //         customerId,
        //         userId,
        //       });
        //       break;
        //     }
        //   }
        //   break;
        // }
        // case 'freshdesk': {
        //   switch (object) {
        //     case 'agent':
        //     case 'customer':
        //     case 'ticket': {
        //       await freshdeskServices.handleVariableResults({
        //         agentId,
        //         cache,
        //         companyId,
        //         connection,
        //         customerId,
        //       });
        //       break;
        //     }
        //     // case 'cannedResponse':
        //     //   const { data } = await freshdeskServices.handleEndpointRequest({ companyId, endpoint: {
        //     //     integration: 'freshdesk',
        //     //     type: 'freshdesk-fetch-canned-response',
        //     //     request: {
        //     //       id: ,
        //     //       instance,
        //     //     }
        //     //   }});
        //     //   break;
        //   }
        //   break;
        // }
        case 'salesforce': {
          if (environment === 'salesforce') {
            const {
              environment: { parameters },
              links: { sobjectUrl },
            } = await SalesforceClient.context();
            const data = await SalesforceClient.ajax(`${sobjectUrl}${object}/${parameters.record}`);
            console.log({ data });
            updateCache({
              cache,
              path: `salesforce.${object}`,
              update: { labels: data, values: data },
            });
          } else {
          }
          break;
        }
      }
    }

    // Check for the cached result again
    checkCache({ accessor, cache, useLabel });

    // If still not found return error
    return { cache, data: null, error: { message: 'No result found' } };
  } catch (err) {
    return { cache, data: null, error: handleError(err) };
  }
}

async function handleFetchResults({
  cache: _cache,
  companyId,
  connectionKey,
  environment,
  request,
  user,
}: {
  cache?: Partial<variableTypes.Cache>;
  companyId: string;
  connectionKey: string;
  environment: environmentTypes.Environment;
  request: variableTypes.ResultsRequest;
  user: userTypes.User;
}) {
  let cache: variableTypes.Cache = {
    fields: _cache?.fields || {},
    labels: _cache?.labels || {},
    values: _cache?.values || {},
  };

  const now = DateTime.now();
  const connection = connectionUtils.getElements(connectionKey);

  const defaults = {
    connection,
    currentDate: now.toFormat('MM-DD-YYYY'),
    currentDateISO: now.toISODate(),
    currentDateTimeISO: now.toISO(),
    currentDay: now.weekdayLong,
    currentMonth: now.toFormat('MM'),
    currentDayOf: now.day,
    currentMonthName: now.monthLong,
    currentYear: now.year,
    user: {
      email: user.email,
      firstName: user.firstName,
      id: user.id,
      lastName: user.lastName,
    },
  };

  _.merge(cache, { labels: defaults, values: defaults });

  const variables = variableUtils.buildVariablesArray({ request });
  console.log({ request, variables });

  for (const { accessor, format, raw } of variables) {
    const result = await handleFetchResult({
      accessor,
      cache,
      companyId,
      connectionKey,
      environment,
      format,
      useLabel: !raw,
      userId: user.id,
    });
    cache = result.cache;
  }

  return variableUtils.buildResultsObject({ cache, request });
}

export const VariableClient = {
  fetchResult: handleFetchResult,
  fetchResults: handleFetchResults,
};
