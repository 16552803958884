import { ApiClient, AuthClient, SalesforceClient, useMount } from '@client';
import { CompanyProvider } from '@client/context';
import { Loading, Text, View } from '@client/elements';
import { Field, Form } from '@client/form';
import { Header as SalesforceArenaQualityHeader } from '@client/powerq/SalesforceArenaQuality/Header';
import { storage } from '@shared';
import * as companyTypes from '@shared/company/types';
import * as environmentTypes from '@shared/environment/types';
import * as powerqTypes from '@shared/powerq/types';
import * as salesforceTypes from '@shared/salesforce/types';
import * as userTypes from '@shared/user/types';
import _ from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface Props {
  children: React.ReactNode;
  environment: environmentTypes.Environment;
  powerq?: powerqTypes.Type;
  user: userTypes.User;
}

interface Value {
  connectionId: string | null;
  environment: environmentTypes.Environment;
  kustomer: typeof Kustomer | null;
  onCreateCompany: (payload: companyTypes.CompanyRequest) => Promise<void>;
  salesforce: salesforceTypes.LocalClient | null;
  user: userTypes.User;
  userId: string;
}

const UserContext = createContext<Value | undefined>(undefined);

export const UserProvider = ({ children, environment, powerq, user }: Props) => {
  const [companies, setCompanies] = useState<companyTypes.Company[]>();
  const [company, setCompany] = useState<companyTypes.Company>();
  const [connectionId, setConnectionId] = useState<string | null>(null);
  const [kustomer, setKustomer] = useState<typeof Kustomer | null>(null);
  const [initialValuesCompany, setInitialValuesCompany] = useState<{
    name: string;
    phone: string;
  }>();
  const [loaded, setLoaded] = useState(false);
  const [salesforce, setSalesforce] = useState<salesforceTypes.LocalClient | null>(null);

  const needsCreateCompany = useMemo(
    () => _.size(companies) === 0 && !company && loaded,
    [companies, company, loaded]
  );
  const userId = useMemo(() => user.id, [user.id]);

  const onCreateCompany = useCallback(async (payload: companyTypes.CompanyRequest) => {
    const { data } = await ApiClient.companies.post(payload);
    setCompanies(data);
    if (_.size(data) === 1) {
      setCompany(data[0]);
    }
  }, []);

  useMount(async () => {
    try {
      const { data } = await ApiClient.companies.get();
      if (_.size(data) === 0) {
      } else if (_.size(data) === 1) {
        setCompany(data[0]);
      }
      setCompanies(data);
      switch (environment) {
        case 'kustomer': {
          Kustomer.initialize(async (context) => {
            const kustomerConversation: string | undefined =
              context?.conversation?.relationships?.org?.data?.id;
            const kustomerOrganization: string | undefined =
              context?.conversation?.relationships?.org?.data?.id;
            if (kustomerConversation) {
              setConnectionId('kustomer_conversation_' + kustomerConversation);
            }
            if (kustomerOrganization) {
              await storage.setItem('kustomerOrganization', kustomerOrganization);
            }
          });
          break;
        }
        case 'salesforce': {
          const context = await SalesforceClient.context();
          const { object, record } = context.environment.parameters;
          if (!object) throw new Error('Missing Salesforce Object Context');
          if (!record) throw new Error('Missing Salesforce Record Context');
          setConnectionId(`salesforce_${object}_${record}`);
          setInitialValuesCompany({ name: context.organization.name, phone: '' });
          break;
        }
        default: {
          setInitialValuesCompany({ name: '', phone: '' });
        }
      }
      setLoaded(true);
    } catch {
      // TODO: Persist error message
      AuthClient.signOut();
    }
  });

  const value = useMemo(
    () => ({ connectionId, environment, kustomer, onCreateCompany, salesforce, user, userId }),
    [connectionId, environment, kustomer, onCreateCompany, salesforce, user, userId]
  );

  if (!connectionId || !loaded) return <Loading type="skelton" />;

  if (needsCreateCompany && initialValuesCompany) {
    switch (powerq) {
      case 'salesforce-arena-quality': {
        return (
          <View sx={{ p: '$2' }}>
            <SalesforceArenaQualityHeader />
            <Form<{
              name: string;
              phone: string;
            }>
              // label="Create Company"
              initialValues={initialValuesCompany}
              labelSubmit="Continue"
              onSubmit={onCreateCompany}
              schema={{
                name: { required: true },
                // phone: { required: true },
              }}
            >
              <Field label="Confirm Company Name" name="name" sx={{ mb: '$3' }} type="plain-text" />
              {/* <Field label="Phone Number" name="phone" type="plain-text" /> */}
            </Form>
          </View>
        );
      }
      default:
        return (
          <Form<{ name: string; phone: string }>
            label="Create Company"
            initialValues={{ name: '', phone: '' }}
            onSubmit={onCreateCompany}
            schema={{
              name: { required: true },
              phone: { required: true },
            }}
          >
            <Field label="Company Name" name="name" type="plain-text" />
            <Field label="Phone Number" name="phone" type="plain-text" />
          </Form>
        );
    }
  }

  // If no company, create or select one
  if (!company) {
    return <Text>Select Company</Text>;
  }

  return (
    <UserContext.Provider value={value}>
      <CompanyProvider company={company} userId={userId}>
        {children}
      </CompanyProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be within UserProvider');
  }
  return context;
};
