import { useMount } from '@client';
import { useVariable } from '@client/context';
import { Button, Loading, Html, Text, View } from '@client/elements';
import * as stepTypes from '@shared/step/types';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export default function DecisionTree({
  connectionKey,
  instance,
  onStepComplete,
  step: {
    props: { decisions, richtext },
  },
}: stepTypes.Props<'DecisionTree'>) {
  const { onRequestResults } = useVariable();

  const [html, setHtml] = useState<string>();

  const handleSelectDecision = useCallback(
    async (branch: number) => {
      onStepComplete({ branch, html });
    },
    [html, onStepComplete]
  );

  useMount(async () => {
    const results = await onRequestResults({
      connectionKey,
      instance,
      variables: { html: richtext || '' },
    });
    setHtml(results.html as string);
  });

  if (_.isNil(html)) return <Loading />;

  return (
    <View>
      <Html html={html} />
      {_.map(decisions, (decision, branch) => (
        <Button key={branch} label={decision.label} onPress={() => handleSelectDecision(branch)} />
      ))}
    </View>
  );
}
