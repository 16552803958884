export interface Props {
  height: number;
  width: number;
  style?: React.CSSProperties;
}

export function FreshworksIcon(props: Props) {
  return (
    <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#44a3eb"
        fillOpacity="1"
        opacity="1"
        fillRule="evenodd"
        stroke="none"
        id="3"
        d="M39.5684,79.4117 C39.5684,79.4117,39.5684,79.4117,39.5684,79.4117 C17.6547,79.4117,-1.05008e-05,61.6883,-1.05008e-05,39.7058 C-1.05008e-05,39.7058,-1.05008e-05,39.7058,-1.05008e-05,39.7058 C-1.05008e-05,17.7234,17.7234,1.18595e-05,39.5684,1.18595e-05 C49.5063,1.18595e-05,59.4442,1.18595e-05,69.3821,1.18595e-05 C74.9464,1.18595e-05,79.4116,4.4652,79.4116,10.0295 C79.4116,19.9216,79.4116,29.8137,79.4116,39.7058 C79.343,61.6883,61.6884,79.4117,39.5684,79.4117 z"
      />
      <path
        fill="#ffffff"
        fillOpacity="1"
        opacity="1"
        fillRule="evenodd"
        stroke="none"
        id="5"
        d="M52.071,23.3564 C45.4533,23.3564,38.8357,23.3564,32.2181,23.3564 C26.585,23.3564,22.1885,27.8215,22.1885,33.3859 C22.1885,39.0647,22.1885,44.7435,22.1885,50.4223 C22.1885,51.5901,23.1503,52.5518,24.3181,52.5518 C33.5004,52.5518,42.6827,52.5518,51.8649,52.5518 C57.4979,52.5518,61.8944,48.0867,61.8944,42.5223 C61.8944,42.5223,61.8944,33.3859,61.8944,33.3859 C61.8257,27.8215,57.4292,23.3564,52.071,23.3564 z M44.7893,44.5832 C39.9577,44.5832,35.1261,44.5832,30.2946,44.5832 C29.1268,44.5832,28.4398,43.6215,28.4398,42.7285 C28.4398,41.8353,29.4015,40.8736,30.2946,40.8736 C35.1261,40.8736,39.9577,40.8736,44.7893,40.8736 C45.9571,40.8736,46.644,41.8353,46.644,42.7285 C46.644,43.6215,45.9571,44.5832,44.7893,44.5832 z M53.651,35.2406 C45.8655,35.2406,38.0801,35.2406,30.2946,35.2406 C29.1268,35.2406,28.4398,34.2789,28.4398,33.3859 C28.4398,32.218,29.4015,31.5311,30.2946,31.5311 C38.0801,31.5311,45.8655,31.5311,53.651,31.5311 C54.8188,31.5311,55.5057,32.4928,55.5057,33.3859 C55.5057,34.2789,54.6127,35.2406,53.651,35.2406 z"
      />
      <path
        fill="#24c16e"
        fillOpacity="1"
        opacity="1"
        fillRule="evenodd"
        stroke="none"
        id="8"
        d="M79.7031,39.7062 C89.7549,39.7062,99.8068,39.7062,109.859,39.7062 C112.552,39.6829,115.142,40.7425,117.047,42.6471 C118.951,44.5516,120.011,47.1414,119.987,49.8347 C119.987,59.8866,119.987,69.9385,119.987,79.9904 C119.987,90.5909,115.774,100.767,108.273,108.272 C100.772,115.776,90.5979,119.995,79.9875,120 C79.9117,120,79.8358,120,79.76,120 C69.1416,120.012,58.9538,115.803,51.4396,108.301 C43.9258,100.797,39.7044,90.614,39.7058,79.9953 C39.7058,57.8238,57.5885,39.9412,79.7154,39.7136 z"
      />
      <path
        fill="#ffffff"
        fillOpacity="1"
        opacity="1"
        fillRule="evenodd"
        stroke="none"
        id="10"
        d="M79.7031,57.5888 C69.5498,57.5888,61.3184,65.8203,61.3184,75.9736 C61.3184,80.1404,61.3184,84.3072,61.3184,88.4741 C61.3942,91.819,64.0877,94.5125,67.4326,94.5883 C69.1664,94.5883,70.9003,94.5883,72.6341,94.5883 C72.6341,89.8064,72.6341,85.0245,72.6341,80.2427 C70.2926,80.2427,67.9512,80.2427,65.6097,80.2427 C65.6097,78.9046,65.6097,77.5664,65.6097,76.2283 C66.0376,68.6671,72.2928,62.7557,79.8663,62.7557 C87.4398,62.7557,93.7173,68.6919,94.1229,76.2283 C94.1229,77.5665,94.1229,78.9046,94.1229,80.2427 C91.751,80.2427,89.379,80.2427,87.007,80.2427 C87.007,85.0245,87.007,89.8064,87.007,94.5883 C88.5735,94.5883,90.1399,94.5883,91.7065,94.5883 C91.7065,94.6642,91.7065,94.7401,91.7065,94.8159 C91.657,97.92,89.1539,100.423,86.0498,100.473 C84.1783,100.473,82.3067,100.473,80.4352,100.473 C79.9801,100.473,79.478,100.7,79.478,101.158 C79.5027,101.652,79.9158,102.093,80.4352,102.115 C82.3208,102.115,84.2063,102.115,86.0918,102.115 C90.1123,102.089,93.3652,98.8363,93.3908,94.8159 C93.3908,94.6642,93.3908,94.5125,93.3908,94.3608 C96.1611,93.7374,98.0903,91.269,98.0903,88.4296 C98.0903,84.3543,98.0903,80.2789,98.0903,76.2036 C98.3451,65.8451,90.1334,57.5888,79.7031,57.5888 z"
      />
    </svg>
  );
}
