// import { Skeleton, View } from '@client/elements';
import { ActivityIndicator, SxProp } from 'dripsy';

export interface LoadingProps {
  sx?: SxProp;
  size?: number;
  type?: 'linear' | 'skelton';
}

export function Loading({ type, ...props }: LoadingProps) {
  return <ActivityIndicator {...props} size={50} sx={{ m: '$3' }} />;

  // switch (type) {
  //   case 'skelton':
  //     return (
  //       <View>
  //         <Skeleton animation="pulse" circle height={50} style={{ margin: 5 }} width={50} />
  //         <Skeleton animation="pulse" height={30} style={{ margin: 5 }} />
  //         <Skeleton animation="pulse" height={30} style={{ margin: 5 }} />
  //         <Skeleton animation="pulse" height={30} style={{ margin: 5 }} />
  //       </View>
  //     );
  //   default:
  //     return <LinearProgress {...props} />;
  // }
}
