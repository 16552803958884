import { FirestoreClient, useMount, usePrevious, useUnmount } from '@client';
import { Sidekick } from '@client/components';
import { useCompany, useEnvironment, useUser, VariableProvider } from '@client/context';
import { Loading } from '@client/elements';
import * as connectionTypes from '@shared/connection/types';
import * as environmentTypes from '@shared/environment/types';
import * as flowTypes from '@shared/flow/types';
import * as flowUtils from '@shared/flow/utils';
import * as stepTypes from '@shared/step/types';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  environment: environmentTypes.Environment;
}

interface Value {
  categories: { [id: string]: flowTypes.Category | undefined };
  connection: connectionTypes.Connection;
  connectionFlow: connectionTypes.Flow;
  connectionId: string;
  connectionFlowVersion: connectionTypes.Version;
  flow: flowTypes.Flow | null;
  flowId: string | null;
  flows: { [id: string]: flowTypes.Flow | undefined };
  isFlowComplete: boolean;
  isStepComplete: boolean;
  onStepBack?: () => void;
  onStepComplete: (payload?: stepTypes.Completion) => Promise<void>;
  onStepNext?: () => void;
  onStepStart: () => Promise<void>;
  onSelectFlowId: (id: string | null) => void;
  onSelectTestDrive: (payload: flowTypes.TestDrive | null) => void;
  onUpdateConnection: (payload: Partial<connectionTypes.Connection>) => Promise<void>;
  onUpdateConnectionFlow: (payload: Partial<connectionTypes.Flow>) => Promise<void>;
  onUpdateConnectionFlowVersion: (payload: Partial<connectionTypes.Version>) => Promise<void>;
  step: stepTypes.Step | null;
  stepId: string | null;
  stepIndex: number | null;
  steps: stepTypes.Step[] | null;
  version: flowTypes.Version | undefined;
  versionLoading: boolean;
}

const SidekickContext = React.createContext<Value | undefined>(undefined);

export const SidekickProvider = ({ environment }: Props) => {
  const { company, companyId } = useCompany();
  // const { connectionId } = useEnvironment();
  const { connectionId, user, userId } = useUser();

  const [categories, setCategories] = useState<{ [id: string]: flowTypes.Category | undefined }>();
  const [connection, setConnection] = useState<connectionTypes.Connection>();
  const [flowId, setFlowId] = useState<string | null>(null);
  const [flows, setFlows] = useState<{ [id: string]: flowTypes.Flow | undefined }>();
  const [stepId, setStepId] = useState<string | null>(null);
  const [testDrive, setTestDrive] = useState<flowTypes.TestDrive>();
  const [version, setVersion] = useState<flowTypes.Version>();
  // const [versionId, setVersionId] = useState<string | null>(null);
  const [versionLoading, setVersionLoading] = useState(false);

  const flow = useMemo(() => (flowId && flows?.[flowId]) || null, [flowId, flows]);
  const step = useMemo(
    () => (stepId && version?.steps?.[stepId]) || null,
    [stepId, version?.steps]
  );

  const versionKey = useMemo(() => {
    if (testDrive) {
      // Let test drive mode override the flow defaults
      return testDrive?.draft ? `${testDrive.versionId}_draft` : testDrive.versionId;
    }
    return flow?.versionPublishedId || null;
  }, [flow?.versionPublishedId, testDrive]);

  const connectionFlow = useMemo(
    () => (connection ? (flowId && connection.flows?.[flowId]) || {} : null),
    [connection, flowId]
  );
  const connectionFlowVersion = useMemo(
    () =>
      connection
        ? (flowId && versionKey && connection.flows?.[flowId]?.versions?.[versionKey]) || {}
        : null,
    [connection, flowId, versionKey]
  );
  const decisions = useMemo(
    () =>
      _.reduce(
        connectionFlowVersion?.steps,
        (m: connectionTypes.Decisions, s, id) =>
          typeof s?.branch === 'number' ? { ...m, [id]: s.branch } : m,
        {}
      ),
    [connectionFlowVersion]
  );

  const steps = useMemo(
    () =>
      (version &&
        flowUtils.buildStepsArray({
          decisions,
          version,
        })) ||
      null,
    [decisions, version]
  );
  const stepFirstStartedAt = useMemo(
    () =>
      (stepId && connectionFlowVersion?.steps?.[stepId]?.users?.[userId]?.firstStartedAt) || null,
    [connectionFlowVersion, stepId, userId]
  );
  const stepLastStartedAt = useMemo(
    () =>
      (stepId && connectionFlowVersion?.steps?.[stepId]?.users?.[userId]?.lastStartedAt) || null,
    [connectionFlowVersion, stepId, userId]
  );
  const versionFirstStartedAt = useMemo(
    () => connectionFlowVersion?.users?.[userId]?.firstStartedAt || null,
    [connectionFlowVersion, userId]
  );
  const versionLastStartedAt = useMemo(
    () => connectionFlowVersion?.users?.[userId]?.lastStartedAt || null,
    [connectionFlowVersion, userId]
  );
  const stepIndex = useMemo(() => {
    const _stepIndex = _.findIndex(steps, (s) => s.uid === stepId);
    return _stepIndex >= 0 ? _stepIndex : null;
  }, [stepId, steps]);
  const stepIdAfter = useMemo(
    () => (typeof stepIndex === 'number' && steps?.[stepIndex + 1]?.uid) || null,
    [stepIndex, steps]
  );
  const stepIdBefore = useMemo(
    () => (stepIndex && steps?.[stepIndex - 1]?.uid) || null,
    [stepIndex, steps]
  );

  const isFlowComplete = useMemo(
    () =>
      !!connectionFlowVersion &&
      !!steps &&
      !flowUtils.findFirstIncompleteStep({ connectionFlowVersion, steps }),
    [connectionFlowVersion, steps]
  );
  const isStepComplete = useMemo(
    () => !!(stepId && connectionFlowVersion?.steps?.[stepId]?.complete),
    [connectionFlowVersion, stepId]
  );

  const previousSteps = usePrevious(steps);
  const previousVersionKey = usePrevious(versionKey);

  const handleStepBack = useCallback(() => {
    if (stepIdBefore) {
      setStepId(stepIdBefore);
    }
  }, [stepIdBefore]);
  const handleStepNext = useCallback(() => {
    if (stepIdAfter) {
      setStepId(stepIdAfter);
    }
  }, [stepIdAfter]);

  const handleSelectFlowId = useCallback((id: string | null) => setFlowId(id), []);
  const handleSelectTestDrive = useCallback(
    (payload: flowTypes.TestDrive | null) => setTestDrive(payload || undefined),
    []
  );
  const handleUpdateConnection = useCallback(
    async (payload: Partial<connectionTypes.Connection>) => {
      if (connectionId) {
        FirestoreClient.companyConnections({ companyId })
          .doc(connectionId)
          .set(payload, { merge: true });
      }
    },
    [companyId, connectionId]
  );
  const handleUpdateConnectionFlow = useCallback(
    async (payload: Partial<connectionTypes.Flow>) => {
      if (connectionId && flowId) {
        return FirestoreClient.companyConnections({ companyId })
          .doc(connectionId)
          .set({ flows: { [flowId]: payload } }, { merge: true });
      }
    },
    [companyId, connectionId, flowId]
  );
  const handleUpdateConnectionFlowVersion = useCallback(
    async (payload: Partial<connectionTypes.Version>) => {
      if (connectionId && flowId && versionKey) {
        return FirestoreClient.companyConnections({ companyId })
          .doc(connectionId)
          .set({ flows: { [flowId]: { versions: { [versionKey]: payload } } } }, { merge: true });
      }
    },
    [companyId, connectionId, flowId, versionKey]
  );

  const handleStepComplete = useCallback(
    async (payload?: stepTypes.Completion) => {
      if (flowId && stepId && step && version && versionKey) {
        const completedAt = new Date().toISOString();
        const wasDecisionStep = step.name === 'DecisionTree';
        let wasFlowCompleted = false;
        const nextConnectionFlowVersion: connectionTypes.Version = {
          ...connectionFlowVersion,
          steps: {
            ...connectionFlowVersion?.steps,
            [step.uid]: { complete: true, inProgress: false },
          },
        };
        wasFlowCompleted = flowUtils.willFlowBeComplete({
          connectionFlowVersion: nextConnectionFlowVersion,
          decisions: {
            ...decisions,
            ...(wasDecisionStep && { [step.uid]: payload?.branch ?? 0 }),
          },
          stepId: step.uid,
          version,
        });
        // await FirestoreClient.runTransaction(async (t) => {

        // });
        await handleUpdateConnection({
          flows: {
            [flowId]: {
              versions: {
                [versionKey]: {
                  complete: wasFlowCompleted,
                  inProgress: !wasFlowCompleted,
                  steps: {
                    [step.uid]: {
                      complete: true,
                      inProgress: false,
                      users: { [userId]: { completedAt } },
                    },
                  },
                  users: { [userId]: { completedAt } },
                },
              },
            },
          },
        });
      }
    },
    [
      connectionFlowVersion,
      decisions,
      flowId,
      handleUpdateConnection,
      stepId,
      step,
      userId,
      version,
      versionKey,
    ]
  );
  const handleStepStart = useCallback(async () => {
    if (flowId && stepId && step && version && versionKey) {
      const lastStartedAt = new Date().toISOString();
      await handleUpdateConnection({
        flows: {
          [flowId]: {
            versions: {
              [versionKey]: {
                complete: false,
                firstStartedAt:
                  isStepComplete || !versionFirstStartedAt ? lastStartedAt : versionFirstStartedAt,
                inProgress: true,
                steps: {
                  [step.uid]: {
                    complete: false,
                    firstStartedAt:
                      isStepComplete || !stepFirstStartedAt ? lastStartedAt : stepFirstStartedAt,
                    inProgress: true,
                    users: {
                      [userId]: {
                        lastStartedAt,
                        ...(stepFirstStartedAt && { firstStartedAt: stepFirstStartedAt }),
                      },
                    },
                  },
                },
                users: {
                  [userId]: {
                    lastStartedAt,
                    ...(versionFirstStartedAt && { firstStartedAt: versionFirstStartedAt }),
                  },
                },
              },
            },
          },
        },
      });
      if (isStepComplete) {
        // If re-running a step that was previously completed, use a new session for analytics
      } else if (stepFirstStartedAt) {
        // If step is being restarted but hasn't been completed yet, log a restart event
      } else {
        // Assume step is being started for the first time
      }
    }
  }, [
    flowId,
    handleUpdateConnection,
    isStepComplete,
    step,
    stepFirstStartedAt,
    stepId,
    userId,
    version,
    versionFirstStartedAt,
    versionKey,
  ]);

  const unsubscribes = useRef<(() => void)[]>([]);
  const value: Value | null = useMemo(
    () =>
      categories && connection && connectionFlow && connectionFlowVersion && connectionId && flows
        ? {
            categories,
            connection,
            connectionFlow,
            connectionFlowVersion,
            connectionId,
            flow,
            flowId,
            flows,
            isFlowComplete,
            isStepComplete,
            onStepBack: handleStepBack,
            onStepComplete: handleStepComplete,
            onStepNext: handleStepNext,
            onStepStart: handleStepStart,
            onSelectFlowId: handleSelectFlowId,
            onSelectTestDrive: handleSelectTestDrive,
            onUpdateConnection: handleUpdateConnection,
            onUpdateConnectionFlow: handleUpdateConnectionFlow,
            onUpdateConnectionFlowVersion: handleUpdateConnectionFlowVersion,
            step,
            stepId,
            stepIndex,
            steps,
            version,
            versionLoading,
          }
        : null,
    [
      categories,
      connection,
      connectionFlow,
      connectionFlowVersion,
      connectionId,
      flow,
      flows,
      flowId,
      handleStepBack,
      handleStepComplete,
      handleStepNext,
      handleStepStart,
      handleSelectFlowId,
      handleSelectTestDrive,
      handleUpdateConnection,
      handleUpdateConnectionFlow,
      handleUpdateConnectionFlowVersion,
      isFlowComplete,
      isStepComplete,
      step,
      stepId,
      stepIndex,
      steps,
      version,
      versionLoading,
    ]
  );

  // When sidekick mounts, pull down categories & flows, and subscribe to the connection doc
  useMount(() => {
    FirestoreClient.companies
      .doc(companyId)
      .get()
      .then((doc) => {
        const data = doc.data();
        setCategories(data?.categories || {});
        setFlows(data?.flows || {});
      });
    if (connectionId) {
      unsubscribes.current.push(
        FirestoreClient.companyConnections({ companyId })
          .doc(connectionId)
          .onSnapshot((doc) => {
            const data = doc.data();
            if (data) {
              setConnection(data);
            } else {
              const createdAt = new Date().toISOString();
              handleUpdateConnection({
                createdAt,
              });
            }
          })
      );
    }
  });

  // Load new version when key changes
  useEffect(() => {
    (async () => {
      if (versionKey !== previousVersionKey && companyId && flowId) {
        setVersionLoading(true);
        if (versionKey) {
          const versionDoc = await FirestoreClient.companyFlowVersions({
            companyId,
            flowId,
          })
            .doc(versionKey)
            .get();
          setVersion(versionDoc.data());
        } else {
          setVersion(undefined);
        }
        setVersionLoading(false);
      }
    })();
  }, [companyId, flowId, previousVersionKey, versionKey]);

  // Select first incomplete step when they become available
  useEffect(() => {
    if (steps && !previousSteps && connectionFlowVersion) {
      const firstIncompleteStep = flowUtils.findFirstIncompleteStep({
        connectionFlowVersion,
        steps,
      });
      if (firstIncompleteStep) {
        setStepId(firstIncompleteStep.uid);
      }
    }
  }, [connectionFlowVersion, previousSteps, steps]);

  useUnmount(() => {
    for (const unsubscribe of unsubscribes.current) {
      unsubscribe();
    }
  });

  if (!value) return <Loading type="skelton" />;

  return (
    <SidekickContext.Provider value={value}>
      <VariableProvider company={company} environment={environment} user={user}>
        <Sidekick />
      </VariableProvider>
    </SidekickContext.Provider>
  );
};

export const useSidekick = () => {
  const context = useContext(SidekickContext);
  if (context === undefined) {
    throw new Error('useSidekick must be within SidekickProvider');
  }
  return context;
};
