import { AuthClient, FirestoreClient, useMount, usePrevious, useUnmount } from '@client';
import { AuthCallback, AuthForm, IntegrationCallback, TopNav } from '@client/components';
import { EnvironmentProvider, SidekickProvider, UserProvider } from '@client/context';
import { Loading, View } from '@client/elements';
import PowerQ from '@client/powerq';
import { IS_DEVELOPMENT, storage } from '@shared';
import * as authTypes from '@shared/auth/types';
import * as environmentTypes from '@shared/environment/types';
import * as integrationTypes from '@shared/integration/types';
import * as powerqTypes from '@shared/powerq/types';
import * as userTypes from '@shared/user/types';
import * as Linking from 'expo-linking';
import type { QueryParams } from 'expo-linking';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface Value {
  user: userTypes.User | null;
  userId: string | null;
}

const AppContext = React.createContext<Value | undefined>(undefined);

export const AppProvider = () => {
  // const [authenticated, setAuthenticated] = useState(false);
  const [environment, setEnvironment] = useState<environmentTypes.Environment>();
  const [loaded, setLoaded] = useState(false);
  const [params, setParams] = useState<QueryParams>();
  const [path, setPath] = useState<string>();
  const [user, setUser] = useState<userTypes.User | null>(null);

  const previousEnvironment = usePrevious(environment);

  const script = useRef<HTMLScriptElement>();
  const unsubscribe = useRef<() => void>();

  const authCallbackProvider = useMemo(
    () => path?.match(/^auth\/callback\/([a-z]+)/)?.[1],
    [path]
  ) as authTypes.Provider | undefined;
  const integrationCallbackName = useMemo(
    () => path?.match(/^integrations\/([a-z]+)\/callback/)?.[1],
    [path]
  ) as integrationTypes.Name | undefined;
  const powerqTarget = useMemo(() => path?.match(/^powerq\/(([a-z]|-)+)/)?.[1], [path]) as
    | powerqTypes.Type
    | undefined;
  const userId = useMemo(() => user?.id || null, [user?.id]);
  const value = useMemo(() => ({ user, userId }), [user, userId]);

  // Logic to run when the auth state has been updated
  const handleAuthState = useCallback(async (authState: { uid: string } | null) => {
    if (authState?.uid) {
      const userData = (await FirestoreClient.users.doc(authState.uid).get()).data();
      setUser(userData || null);
    } else {
      setUser(null);
    }
    setLoaded(true);
  }, []);

  // Logic to run when the auth token has been updated
  const handleIdTokenChanged = useCallback(async (authToken: { accessToken?: string } | null) => {
    if (authToken) {
      const { accessToken } = authToken;
      await storage.setItem('token', accessToken);
      if (IS_DEVELOPMENT) console.log('TOKEN: ', accessToken);
      // setAuthenticated(true);
    } else {
      // setAuthenticated(false);
    }
  }, []);

  // Callback to run after script is loaded
  const onLoad = useCallback(async () => {
    // const parsed = await Linking.parseInitialURLAsync();
    switch (environment) {
      case 'kustomer': {
        Kustomer?.initialize();
        break;
      }
      case 'salesforce': {
        if (Sfdc) {
          console.log({ Sfdc });
        }
        break;
      }
      default:
    }
    // We'll attempt to sign in below this
    const unsubscribeAuthState = AuthClient.onAuthStateChanged(handleAuthState);
    const unsubscribeIdToken = AuthClient.onIdTokenChanged(handleIdTokenChanged);
    unsubscribe.current = () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
    // Attempt to sign in with the if this is not an auth route
    if (!authCallbackProvider) {
      const customToken = await storage.getItem<string>('customToken');
      if (customToken) {
        try {
          await AuthClient.signInWithCustomToken(customToken);
        } catch {
          await storage.clear();
        }
      }
    }

    // setLoaded(true);
  }, [authCallbackProvider, environment, handleAuthState, handleIdTokenChanged]);

  // Callback to run after we have an environment
  const onEnvironment = useCallback(async () => {
    switch (environment) {
      case 'kustomer': {
        script.current = document.createElement(`script`);
        // Load Kustomer SDK
        script.current.src = 'https://cdn.kustomerapp.com/card-js/latest/kustomer-card.min.js';
        document.head.append(script.current);
        script.current.addEventListener(`load`, onLoad);
        break;
      }
      case 'salesforce': {
        require('@salesforce/canvas-js-sdk');
        onLoad();
        break;
      }
      default:
        onLoad();
    }
  }, [environment, onLoad]);

  // Initial mount checks the env and params
  useMount(async () => {
    const url = await Linking.parseInitialURLAsync();
    if (url.path) {
      setPath(url.path);
    }
    if (url.queryParams) {
      setParams(url.queryParams);
    }
    switch (url.path) {
      case 'kustomer': {
        setEnvironment('kustomer');
        break;
      }
      case 'powerq/salesforce-arena-quality': {
        const loginUrl = _.get(url.queryParams, 'loginUrl');
        if (typeof loginUrl === 'string' && /\.salesforce\.com/.test(loginUrl)) {
          setEnvironment('salesforce');
        } else {
          setEnvironment('web');
        }
        break;
      }
      default:
        setEnvironment('web');
    }
  });

  // After env comes back, load any env specific scripts
  useEffect(() => {
    if (environment && !previousEnvironment) {
      onEnvironment();
    }
  }, [environment, onEnvironment, previousEnvironment]);

  // Conditionally removes the script load listener on unmount
  useUnmount(() => {
    script.current?.removeEventListener(`load`, onLoad);
    unsubscribe.current?.();
  });

  if (!environment || !loaded) {
    return (
      // Initial render will determine the height of the iframe within kustomer
      <View sx={{ height: 500, p: 2 }}>
        <Loading type="skelton" />
      </View>
    );
  }

  if (authCallbackProvider) {
    return (
      <AuthCallback
        code={params?.code as string}
        provider={authCallbackProvider}
        state={params?.state as string}
      />
    );
  }

  if (powerqTarget) {
    return <PowerQ environment={environment} target={powerqTarget} user={user} />;
  }

  // If no user, sign in
  if (!user) {
    switch (environment) {
      case 'kustomer': {
        return (
          <View sx={{ height: 500, p: 2 }}>
            <AuthForm options={{ kustomer: true }} />
          </View>
        );
      }
      case 'salesforce': {
        return (
          <View sx={{ height: 500, p: 2 }}>
            <AuthForm options={{ salesforce: true }} />
          </View>
        );
      }
      case 'zendesk': {
        return (
          <View sx={{ height: 500, p: 2 }}>
            <AuthForm options={{ zendesk: true }} />
          </View>
        );
      }
      default:
        return (
          <View sx={{ height: 500, p: 2 }}>
            <AuthForm options={{ google: true, kustomer: true, salesforce: true, zendesk: true }} />
          </View>
        );
    }
  }

  // Don't render nav bars within an integration callback
  if (integrationCallbackName && userId) {
    return (
      <AppContext.Provider value={value}>
        <EnvironmentProvider environment={environment}>
          <UserProvider environment={environment} user={user}>
            <IntegrationCallback userId={userId} />
          </UserProvider>
        </EnvironmentProvider>
      </AppContext.Provider>
    );
  }

  // Currently, we're only loading the expo app within a sidekick env
  // if (!connectionId) {
  //   return (
  //     <AppContext.Provider value={value}>
  //       <EnvironmentProvider environment={environment}>
  //         <TopNav>
  //           <Alert message="Sidekick must be loaded within a supported environment" />
  //         </TopNav>
  //       </EnvironmentProvider>
  //     </AppContext.Provider>
  //   );
  // }

  return (
    <AppContext.Provider value={value}>
      <EnvironmentProvider environment={environment}>
        <View sx={{ height: 500, maxHeight: 500, p: 2 }}>
          <TopNav>
            <UserProvider environment={environment} user={user}>
              <SidekickProvider environment={environment} />
            </UserProvider>
          </TopNav>
        </View>
      </EnvironmentProvider>
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be within AppProvider');
  }
  return context;
};
