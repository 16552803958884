import { Button, Text, View } from '@client/elements';
import Form, { Field, useForm } from '@client/form';
import * as arenaTypes from '@shared/arena/types';
import * as powerqTypes from '@shared/powerq/types';
import * as salesforceTypes from '@shared/salesforce/types';
import * as variableTypes from '@shared/variable/types';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export interface ConfigureAttributeProps {
  onSubmit: (values: powerqTypes.ConfigSalesforceArenaQualityStepAttribute) => void;
  salesforceObjectMetadata: salesforceTypes.SObjectMetadata;
  stepAttributes: arenaTypes.QualityStepAttribute[];
  templateSteps: arenaTypes.QualityTemplateStep[];
}

export function ConfigureAttribute({
  onSubmit,
  salesforceObjectMetadata,
  stepAttributes,
  templateSteps,
}: ConfigureAttributeProps) {
  // const { onChangeFieldValue } = useForm();

  const [attributeId, setAttributeId] = useState<string>();
  const [stepId, setStepId] = useState<string>();
  const [variableAccessor, setVariableAccessor] = useState<string>();

  const accessorOptions = useMemo(
    () =>
      _.map(salesforceObjectMetadata.fields, (f) => ({
        label: f.name,
        value: `salesforce.${salesforceObjectMetadata.name}.${f.name}`,
      })),
    [salesforceObjectMetadata]
  );
  const attributeOptions = useMemo(
    () =>
      _.chain(templateSteps)
        .find((s) => s.guid === stepId)
        .get('attributes')
        .map((a) => ({ label: a.name, value: a.guid }))
        .value(),
    [stepId, templateSteps]
  );
  const stepOptions = useMemo(
    () => _.map(templateSteps, (s) => ({ label: s.name, value: s.guid })),
    [templateSteps]
  );

  const complete = useMemo(
    () => !!(attributeId && stepId && variableAccessor),
    [attributeId, stepId, variableAccessor]
  );

  return (
    <Form<powerqTypes.ConfigSalesforceArenaQualityStepAttribute>
      disabled={!complete}
      onSubmit={onSubmit}
    >
      <Field<string>
        label="Arena Step"
        name="stepId"
        options={stepOptions}
        onSelect={setStepId}
        required
        type="select-single"
      />
      {stepId && (
        <Field<string>
          label="Arena Attribute"
          name="attributeId"
          onSelect={setAttributeId}
          options={attributeOptions}
          required
          type="select-single"
        />
      )}
      {attributeId && (
        <Field<string>
          label="Salesforce Field"
          name="variableAccessor"
          options={accessorOptions}
          required
          onSelect={setVariableAccessor}
          type="select-single"
        />
      )}
    </Form>
  );
}
