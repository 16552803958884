import { useMount } from '@client';
import { AuthForm } from '@client/components';
import { UserProvider } from '@client/context';
import * as environmentTypes from '@shared/environment/types';
import * as powerqTypes from '@shared/powerq/types';
import * as userTypes from '@shared/user/types';

import SalesforceArenaQuality from './SalesforceArenaQuality';

interface Props {
  environment: environmentTypes.Environment;
  target: powerqTypes.Type;
  user: userTypes.User | null;
}

function Target({ target, ...props }: Props & { user: userTypes.User }) {
  switch (target) {
    case 'salesforce-arena-quality':
      return <SalesforceArenaQuality {...props} />;
    default:
      return null;
  }
}

export default function PowerQ({ target, user, ...props }: Props) {
  useMount(() => {
    console.log('MOUNTED POWERQ');
    console.log('PROPS: ', props);
  });

  if (!user) {
    console.log('RENDERING POWERQ AUTH');
    return (
      <AuthForm
        options={{
          powerq: target,
          salesforce: true,
          salesforceLabel: 'Authorize Salesforce',
        }}
      />
    );
  }
  return (
    <UserProvider environment={props.environment} powerq={target} user={user}>
      <Target target={target} user={user} {...props} />
    </UserProvider>
  );
}
