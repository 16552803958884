import { View as DripsyView, SxProp } from 'dripsy';
import { ViewProps as NativeViewProps } from 'react-native';

export interface ViewProps extends NativeViewProps {
  children?: React.ReactNode;
  innerRef?: React.Ref<any>;
  sx?: SxProp;
}

export function View({ children, innerRef, sx, ...props }: ViewProps) {
  return (
    <DripsyView ref={innerRef} sx={{ bg: '$light', ...sx }} {...props}>
      {children}
    </DripsyView>
  );
}
