import { ApiClient, APP_URL, useMount } from '@client';
import { Button, Icon, TextInput, View } from '@client/elements';
import { storage } from '@shared';
import React, { useCallback, useState } from 'react';

export type Props = {
  onAuth: (url: string) => void;
};

export function AuthButtonGoogle({ onAuth }: Props) {
  const [errorMessage, setErrorMessage] = useState('');

  const handlePress = useCallback(async () => {
    try {
      const {
        data: { url },
      } = await ApiClient.auth.url.post({
        provider: 'google',
        redirect: `${APP_URL}/auth/callback/google`,
      });
      onAuth(url);
    } catch {
      setErrorMessage('Error, please try again');
    }
  }, [onAuth]);

  return (
    <View sx={{ width: 230 }}>
      <Button
        icon={<Icon height={30} name="google-icon" width={30} />}
        label="Google Sign In"
        onPress={handlePress}
        sx={{ width: 220 }}
        variant="outlined"
      />
    </View>
  );
}
