import { View } from '@client/elements';
import { DataGridPro, GridRowsProp, GridColDef } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { SxProp } from 'dripsy';

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI ?? '');
interface Props {
  columns: GridColDef[];
  density?: 'comfortable' | 'compact' | 'standard';
  loading?: boolean;
  pageSize?: number;
  pagination?: boolean;
  rows: GridRowsProp;
  wrapperStyle?: SxProp;
}
export function Table({
  columns,
  density = 'comfortable',
  loading = false,
  rows,
  pageSize,
  pagination = false,
  wrapperStyle,
}: Props) {
  return (
    <View sx={wrapperStyle ?? { width: '100%', height: '100%' }}>
      <DataGridPro
        columns={columns}
        density={density}
        experimentalFeatures={{ newEditingApi: true }}
        loading={loading}
        pageSize={pageSize}
        pagination={pagination}
        rows={rows}
      />
    </View>
  );
}
