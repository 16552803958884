import { PLATFORM } from '@client';
import { WebView } from 'react-native-webview';
import sanitizeHtml from 'sanitize-html';

export interface HtmlProps {
  html: string;
}

export function Html({ html }: HtmlProps) {
  const _html = sanitizeHtml(html);
  switch (PLATFORM) {
    case 'web':
      return <div dangerouslySetInnerHTML={{ __html: _html }} />;
    default:
      return <WebView source={{ html: _html }} />;
  }
}
