import { View } from '@client/elements';
import { Text, TextInput as DripsyTextInput, SxProp } from 'dripsy';
import { TextInputProps as NativeTextInputProps } from 'react-native';

export interface TextInputProps extends NativeTextInputProps {
  // style?: styleTypes.Style;
  errorMessage?: string;
  label?: string;
  innerRef?: React.Ref<any>;
  placeholder?: string;
  sx?: SxProp;
  type?: 'password' | 'plain-text';
}

export function TextInput({
  errorMessage,
  innerRef,
  label,
  multiline,
  placeholder,
  sx,
  type,
  ...props
}: TextInputProps) {
  // const { getStyle } = useStyle();

  return (
    <View sx={{ display: 'flex', flexDirection: 'column' }}>
      {label && <Text sx={{ color: '$neutralDark', fontSize: 14, m: '$1' }}>{label}</Text>}
      <DripsyTextInput
        {...(props as any)}
        placeholder={placeholder}
        ref={innerRef}
        sx={{
          borderColor: '$neutral',
          borderRadius: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          m: '$1',
          minHeight: 30,
          p: '$2',
          ...sx,
        }}
        secureTextEntry={type === 'password'}
      />
      {errorMessage && <Text sx={{ color: '$error' }}>{errorMessage}</Text>}
    </View>
  );
}
