import { ApiClient, useMount } from '@client';
import { Loading } from '@client/elements';
import { IS_DEVELOPMENT } from '@shared';
import * as companyTypes from '@shared/company/types';
import React, { useCallback, useContext, useMemo, useState } from 'react';

interface Props {
  children: React.ReactNode;
  company: companyTypes.Company;
  userId: string;
}

interface Value {
  company: companyTypes.Company;
  companyId: string;
  onFetchMembers: () => Promise<companyTypes.Member[]>;
  role: companyTypes.MemberRole;
  userId: string;
}

const CompanyContext = React.createContext<Value | undefined>(undefined);

export const CompanyProvider = ({ children, company, userId }: Props) => {
  const [member, setMember] = useState<companyTypes.Member>();

  const role = useMemo(() => member?.role || null, [member?.role]);

  const onFetchMembers = useCallback(async () => {
    const { data } = await ApiClient.company(company.id).members.get();
    return data;
  }, [company.id]);

  const onLoadRole = useCallback(async () => {
    const { data } = await ApiClient.company(company.id).currentMember.get();
    setMember(data);
  }, [company.id]);

  const value = useMemo(
    () =>
      role
        ? {
            company,
            companyId: company.id,
            onFetchMembers,
            role,
            userId,
          }
        : null,
    [company, onFetchMembers, role, userId]
  );

  useMount(() => {
    console.log('MOUNTED');
    onLoadRole();
    if (IS_DEVELOPMENT) console.log('COMPANY: ', company.id);
  });

  if (!value) return <Loading />;

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error('useCompany must be within CompanyProvider');
  }
  return context;
};
