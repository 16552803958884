import { Pressable as DripsyPressable, SxProp, View } from 'dripsy';

export interface PressableProps {
  children: React.ReactNode;
  disabled?: boolean;
  hovered?: boolean;
  onPress?: () => void;
  sx?: SxProp;
  sxHovered?: SxProp;
}

export function Pressable({
  children,
  disabled,
  onPress,
  sx,
  sxHovered,
  ...props
}: PressableProps) {
  const _sx: SxProp = { ...sx, opacity: 1 };
  const _sxDisabled: SxProp = { ..._sx, bg: '$neutralLight', cursor: 'not-allowed' };
  const _sxHovered: SxProp = { opacity: 0.8, ...sxHovered };
  return (
    <DripsyPressable disabled={disabled} onResponderStart={onPress} {...props}>
      {({ hovered }) => {
        return (
          <View
            sx={{
              ..._sx,
              ...(hovered && onPress && _sxHovered),
              ...(disabled && _sxDisabled),
            }}
          >
            {children}
          </View>
        );
      }}
    </DripsyPressable>
  );
}
