import { ApiClient, APP_URL } from '@client';
import { Button, Checkbox, Icon, View } from '@client/elements';
import React, { useCallback, useState } from 'react';

export type Props = {
  label?: string;
  onAuth: (url: string) => void;
};

export function AuthButtonSalesforce({ label, onAuth }: Props) {
  const [sandbox, setSandbox] = useState(false);

  const handlePress = useCallback(async () => {
    const {
      data: { url },
    } = await ApiClient.auth.url.post({
      provider: 'salesforce',
      redirect: `${APP_URL}/auth/callback/salesforce`,
      sandbox,
    });
    onAuth(url);
  }, [onAuth, sandbox]);

  return (
    <View sx={{ width: 230 }}>
      <Button
        icon={<Icon height={30} name="salesforce-icon" width={30} />}
        label={label || 'Salesforce Sign In'}
        onPress={handlePress}
        sx={{ width: 220 }}
        variant="outlined"
      />
      <Checkbox checked={sandbox} label="Sandbox" onPress={() => setSandbox((s) => !s)} />
    </View>
  );
}
