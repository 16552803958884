import { ApiClient, AuthClient, APP_URL, createParam, handleError, useMount } from '@client';
import { useCompany } from '@client/context';
import { Alert, Loading, View } from '@client/elements';
import * as apiTypes from '@shared/api/types';
import * as integrationTypes from '@shared/integration/types';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { useCallback, useState } from 'react';

interface Props {
  userId: string;
}

type Params = {
  code: string;
  integration: integrationTypes.Name;
};

const { useParam } = createParam<Params>();

export function IntegrationCallback({ userId }: Props) {
  const { companyId } = useCompany();
  const [error, setError] = useState<apiTypes.ExceptionPayload>();
  const [code] = useParam('code');
  const [integration] = useParam('integration');

  useMount(async () => {
    try {
      if (!code) throw new Error('Missing Code');
      if (!companyId) throw new Error('Missing Company');
      console.log(integration);
      switch (integration) {
        case 'docusign': {
          const { data } = await ApiClient.company(companyId)
            .integration('docusign')
            .auth.user.post({
              createClientCompany: true,
              createClientUser: true,
              code,
              companyId,
              integration: 'docusign',
              userId,
            });
          console.log(data);
        }
      }
      WebBrowser.maybeCompleteAuthSession();
    } catch (err) {
      setError(handleError(err));
    }
  });

  return <Loading />;
}
