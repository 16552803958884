import * as connectionTypes from '@shared/connection/types';
import * as integrationTypes from '@shared/integration/types';
import _ from 'lodash';

export const getElements = (connectionKey: string): connectionTypes.Elements => {
  const elementArray = _.split(connectionKey, '_');
  return {
    context: elementArray[1],
    record: elementArray[2],
    source: elementArray[0] as integrationTypes.Name,
  };
};

export const getKey = (connectionTarget: connectionTypes.Elements): string =>
  `${connectionTarget.source}_${connectionTarget.context}_${connectionTarget.record}`;

export const prefixVariables = (customVariables: connectionTypes.CustomVariables) => {
  let mutant: connectionTypes.Variables = {};
  _.map(customVariables, (v, key) => {
    // Prefix with both dot and underscore
    _.set(mutant, `custom.${key}`, v);
    _.set(mutant, `custom_${key}`, v);
  });
  return mutant;
};
