import { APP_URL } from '@client';
import {
  EXPO_PUBLIC_FIREBASE_API_KEY,
  EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN,
  EXPO_PUBLIC_FIREBASE_DATABASE_URL,
  EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  EXPO_PUBLIC_FIREBASE_PROJECT_ID,
  EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET,
} from '@env';
import { storage } from '@shared';
import * as authTypes from '@shared/auth/types';
import * as companyTypes from '@shared/company/types';
import * as connectionTypes from '@shared/connection/types';
import * as errorTypes from '@shared/error/types';
import * as eventTypes from '@shared/event/types';
import * as filingTypes from '@shared/filing/types';
import * as firebaseTypes from '@shared/firebase/types';
import * as flowTypes from '@shared/flow/types';
import * as integrationTypes from '@shared/integration/types';
import * as powerqTypes from '@shared/powerq/types';
import * as recordTypes from '@shared/record/types';
import * as scheduleTypes from '@shared/schedule/types';
import * as suggestionTypes from '@shared/suggestion/types';
import * as userTypes from '@shared/user/types';
import { getApp, getApps, initializeApp } from 'firebase/app';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  onIdTokenChanged,
  NextOrObserver,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailLink,
  signOut,
  User,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  runTransaction,
  limit,
  orderBy,
  onSnapshot,
  setDoc,
  query,
  updateDoc,
  where,
  Timestamp,
  Unsubscribe,
} from 'firebase/firestore';
// Direct imports here because server types rely on library incompatible with web
// import type { IdTokenResult } from 'firebase/auth';
import type {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
  Transaction,
  TransactionOptions,
  UpdateData,
  WhereFilterOp,
  WithFieldValue,
  Firestore,
} from 'firebase/firestore';
// type SnapshotRef<T> = DocumentReference<T> | CollectionReference<T> | Query<T>;

// Loads env variables into project
// require('dotenv').config({ path: __dirname + '/apps/expo/.env' });
// require('dotenv').config({ path: __dirname + '/.env' });

interface QueryOptions {
  limit?: number;
  order?: { path: string; direction?: 'asc' | 'desc' };
  where?: {
    path: string;
    filter: WhereFilterOp;
    // | '<'
    // | '<='
    // | '=='
    // | '!='
    // | '>='
    // | '>'
    // | 'array-contains'
    // | 'in'
    // | 'array-contains-any'
    // | 'not-in';
    value: boolean | number | string;
  }[];
}

const creds = {
  apiKey:
    process.env.NEXT_PUBLIC_FIREBASE_API_KEY ??
    process.env.EXPO_PUBLIC_FIREBASE_API_KEY ??
    EXPO_PUBLIC_FIREBASE_API_KEY,
  authDomain:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ??
    process.env.EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN ??
    EXPO_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL:
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ??
    process.env.EXPO_PUBLIC_FIREBASE_DATABASE_URL ??
    EXPO_PUBLIC_FIREBASE_DATABASE_URL,
  messagingSenderId:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ??
    process.env.EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ??
    EXPO_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId:
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ??
    process.env.EXPO_PUBLIC_FIREBASE_PROJECT_ID ??
    EXPO_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ??
    process.env.EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET ??
    EXPO_PUBLIC_FIREBASE_STORAGE_BUCKET,
};

const firebase = getApps().filter((a) => a.name === 'client').length
  ? getApp('client')
  : initializeApp(creds, 'client');

const auth = getAuth(firebase);
const firestore = getFirestore(firebase);

export const AuthClient = {
  currentUser: auth.currentUser,
  isSignInWithEmailLink: (url: string) => isSignInWithEmailLink(auth, url),
  onAuthStateChanged: (observer: NextOrObserver<User | null>) => onAuthStateChanged(auth, observer),
  onIdTokenChanged: (observer: NextOrObserver<(User & { accessToken?: string }) | null>) =>
    onIdTokenChanged(auth, observer),
  signInWithCustomToken: (token: string) => signInWithCustomToken(auth, token),
  signInWithEmailLink: (email: string, url: string) => signInWithEmailLink(auth, email, url),
  sendSignInLinkToEmail: async ({ email, oauth }: { email: string; oauth?: authTypes.Provider }) =>
    sendSignInLinkToEmail(auth, email, {
      handleCodeInApp: true,
      url: APP_URL + '/auth/callback/email' + (oauth ? `?oauth=${oauth}` : ''),
    }),
  signOut: () => {
    storage.clear();
    signOut(auth);
  },
};
interface AttachQueryMethods<T> {
  get: () => Promise<QuerySnapshot<T>>;
  onSnapshot: (observer: (snapshot: QuerySnapshot<T>) => void) => Unsubscribe;
}
const attachQueryMethods = <T>(collectionRef: CollectionReference<T>, options: QueryOptions) => {
  const args = [];
  for (const { path, filter, value } of options.where || []) {
    args.push(where(path, filter, value));
  }
  if (options.order) args.push(orderBy(options.order.path, options.order.direction));
  if (options.limit) args.push(limit(options.limit));
  const queryRef = query(collectionRef, ...args);
  const queryMethods = {
    get: () => getDocs(queryRef),
    onSnapshot: (observer: (snapshot: QuerySnapshot<T>) => void) => onSnapshot(queryRef, observer),
  };
  return queryMethods;
};
interface AttachDocMethods<T> {
  get: () => Promise<DocumentSnapshot<T>>;
  onSnapshot: (observer: (snapshot: DocumentSnapshot<T>) => void) => Unsubscribe;
  set: (
    data: T,
    options?: {
      merge?: boolean;
    }
  ) => Promise<void>;
  update: (payload: UpdateData<T>) => Promise<void>;
}
const attachDocMethods = <T>(app: Firestore, collectionPath: string, docId: string) => {
  const docPath = `${collectionPath}/${docId}`;
  const docRef = doc(app, docPath) as DocumentReference<T>;
  const docMethods = {
    get: () => getDoc(docRef),
    onSnapshot: (observer: (snapshot: DocumentSnapshot<T>) => void) =>
      // TODO: Fix typing
      onSnapshot<T>(docRef, observer),
    set: (data: T, options?: { merge?: boolean }) =>
      setDoc(docRef as DocumentReference<WithFieldValue<T>>, data, options || {}),
    update: (payload: UpdateData<T>) => updateDoc(docRef, payload),
  };
  return docMethods;
};

const attachCollectionMethods = <T>(
  app: Firestore,
  collectionPath: string,
  collectionRef: CollectionReference<T>
) => {
  const collectionMethods = {
    add: (payload: T) => addDoc(collectionRef, payload),
    doc: (id: string) => attachDocMethods<T>(app, collectionPath, id),
    get: () => getDocs(collectionRef as CollectionReference<T>),
    query: (options: QueryOptions) => attachQueryMethods<T>(collectionRef, options),
    onSnapshot: <T>(observer: (snapshot: QuerySnapshot<T>) => void) =>
      onSnapshot(collectionRef as Query<T>, observer),
    ref: collectionRef,
    // custom
    newDocRef: () => doc(collectionRef),
  };
  return collectionMethods;
};
export const firestoreTypedWeb = <T>(firestore: Firestore, collectionPath: string) => {
  const collectionRef = collection(firestore, collectionPath) as CollectionReference<T>;
  return attachCollectionMethods(
    firestore,
    collectionPath,
    collectionRef
  ) as unknown as _collectionMethods<T>;
};

type _collectionMethods<T> = {
  add: (payload: T) => Promise<DocumentReference<T>>;
  doc: (id: string) => AttachDocMethods<T>;
  get: () => Promise<QuerySnapshot<T>>;
  query: (options: QueryOptions) => AttachQueryMethods<T>;
  onSnapshot: <T>(observer: (snapshot: QuerySnapshot<T>) => void) => typeof onSnapshot;
  ref: CollectionReference<T>;
  // custom
  newDocRef: () => DocumentReference<T>;
};

type FirestoreTypedWeb<T> = (
  firestore: Firestore,
  collectionPath: string
) => (
  app: Firestore,
  collectionPath: string,
  collectionRef: CollectionReference<T>
) => _collectionMethods<T>;
type FirestoreClientCollection<T> = _collectionMethods<T>;
type CollectionMethods = {
  [Property in keyof firebaseTypes.FirestoreSubcollections]: ReplaceReturnType<
    firebaseTypes.FirestoreSubcollections[Property]
  >;
};
type ReplaceReturnType<T extends (...a: any) => any> = (
  ...a: Parameters<T>
) => FirestoreClientCollection<ReturnType<T>>;
type FirestoreClientInterface = {
  runTransaction: <T>(
    updateFunction: (transaction: Transaction) => Promise<T>,
    options?: TransactionOptions
  ) => ReturnType<typeof runTransaction>;
  Timestamp: typeof Timestamp;
  companies: FirestoreClientCollection<companyTypes.Company>;
  mapRecorderStates: FirestoreClientCollection<filingTypes.RecorderStateMap>;
  recorders: FirestoreClientCollection<filingTypes.Recorder>;
  users: FirestoreClientCollection<userTypes.User>;
} & CollectionMethods;

export const FirestoreClient: FirestoreClientInterface = {
  runTransaction: <T>(
    updateFunction: (transaction: Transaction) => Promise<T>,
    options?: TransactionOptions
  ) => runTransaction(firestore, updateFunction, options),
  // Classes
  Timestamp,
  // Collections
  companies: firestoreTypedWeb<companyTypes.Company>(firestore, 'companies'),
  mapRecorderStates: firestoreTypedWeb<filingTypes.RecorderStateMap>(
    firestore,
    'maps/recorder/states'
  ),
  recorders: firestoreTypedWeb<filingTypes.Recorder>(firestore, 'recorders'),
  users: firestoreTypedWeb<userTypes.User>(firestore, 'users'),
  // Subcollections
  companyCategories: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<flowTypes.Category>(firestore, `companies/${companyId}/categories`),
  companyConnections: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<connectionTypes.Document>(firestore, `companies/${companyId}/connections`),
  companyErrors: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<errorTypes.Error>(firestore, `companies/${companyId}/errors`),
  companyEvents: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<eventTypes.EventFirestore>(firestore, `companies/${companyId}/events`),
  companyFlows: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<flowTypes.Flow>(firestore, `companies/${companyId}/flows`),
  companyFlowVersions: ({ companyId, flowId }: { companyId: string; flowId: string }) =>
    firestoreTypedWeb<flowTypes.Version>(
      firestore,
      `companies/${companyId}/flows/${flowId}/versions`
    ),
  companyFlowVersionStepSuggestions: ({
    companyId,
    flowId,
    versionId,
  }: {
    companyId: string;
    flowId: string;
    versionId: string;
  }) =>
    firestoreTypedWeb<suggestionTypes.FlowVersionStepEdit>(
      firestore,
      `companies/${companyId}/flows/${flowId}/versions/${versionId}/stepSuggestions`
    ),
  companyGroups: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<companyTypes.Group>(firestore, `companies/${companyId}/groups`),
  companyIntegrations: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<integrationTypes.Integration>(
      firestore,
      `companies/${companyId}/integrations`
    ),
  companyInteractions: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<companyTypes.Interaction>(firestore, `companies/${companyId}/interactions`),
  companyNotifications: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<companyTypes.CompanyNotification>(
      firestore,
      `companies/${companyId}/notifications`
    ),
  companyPowerq: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<powerqTypes.Config>(firestore, `companies/${companyId}/powerq`),
  companyRecords: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<recordTypes.Record>(firestore, `recorders/${companyId}/records`),
  companyRecorderAddRequests: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<filingTypes.RecorderAddRequest>(
      firestore,
      `companies/${companyId}/recorderAddRequests`
    ),
  companyRecorderChangeRequests: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<filingTypes.RecorderChangeRequest>(
      firestore,
      `companies/${companyId}/recorderChangeRequests`
    ),
  companyRecorderScenarioChangeRequests: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<filingTypes.RecorderScenarioChangeRequest>(
      firestore,
      `companies/${companyId}/recorderScenarioChangeRequests`
    ),
  companySchedules: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<scheduleTypes.Schedule[]>(firestore, `companies/${companyId}/schedules`),
  companySuggestions: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<suggestionTypes.Suggestion>(firestore, `companies/${companyId}/suggestions`),
  companySuggestionComments: ({
    companyId,
    suggestionId,
  }: {
    companyId: string;
    suggestionId: string;
  }) =>
    firestoreTypedWeb<suggestionTypes.StepSuggestionComment>(
      firestore,
      `companies/${companyId}/suggestions/${suggestionId}/comments`
    ),
  companyMembers: ({ companyId }: { companyId: string }) =>
    firestoreTypedWeb<companyTypes.Member>(firestore, `companies/${companyId}/members`),
  recorderScenarios: ({ recorderId }: { recorderId: string }) =>
    firestoreTypedWeb<filingTypes.RecorderScenario>(firestore, `recorders/${recorderId}/scenarios`),
  starterFlows: () => firestoreTypedWeb<flowTypes.Flow>(firestore, `starterFlows`),
  userCompanies: ({ userId }: { userId: string }) =>
    firestoreTypedWeb<companyTypes.Member>(firestore, `users/${userId}/companies`),
};
