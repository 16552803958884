import { API_URL } from '@client';
import { storage } from '@shared';
import * as authTypes from '@shared/auth/types';
import * as companyTypes from '@shared/company/types';
import * as endpointTypes from '@shared/endpoint/types';
import * as filingTypes from '@shared/filing/types';
import * as integrationTypes from '@shared/integration/types';
import * as lobTypes from '@shared/lob/types';
import * as recordTypes from '@shared/record/types';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';

function apiUrl(endpoint: string) {
  return API_URL + '/' + _.trimStart(endpoint, '/');
}

async function fetchToken() {
  const token = await storage.getItem<string>('token');
  return token;
}

export const ApiClient = {
  // Base methods
  _delete: async (endpoint: string) => {
    const headers = { Authorization: `Token ${await fetchToken()}` };
    return axios.delete(apiUrl(endpoint), { headers });
  },
  _get: async (endpoint: string) => {
    const headers = { Authorization: `Token ${await fetchToken()}` };
    return axios.get(apiUrl(endpoint), { headers });
  },
  _patch: async (endpoint: string, payload: any) => {
    const headers = { Authorization: `Token ${await fetchToken()}` };
    return axios.patch(apiUrl(endpoint), payload, { headers });
  },
  _post: async (endpoint: string, payload: any) => {
    const headers = { Authorization: `Token ${await fetchToken()}` };
    return axios.post(apiUrl(endpoint), payload, { headers });
  },
  _put: async (endpoint: string, payload: any) => {
    const headers = { Authorization: `Token ${await fetchToken()}` };
    return axios.put(apiUrl(endpoint), payload, { headers });
  },
  _postNoAuth: async (endpoint: string, payload: any) => {
    return axios.post(apiUrl(endpoint), payload);
  },
  // Typed endpoints
  auth: {
    url: {
      post: (payload: authTypes.ApiRequestUrl): Promise<AxiosResponse<authTypes.ApiResponseUrl>> =>
        ApiClient._postNoAuth(`/auth/url`, payload),
    },
    user: {
      post: (
        payload: authTypes.ApiRequestUser
      ): Promise<AxiosResponse<authTypes.ApiResponseUser>> =>
        ApiClient._postNoAuth(`/auth/user`, payload),
    },
  },
  companies: {
    get: async (): Promise<AxiosResponse<companyTypes.CompanyWithMembership[]>> =>
      ApiClient._get(`/companies`),
    post: async (
      payload: companyTypes.CompanyRequest
    ): Promise<AxiosResponse<companyTypes.CompanyWithMembership[]>> =>
      ApiClient._post(`/companies`, payload),
  },
  company: (companyId: string) => ({
    get: async (): Promise<AxiosResponse<companyTypes.Company>> =>
      ApiClient._get(`/companies/${companyId}`),
    currentMember: {
      get: () => ApiClient._get(`/companies/${companyId}/member`),
    },
    // Used to dynamically handle various integration endpoints based on the payload
    endpoint: <T extends endpointTypes.Endpoint['type']>(payload: {
      type: T;
      integration: Extract<endpointTypes.Endpoint, { type: T }>['integration'];
      request: Extract<endpointTypes.Endpoint, { type: T }>['request'];
    }): Promise<AxiosResponse<endpointTypes.Response<T>>> =>
      ApiClient._post(`/companies/${companyId}/endpoint`, payload),
    filings: {
      post: async (
        payload: filingTypes.SubmitRequest
      ): Promise<AxiosResponse<filingTypes.Filing>> =>
        ApiClient._post(`/companies/${companyId}/filings`, payload),
    },
    integration: <IntegrationName extends integrationTypes.Name>(integration: IntegrationName) => ({
      get: async (): Promise<AxiosResponse<integrationTypes.Integration>> =>
        ApiClient._get(`/companies/${companyId}/integrations/${integration}`),
      post: async (
        payload: integrationTypes.IntegrationRequest
      ): Promise<AxiosResponse<integrationTypes.Integration>> =>
        ApiClient._post(`/companies/${companyId}/integrations/${integration}`, payload),
      auth: {
        get: async (): Promise<
          AxiosResponse<
            Extract<integrationTypes.ClientCompanySummary, { integration: IntegrationName }>[]
          >
        > => ApiClient._get(`/companies/${companyId}/integrations/${integration}/clients`),
        url: {
          post: async (
            payload: integrationTypes.AuthUrlRequest
          ): Promise<
            AxiosResponse<Extract<integrationTypes.AuthUrl, { integration: IntegrationName }>>
          > =>
            ApiClient._post(
              `/companies/${companyId}/integrations/${integration}/auth/url`,
              payload
            ),
        },
        user: {
          post: async (
            payload: integrationTypes.AuthUserRequest
          ): Promise<
            AxiosResponse<Extract<integrationTypes.AuthUser, { integration: IntegrationName }>>
          > =>
            ApiClient._post(
              `/companies/${companyId}/integrations/${integration}/auth/user`,
              payload
            ),
        },
      },
      clients: {
        get: async (): Promise<
          AxiosResponse<
            Extract<integrationTypes.ClientCompanySummary, { integration: IntegrationName }>[]
          >
        > => ApiClient._get(`/companies/${companyId}/integrations/${integration}/clients`),
      },
      instance: (instance: string) => ({
        delete: async (): Promise<AxiosResponse<null>> =>
          ApiClient._delete(
            `/companies/${companyId}/integrations/${integration}/instances/${instance}`
          ),
        get: async (): Promise<AxiosResponse<integrationTypes.ClientCompanySummary>> =>
          ApiClient._get(
            `/companies/${companyId}/integrations/${integration}/instances/${instance}`
          ),
        post: async (
          payload: integrationTypes.ClientRequest
        ): Promise<AxiosResponse<integrationTypes.ClientCompanySummary>> =>
          ApiClient._post(
            `/companies/${companyId}/integrations/${integration}/instances/${instance}`,
            payload
          ),
      }),
    }),
    integrations: {
      get: async (): Promise<AxiosResponse<integrationTypes.IntegrationSummary[]>> =>
        ApiClient._get(`/companies/${companyId}/integrations`),
      post: async (
        payload: integrationTypes.IntegrationRequest
      ): Promise<AxiosResponse<integrationTypes.Integration[]>> =>
        ApiClient._post(`/companies/${companyId}/integrations`, payload),
    },
    members: {
      get: async (): Promise<AxiosResponse<companyTypes.Member[]>> =>
        ApiClient._get(`/companies/${companyId}/members`),
    },
    record: (recordId: string) => ({
      put: async (
        payload: Partial<recordTypes.Record>
      ): Promise<AxiosResponse<recordTypes.Record>> =>
        ApiClient._put(`/companies/${companyId}/records/${recordId}`, payload),
    }),
    tokens: {
      get: async (): Promise<AxiosResponse<authTypes.ExternalAPI_TokenGetResponse>> =>
        ApiClient._get(`/companies/${companyId}/tokens`),
      post: async (
        payload: authTypes.ExternalAPI_TokenPostRequest
      ): Promise<AxiosResponse<authTypes.UserToken>> =>
        ApiClient._post(`/companies/${companyId}/tokens`, payload),
      delete: async (tokenId: string) =>
        ApiClient._delete(`/companies/${companyId}/tokens/${tokenId}`),
    },
  }),
  lob: {
    address: {
      get: (id: string): Promise<AxiosResponse<lobTypes.Address>> =>
        ApiClient._get(`/lob/addresses/${id}`),
    },
    addresses: {
      get: (): Promise<AxiosResponse<lobTypes.Address[]>> => ApiClient._get('/lob/addresses'),
      post: (address: lobTypes.AddressRequest): Promise<AxiosResponse<lobTypes.Address>> =>
        ApiClient._post('/lob/addresses', address),
    },
    us_verifications: {
      post: (data: lobTypes.VerificationRequest): Promise<AxiosResponse<lobTypes.Verification>> =>
        ApiClient._post(`/lob/us_verifications`, data),
    },
  },
  simplifile: {
    recipients: {
      get: (id: string): Promise<AxiosResponse<lobTypes.Address>> =>
        ApiClient._get(`/lob/addresses/${id}`),
    },
    addresses: {
      get: (): Promise<AxiosResponse<lobTypes.Address[]>> => ApiClient._get('/lob/addresses'),
    },
  },
  user: (userId: string) => ({
    integration: <IntegrationName extends integrationTypes.Name>(integration: IntegrationName) => ({
      // get: async (): Promise<AxiosResponse<integrationTypes.Integration>> =>
      //   ApiClient._get(`/users/${userId}/integrations/${integration}`),
      // post: async (
      //   payload: integrationTypes.IntegrationRequest
      // ): Promise<AxiosResponse<integrationTypes.Integration>> =>
      //   ApiClient._post(`/users/${userId}/integrations/${integration}`, payload),
      instance: (instance: string) => ({
        delete: async (): Promise<AxiosResponse<null>> =>
          ApiClient._delete(`/users/${userId}/integrations/${integration}/instances/${instance}`),
        get: async (): Promise<AxiosResponse<integrationTypes.ClientUserSummary>> =>
          ApiClient._get(`/users/${userId}/integrations/${integration}/instances/${instance}`),
        post: async (
          payload: Extract<integrationTypes.ClientRequest, { level: 'user' }>
        ): Promise<AxiosResponse<integrationTypes.ClientUserSummary>> =>
          ApiClient._post(
            `/users/${userId}/integrations/${integration}/instances/${instance}`,
            payload
          ),
      }),
    }),
  }),
  currentUser: {
    integration: <IntegrationName extends integrationTypes.Name>(integration: IntegrationName) => ({
      clientsWithData: {
        get: async (): Promise<AxiosResponse<integrationTypes.ClientUser<IntegrationName>[]>> =>
          ApiClient._get(`/user/integrations/${integration}/clients-with-data`),
      },
    }),
  },
};
