import { Button, Text, View } from '@client/elements';
import Form, { Field, useForm } from '@client/form';
import * as arenaTypes from '@shared/arena/types';
import * as powerqTypes from '@shared/powerq/types';
import * as salesforceTypes from '@shared/salesforce/types';
import * as variableTypes from '@shared/variable/types';
import _, { at } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { ConfigureAttribute } from './ConfigureAttribute';

type Config = Pick<
  powerqTypes.Config<'salesforce-arena-quality'>,
  'arenaStepAttributes'
>['arenaStepAttributes'];

interface AttributeEdit {
  configAttribute: powerqTypes.ConfigSalesforceArenaQualityStepAttribute;
  index: number;
}

export interface ConfigureAttributesProps {
  onSubmit: (config: { arenaStepAttributes: Config }) => void;
  salesforceObjectMetadata: salesforceTypes.SObjectMetadata;
  stepAttributes: arenaTypes.QualityStepAttribute[];
  templateSteps: arenaTypes.QualityTemplateStep[];
}

export function ConfigureAttributes({
  onSubmit,
  stepAttributes,
  salesforceObjectMetadata,
  templateSteps,
}: ConfigureAttributesProps) {
  const [attributes, setAttributes] = useState<Config>([]);

  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState<AttributeEdit>();

  const handleAddAttribute = useCallback(
    (values: powerqTypes.ConfigSalesforceArenaQualityStepAttribute) => {
      setAttributes((s) => [...s, values]);
      setAdding(false);
    },
    []
  );

  const handleEditAttribute = useCallback(
    (values: powerqTypes.ConfigSalesforceArenaQualityStepAttribute) => {
      if (editing) {
        setAttributes((s) => [
          ..._.slice(s, 0, editing.index),
          values,
          ..._.slice(s, editing.index + 1),
        ]);
        setEditing(undefined);
      }
    },
    [editing]
  );

  const handleSubmit = useCallback(
    () => onSubmit({ arenaStepAttributes: attributes }),
    [attributes, onSubmit]
  );

  if (adding) {
    return (
      <ConfigureAttribute
        onSubmit={handleAddAttribute}
        salesforceObjectMetadata={salesforceObjectMetadata}
        stepAttributes={stepAttributes}
        templateSteps={templateSteps}
      />
    );
  }

  if (editing) {
    return (
      <ConfigureAttribute
        onSubmit={handleAddAttribute}
        salesforceObjectMetadata={salesforceObjectMetadata}
        stepAttributes={stepAttributes}
        templateSteps={templateSteps}
      />
    );
  }

  return (
    <>
      {_.size(attributes) > 0 ? (
        <>
          <Text variant="h3">Existing Mapping</Text>
          {_.map(attributes, (mappedAttribute, index) => {
            const templateStep = _.find(templateSteps, (s) => s.guid === mappedAttribute.stepId);
            const step = templateStep?.name || '';
            const attribute = _.find(
              templateStep?.attributes,
              (a) => a.guid === mappedAttribute.attributeId
            )?.name;
            const [, object, field] = _.split(mappedAttribute.variableAccessor, '.');
            return (
              <View
                key={index}
                sx={{
                  borderColor: '$neutral',
                  borderRadius: 5,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  m: '$1',
                  p: '$1',
                }}
              >
                <Text>{`Step: ${step}`}</Text>
                <Text>{`Attribute: ${attribute}`}</Text>
                <Text>{`${object}: ${field}`}</Text>
              </View>
            );
          })}
          <Button label="Add Attribute" onPress={() => setAdding(true)} variant="outlined" />
        </>
      ) : (
        <>
          <Text>No Attributes Mapped</Text>
          <Button label="Add Attribute" onPress={() => setAdding(true)} variant="outlined" />
        </>
      )}
      <Button label="Finish Mapping" onPress={handleSubmit} />
    </>
  );
}
