// import { FirestoreClient } from '@client';
import { Text, View } from '@client/elements';
// import * as eventTypes from '@shared/event/types';
import * as stepTypes from '@shared/step/types';

export interface Props {
  step: stepTypes.Props['step'];
}

export function StepCompleted({ step }: Props) {
  return (
    <View>
      <Text>Step Completed</Text>
      <Text>{step.props.title}</Text>
    </View>
  );
}

export default StepCompleted;
