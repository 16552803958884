import { ApiClient, useMount, useUnmount } from '@client';
import { Loading } from '@client/elements';
import { storage } from '@shared';
import * as environmentTypes from '@shared/environment/types';
import * as salesforceTypes from '@shared/salesforce/types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  children: React.ReactNode;
  environment: environmentTypes.Environment;
}
interface Value {
  // connectionId: string | null;
  // kustomer: typeof Kustomer | null;
  // salesforce: salesforceTypes.Client | null;
}

const EnvironmentContext = React.createContext<Value | undefined>(undefined);

export const EnvironmentProvider = ({ children, environment }: Props) => {
  const script = useRef<HTMLScriptElement>();
  const unsubscribe = useRef<() => void>();

  const [connectionId, setConnectionId] = useState<string | null>(null);
  // const [kustomer, setKustomer] = useState<typeof Kustomer | null>(null);
  const [loading, setLoading] = useState(true);
  // const [salesforce, setSalesforce] = useState<Value['salesforce']>(null);

  // Callback to run after script is loaded
  const onLoad = useCallback(async () => {
    // const parsed = await Linking.parseInitialURLAsync();
    switch (environment) {
      case 'kustomer': {
        if (Kustomer) {
          // setKustomer(Kustomer);
          Kustomer.initialize();
          // Kustomer.initialize(async (context) => {
          //   const kustomerConversation: string | undefined =
          //     context?.conversation?.relationships?.org?.data?.id;
          //   const kustomerOrganization: string | undefined =
          //     context?.conversation?.relationships?.org?.data?.id;
          //   if (kustomerConversation) {
          //     setConnectionId('kustomer_conversation_' + kustomerConversation);
          //   }
          //   if (kustomerOrganization) {
          //     await storage.setItem('kustomerOrganization', kustomerOrganization);
          //   }
          // });
        }
        break;
      }
      // case 'salesforce': {
      //   if (Sfdc) {
      //     console.log({ Sfdc });
      //   }
      //   break;
      // }
      // default:
    }
    setLoading(false);
  }, [environment]);

  useMount(async () => {
    // Callback to run after we have an environment
    switch (environment) {
      case 'kustomer': {
        script.current = document.createElement(`script`);
        // Load Kustomer SDK
        script.current.src = 'https://cdn.kustomerapp.com/card-js/latest/kustomer-card.min.js';
        document.head.append(script.current);
        script.current.addEventListener(`load`, onLoad);
        break;
      }
      case 'salesforce': {
        // Load Salesforce Canvas SDK
        require('@salesforce/canvas-js-sdk');
        onLoad();
        break;
      }
      default:
        setLoading(false);
    }
  });

  useUnmount(() => {
    script.current?.removeEventListener(`load`, onLoad);
    unsubscribe.current?.();
  });

  const value = useMemo(
    () => ({
      connectionId,
      // kustomer,
      // salesforce,
    }),
    [
      connectionId,
      // , kustomer
      // , salesforce
    ]
  );

  if (loading) return <Loading />;

  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>;
};

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error('useSalesforce must be within EnvironmentProvider');
  }
  return context;
};
