import { ApiClient, APP_URL } from '@client';
import { Button, Icon, View } from '@client/elements';
import React, { useCallback } from 'react';

export type Props = {
  onAuth: (url: string) => void;
};

export function AuthButtonKustomer({ onAuth }: Props) {
  const handlePress = useCallback(async () => {
    const {
      data: { url },
    } = await ApiClient.auth.url.post({
      provider: 'kustomer',
      redirect: APP_URL + '/auth/callback/kustomer',
    });
    onAuth(url);
  }, [onAuth]);

  return (
    <View sx={{ width: 230 }}>
      <Button
        icon={<Icon height={30} name="kustomer-icon" width={30} />}
        label="Kustomer Sign In"
        onPress={handlePress}
        sx={{ width: 220 }}
        variant="outlined"
      />
    </View>
  );
}
