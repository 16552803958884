import { Pressable, Icon, IconProps } from '@client/elements';

export interface IconButtonProps extends IconProps {
  onPress: () => void;
}

export function IconButton({ onPress, ...props }: IconButtonProps) {
  return (
    <Pressable onPress={onPress}>
      <Icon {...props} />
    </Pressable>
  );
}
