import { Button, View } from '@client/elements';
import _ from 'lodash';
import React, { useContext, useState } from 'react';

export interface FormArrayProps {
  children: React.ReactElement | React.ReactElement[];
  labelAdd?: string;
  name: string;
}

interface Value {
  path: string;
}

const FormArrayContext = React.createContext<Value | undefined>(undefined);

/** Creates an array a fields nested within a form */
export function FormArray({ children, labelAdd = 'Add Field', name }: FormArrayProps) {
  const [items, setItems] = useState<null[]>([]);

  return (
    <>
      {_.map(items, (item, index) => {
        return (
          <FormArrayContext.Provider key={index} value={{ path: `${name}.${index}` }}>
            <View
              sx={{
                borderColor: '$neutral',
                borderRadius: 5,
                borderStyle: 'solid',
                borderWidth: 1,
                m: '$1',
                p: '$1',
              }}
            >
              {children}
            </View>
          </FormArrayContext.Provider>
        );
      })}
      <Button label={labelAdd} onPress={() => setItems((s) => [...s, null])} variant="outlined" />
    </>
  );
}

export const useFormArray = () => {
  const context = useContext(FormArrayContext);
  return context;
};
