import { FlowCompleted, Step, StepCompleted } from '@client/components';
import { useCompany, useSidekick, useUser } from '@client/context';
import { FlatList, FlatListProps, Pressable, Text, View } from '@client/elements';
// import * as connectionTypes from '@shared/connection/types';
import * as flowTypes from '@shared/flow/types';
import _ from 'lodash';
import { useMemo } from 'react';

export interface Props {
  // connection: connectionTypes.Connection
}

const renderFlowItem: FlatListProps<flowTypes.Flow>['renderItem'] = ({ item }) => (
  <Pressable key={item.id} onPress={item.onPress} sx={{ p: '$1' }}>
    <Text>{item.title}</Text>
  </Pressable>
);

export function Sidekick() {
  const { companyId } = useCompany();
  const {
    connection,
    connectionId,
    flow,
    flowId,
    flows,
    isFlowComplete,
    isStepComplete,
    onStepBack,
    onStepComplete,
    onStepNext,
    onStepStart,
    onSelectFlowId,
    step,
    stepId,
    steps,
  } = useSidekick();
  const { userId } = useUser();

  const flowArray = useMemo(
    () =>
      _.chain(flows)
        .map((flow, id) => ({ ...flow, id, onPress: () => onSelectFlowId(id) }))
        .orderBy('title')
        .value(),
    [flows, onSelectFlowId]
  );

  // If selected flow is complete show summary
  if (flow && isFlowComplete && steps) {
    return <FlowCompleted flow={flow} />;
  }

  // If selected step is complete show summary
  if (isStepComplete && step) {
    return <StepCompleted step={step} />;
  }

  // Render a step once selected
  if (flow && flowId && step && stepId) {
    return (
      <View
        sx={{
          borderColor: '$dark',
          borderRadius: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          display: 'flex',
          margin: '$1',
          padding: '$1',
        }}
      >
        <Text sx={{ mb: '$1' }} variant="h4">
          {flow.title}
        </Text>
        <Step
          companyId={companyId}
          connectionKey={connectionId}
          flowId={flowId}
          instance={connection.instance}
          onStepBack={onStepBack}
          onStepComplete={onStepComplete}
          onStepNext={onStepNext}
          onStepStart={onStepStart}
          step={step}
          stepId={stepId}
          userId={userId}
        />
      </View>
    );
  }

  // Render a flow selection screen before flow selected
  return (
    <View sx={{ maxHeight: 435 }}>
      <Text sx={{ mb: '$1' }} variant="h4">
        Select a Flow
      </Text>
      <FlatList<flowTypes.Flow>
        data={flowArray}
        // height={300}
        renderItem={renderFlowItem}
        sx={{ borderStyle: 'solid', m: '$1' }}
      />
      {/* <ListView data={flowArray} renderRow={renderFlowItem} /> */}
    </View>
  );
}
