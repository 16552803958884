import { useMount } from '@client';
import { useVariable } from '@client/context';
import { Button, Loading, Html, Text, View } from '@client/elements';
import * as stepTypes from '@shared/step/types';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export default function DecisionTree({
  connectionKey,
  instance,
  // onStepComplete,
  step: {
    props: { richtext },
  },
}: stepTypes.Props<'RichText'>) {
  const { onRequestResults } = useVariable();

  const [html, setHtml] = useState<string>();

  useMount(async () => {
    const results = await onRequestResults<{ html: string }>({
      connectionKey,
      instance,
      variables: { html: richtext || '' },
    });
    setHtml(results.html);
  });

  if (_.isNil(html)) return <Loading />;

  return (
    <View>
      <Html html={html} />
    </View>
  );
}
