import { ApiClient, useMount } from '@client';
import { useVariable } from '@client/context';
import { Button, Loading, View } from '@client/elements';
import { Form, Field } from '@client/form';
import * as arenaTypes from '@shared/arena/types';
import * as powerqTypes from '@shared/powerq/types';
import * as stepTypes from '@shared/step/types';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export type ArenaCreateQualityProcessValues = {
  attributes: { [id: string]: boolean | number | string | string[] };
  description: string;
  name: string;
  ownerId: string;
  prefixId: string;
  templateId: string;
  type: string;
};

export function ArenaCreateQualityProcess({
  companyId,
  onStepComplete,
  step: {
    props: { inputs },
  },
}: stepTypes.Props<'ArenaCreateQualityProcess'>) {
  const [template, setTemplate] = useState<arenaTypes.QualityTemplate>();
  const variable = useVariable();

  const attributeFields = useMemo(() => {
    if (!template) return null;
    const allAttributes: { attributeId: string; attributeName: string; stepName: string }[] = [];
    for (const step of template.steps) {
      for (const attribute of step.attributes) {
        allAttributes.push({
          attributeId: attribute.guid,
          attributeName: attribute.name,
          stepName: step.name,
        });
      }
    }
    return _.map(inputs.attributes, (v, attributeId) => {
      const config = _.find(allAttributes, (a) => a.attributeId === attributeId);
      return {
        label: `${config?.stepName}:\n${config?.attributeName}`,
        name: `attributes.${attributeId}`,
      };
    });
  }, [inputs, template]);

  const [initialValues, setInitialValues] = useState<ArenaCreateQualityProcessValues>();

  const handleCreate = useCallback(
    async (values: ArenaCreateQualityProcessValues) => {
      const { data: dataProcess } = await ApiClient.company(companyId).endpoint({
        integration: 'arena',
        type: 'arena-create-quality-process',
        request: {
          description: values.description,
          ownerId: values.ownerId,
          name: values.name,
          prefixId: values.prefixId,
          templateId: values.templateId,
          type: values.type,
        },
      });
      try {
        await ApiClient.company(companyId).endpoint({
          integration: 'arena',
          type: 'arena-update-quality-process',
          request: {
            // attributes: _.map(config.arenaStepAttributes, (a) => ({
            //   attributeId: a.attributeId,
            //   stepId: a.stepId,
            //   value: 'test',
            // })),
            processId: dataProcess.guid,
          },
        });
      } catch {
        // If any of these fail, roll back the created quality process
        await ApiClient.company(companyId).endpoint({
          integration: 'arena',
          type: 'arena-delete-quality-process',
          request: { processId: dataProcess.guid },
        });
      }
    },
    [companyId]
  );

  useMount(async () => {
    console.log({ inputs });
    const { data: dataTemplate } = await ApiClient.company(companyId).endpoint({
      integration: 'arena',
      type: 'arena-fetch-quality-template',
      request: { templateId: inputs.templateId },
    });
    console.log({ dataTemplate });
    setTemplate(dataTemplate);
    const values = await variable.onRequestResults<ArenaCreateQualityProcessValues>({
      variables: {
        description: inputs.name || '',
        name: inputs.name || '',
        ..._.reduce(
          inputs.attributes,
          // (m, value, id) => ({ ...m, [`attributes.${id}`]: { accessor: value } }),
          (m, value, id) => ({ ...m, [`attributes.${id}`]: value }),
          {}
        ),
      },
    });
    console.log({ values });
    setInitialValues(values);
  });

  if (!initialValues) return <Loading />;

  return (
    <Form
      initialValues={initialValues}
      labelSubmit="Create Quality Process"
      onSubmit={handleCreate}
    >
      <Field label="Name" name="name" />
      <Field label="Description" name="description" />
      <Field label="Type" name="type" />
      {_.map(attributeFields, (field, index) => (
        <Field key={index} {...field} />
      ))}
    </Form>
  );
}
