import { ApiClient, AuthClient, APP_URL, createParam, handleError, useMount } from '@client';
import { AuthForm } from '@client/components';
import { Alert, Loading, View } from '@client/elements';
import { Field, Form } from '@client/form';
import { assertExhaustive, storage } from '@shared';
import * as apiTypes from '@shared/api/types';
import * as authTypes from '@shared/auth/types';
import * as salesforceConstants from '@shared/salesforce/constants';
// import * as userTypes from '@shared/user/types';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import _ from 'lodash';
import { useCallback, useState } from 'react';

interface Props {
  code?: string;
  onSuccess?: () => void;
  provider: authTypes.Provider;
  state?: string;
}

type Params = {
  oauth: Exclude<authTypes.Provider, 'email'>;
};

const { useParam } = createParam<Params>();

export function AuthCallback({ code, provider, onSuccess, state }: Props) {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState<apiTypes.ExceptionPayload>();
  const [emailed, setEmailed] = useState<string>();
  const [oauth] = useParam('oauth');

  const handleVerifyEmail = useCallback(
    async ({ email }: { email: string }) => {
      try {
        await AuthClient.sendSignInLinkToEmail({ email, oauth: provider });
        await storage.setItem('userEmail', email);
        setEmailed(email);
      } catch (err) {
        setError(handleError(err));
      }
    },
    [provider]
  );

  useMount(async () => {
    try {
      // let successPath = '/';
      let token: string | null = null;
      // let user: userTypes.User | null = null;
      switch (provider) {
        case 'email': {
          const url = await Linking.getInitialURL();
          const email = await storage.getItem<string>('userEmail');
          if (email && url) {
            if (AuthClient.isSignInWithEmailLink(url)) {
              await AuthClient.signInWithEmailLink(email, url);
              // Force them to finish the oauth process
              if (oauth) {
                await AuthClient.signOut();
              }
              setVerified(true);
            }
          }
          break;
        }
        case 'google':
        case 'kustomer': {
          if (!code) throw new Error('No Code Provided');
          const { data } = await ApiClient.auth.user.post({
            code,
            provider,
            redirect: APP_URL + `/auth/callback/${provider}`,
          });
          token = data.token;
          // user = data.user;
          break;
        }
        case 'salesforce': {
          if (!code) throw new Error('No Code Provided');
          let sandbox = false;
          try {
            if (state) {
              const sandboxState = JSON.parse(state)?.sandbox;
              sandbox = sandboxState === true || sandboxState === 'true';
            }
          } catch {
            // Failing silently because json parse may throw error
          }
          const { data } = await ApiClient.auth.user.post({
            code,
            provider,
            redirect: APP_URL + '/auth/callback/salesforce',
            sandbox,
          });
          token = data.token;
          // user = data.user;
          break;
        }
        case 'zendesk': {
          if (!code) throw new Error('No Code Provided');
          const subdomain = await storage.getItem<string>('zendeskSubdomain');
          if (subdomain) {
            const { data } = await ApiClient.auth.user.post({
              code,
              provider,
              // redirect: APP_URL + '/auth/callback/zendesk',
              redirect: APP_URL + '/auth/zendesk', // Temporarily changing this until global oauth client updated
              subdomain,
            });
            token = data.token;
            // user = data.user;
          }
          break;
        }
        default:
          assertExhaustive(provider);
      }
      if (token) {
        await storage.setItem('customToken', token);
        WebBrowser.maybeCompleteAuthSession();
        // await AuthClient.signInWithCustomToken(token);
        // onSuccess(successPath);
      }
    } catch (err) {
      setError(handleError(err));
    }
  });

  if (verified) {
    if (oauth) {
      return (
        <View>
          <Alert
            message={`You have successfully verified your email address, now you may finish linking your ${_.capitalize(
              oauth
            )} account`}
            variant="success"
          />
          <AuthForm options={{ [oauth]: true }} onSuccess={onSuccess} />
        </View>
      );
    }
    return (
      <Alert message="You have successfully verified your email address, now you now close this tab" />
    );
  }

  if (emailed) {
    return (
      <Alert
        message={`A verification email has been sent to ${emailed}, please click on the link to finish signing in`}
        variant="success"
      />
    );
  }

  if (error) {
    if ('type' in error) {
      switch (error.type) {
        case 'email-unverified': {
          return (
            <Form<{ email: string }>
              initialValues={error.details}
              label="Please Verify Email Address"
              labelSubmit="Verify"
              onSubmit={handleVerifyEmail}
            >
              <Field disabled label="Email Address" name="email" type="plain-text" />
            </Form>
          );
        }
      }
    }
    return <Alert message={error.message || 'Error during authentication'} variant="error" />;
  }

  return <Loading />;
}
