import { Pressable, Text } from '@client/elements';
import * as styleTypes from '@shared/style/types';
import { Theme } from 'dripsy';
import { useMemo } from 'react';

export interface ButtonProps {
  disabled?: boolean;
  icon?: React.ReactElement;
  label: string;
  loading?: boolean;
  onPress?: () => void;
  sx?: styleTypes.SX;
  variant?: keyof Theme['button'];
}

// const StyledPressable = styled(Pressable, {
//   defaultVariant: 'primary',
//   themeKey: 'button',
// }) as React.ReactElement;

export function Button({ icon, label, sx, variant = 'primary', ...props }: ButtonProps) {
  const _props = useMemo(() => ({ ...props, disabled: props.disabled || props.loading }), [props]);

  switch (variant) {
    case 'outlined':
      return (
        <Pressable
          {..._props}
          sx={{
            alignItems: 'center',
            bg: '$light',
            borderColor: '$dark',
            borderRadius: 8,
            borderStyle: 'solid',
            borderWidth: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            margin: '$1',
            padding: '$2',
            ...sx,
          }}
        >
          {icon}
          <Text
            sx={{
              color: '$primary',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {label}
          </Text>
        </Pressable>
      );
    case 'primary':
    default:
      return (
        <Pressable
          {..._props}
          sx={{
            alignItems: 'center',
            bg: '$primary',
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            margin: '$1',
            padding: '$2',
            ...sx,
          }}
        >
          {icon}
          <Text
            sx={{
              color: '$light',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {label}
          </Text>
        </Pressable>
      );
  }
}
