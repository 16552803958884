import { SxProp } from '@client';
import { useForm, useFormArray } from '@client/form';
import _ from 'lodash';
import { useMemo } from 'react';

import FieldPlainText from './FieldPlainText';
import FieldSelectSingle from './FieldSelectSingle';

// TODO: Strongly type field names
// type _Values = { [field: string]: any };
// type FieldProps<Values extends _Values = _Values> = {
type FieldProps<T extends number | string> = {
  disabled?: boolean;
  label: string;
  // name: Extract<keyof Values, 'string'>;
  name: string;
  /** Callback to run with selected value */
  onSelect?: (value: T) => void;
  options?: { label: string; value: string }[];
  placeholder?: string;
  required?: boolean;
  sx?: SxProp;
  type?: 'password' | 'plain-text' | 'select-single';
};

// export default function Field<Values extends _Values = _Values>({
export default function Field<T extends number | string>({
  options = [],
  name,
  onSelect,
  type = 'plain-text',
  ...props
}: FieldProps<T>) {
  const form = useForm();
  const formArray = useFormArray();

  const path = useMemo(() => (formArray?.path ? `${formArray.path}.${name}` : name), []);

  const error = useMemo(() => _.get(form.errors, path), [form.errors, path]);

  console.log('OPTIONS: ', options);

  switch (type) {
    case 'password':
    case 'plain-text': {
      return <FieldPlainText error={error} name={path} type={type} {...props} />;
    }
    case 'select-single': {
      return <FieldSelectSingle name={path} onSelect={onSelect} options={options} {...props} />;
    }
    default:
      return null;
  }
}
