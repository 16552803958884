import { liquid } from '@shared';
import * as integrationTypes from '@shared/integration/types';
import * as variableConstants from '@shared/variable/constants';
import * as variableTypes from '@shared/variable/types';
import { unflatten } from 'flat';
import _ from 'lodash';
import { DateTime } from 'luxon';

const FormulaParser = require('hot-formula-parser').Parser;

export function buildResultsObject({
  cache,
  request,
}: {
  cache: variableTypes.Cache;
  request: variableTypes.ResultsRequest;
}): variableTypes.Results {
  const results: variableTypes.Results = {};
  for (const name of Object.keys(request.variables)) {
    const config = _.get(request.variables, name);
    if (typeof config === 'string') {
      results[name] = convertTemplate(config, cache.labels);
    } else {
      const label = _.get(cache.labels, name);
      const value = _.get(cache.values, name);
      if (config.raw) {
        results[name] = convertValue({ format: config.format, value });
      } else {
        results[name] = label || value || '';
      }
    }
  }
  return results;
}

export function buildVariablesArray({ request }: { request: variableTypes.ResultsRequest }) {
  const variables: variableTypes.Variable[] = [];
  for (const name of Object.keys(request.variables)) {
    const config = request.variables[name];
    let format: variableTypes.Format = 'text';
    let raw = false;
    if (typeof config === 'string') {
      const accessors = findAccessorsInTemplate(config);
      for (const accessor of accessors) {
        const source = getAccessorSource(accessor);
        variables.push({ accessor, format, name, raw, source });
      }
    } else {
      const accessor = config.accessor;
      raw = config.raw;
      format = config.format;
      const source = getAccessorSource(accessor);
      variables.push({ accessor, format, name, raw, source });
    }
  }
  return variables;
}

export const cacheFields = ({
  cache,
  fields,
  integration,
  type,
}: {
  cache: variableTypes.Cache;
  fields: variableTypes.Field[];
  integration: integrationTypes.Name;
  type: variableTypes.VariableObjectType;
}) => {
  // Prefix with integration and type
  fields.forEach((field) => {
    _.merge(cache, { fields: { [integration]: { [type]: { [field.id]: field } } } });
    // Also cache the variable option
    cacheVariableOption({
      cache,
      field: { accessor: String(field.id), label: field.label },
      integration,
      type,
    });
  });
};

export const cacheObject = ({
  cache,
  integration,
  object,
}: {
  cache: variableTypes.Cache;
  integration: integrationTypes.Name;
  object: integrationTypes.IntegrationObject;
}) => {
  _.merge(cache, { objects: { [integration]: object } });
};

export const cacheVariableOption = ({
  cache,
  field,
  integration,
  type,
}: {
  cache: variableTypes.Cache;
  field: { accessor: string; label: string };
  integration?: integrationTypes.Name;
  type?: variableTypes.VariableObjectType;
}) => {
  if (integration && type) {
    // Prefix with integration and type
    _.merge(cache, {
      labels: {
        [`${integration}_${type}_${field.accessor}`]: `${_.capitalize(integration)} ${_.capitalize(
          type
        )}: ${field.label}`,
      },
    });
  } else if (integration) {
    // Prefix with integration
    _.merge(cache, {
      labels: {
        [`${integration}_${field.accessor}`]: `${_.capitalize(integration)}: ${field.label}`,
      },
    });
  } else {
    // Standard variables
    _.merge(cache, {
      labels: { [field.accessor]: field.label },
    });
  }
};

export const cacheVariableValue = ({
  cache,
  field,
  integration,
  type,
}: {
  cache: variableTypes.Cache;
  field: {
    id: string | number;
    value: variableTypes.Result | undefined;
  };
  integration?: integrationTypes.Name;
  type?: variableTypes.VariableObjectType;
}) => {
  const { id, value } = field;
  if (integration && type) {
    // Allow short access to default objects
    if (variableConstants.DEFAULT_INTEGRATION_OBJECTS.includes(type)) {
      _.merge(cache, {
        values: {
          [`${integration}_${type}_${id}`]: value,
          [`${integration}_${id}`]: value,
        },
      });
    } else {
      _.merge(cache, {
        values: {
          [`${integration}_${type}_${id}`]: value,
        },
      });
    }
  } else if (integration) {
    _.merge(cache, {
      values: {
        [`${integration}_${id}`]: value,
      },
    });
  } else {
    _.merge(cache, {
      values: {
        [id]: value,
      },
    });
  }
};

export const cleanTemplate = (template: string) =>
  _.replace(_.replace(template, /&lt;%=/g, '<%='), /%&gt;/g, '%>');

// For backwards compatibility w/ previous variable naming
export const convertAccessor = (accessor: string) =>
  _.chain(accessor)
    .replace(/salesforce_/g, 'salesforce.Case.')
    .replace(/zendesk_/g, 'zendesk.ticket.')
    .value();

export function convertTemplate(
  template: string | null | undefined,
  results: { [field: string]: any },
  type?: string | null | undefined
) {
  let converted: string | number | boolean = _.chain(cleanTemplate(template ?? ''))
    .split('<%= ')
    .map((s, i) => {
      const pattern = /(\S+) %>/;
      const match = s.match(pattern);
      // Leave it alone if there's no match
      let newString = i > 0 ? `<%= ${s}` : s;
      if (match) {
        const rawValue = _.get(unflatten(results || {}), match[1]);
        let value: boolean | number | string = rawValue || '';
        switch (typeof rawValue) {
          case 'boolean':
            value = rawValue;
            break;
          case 'number':
            // Make sure this stays a number if 0
            value = Number(rawValue);
            break;
          case 'object':
            if (rawValue && type === 'array') {
              value = JSON.stringify(rawValue);
            } else {
              value = rawValue || '';
            }
            break;
          case 'string':
            if (rawValue && type === 'dateISO') {
              try {
                value = new Date(rawValue).toISOString().substring(0, 10);
              } catch (err) {
                console.error(err);
              }
            }
            break;
          default:
            value = rawValue || '';
        }
        newString = _.replace(s, pattern, value as string);
      }
      return newString;
    })
    .join('')
    .value();

  const requestedFunction = converted.match(/^function\.([a-z]+)\(.+\)$/)?.[1];
  switch (requestedFunction) {
    case 'addYears': {
      const argString = converted.substring(18, converted.indexOf(')'));
      const argArray = _.split(argString, ',');
      let [date, value, format] = argArray;
      if (format === 'YYYY-MM-DD' || !format) format = 'yyyy-MM-dd';

      try {
        const isoDate = new Date(date).toISOString();
        converted = DateTime.fromISO(isoDate, { zone: 'utc' })
          .plus({ years: _.toNumber(value) })
          .toFormat(format);
      } catch (err) {
        console.error(err);
      }

      break;
    }
    case 'excel': {
      const parser = new FormulaParser();
      const formula = converted.substring(15, converted.length - 1);
      const { error, result } = parser.parse(formula);
      if (!error) {
        const resultType = typeof result;
        // Return early so liquid doesn't convert the formula result
        switch (resultType) {
          case 'object':
            // Converting object results to string
            if (_.isDate(result)) return result.toISOString().substring(0, 10);
            return JSON.stringify(result);
          default:
            return result;
        }
      }
      break;
    }
    case 'formatDate': {
      try {
        const args = converted.match(/^function\.[a-z]+\((.+)\)$/)?.[1];
        const argsArray = _.split(args, ',');
        if (argsArray[0] && argsArray[1]) {
          const isoDate = new Date(argsArray[0]).toISOString();
          converted = DateTime.fromISO(isoDate).toFormat(
            _.chain(argsArray[1]).trim().trim("'").trim('"').value()
          );
        }
      } catch (err) {
        console.error(err);
      }
      break;
    }
    case 'regexMatch': {
      const argMatches = converted.matchAll(/function.regexMatch\(([\s\S]+),\/(.+)\/,(\d+)\)/g);
      if (argMatches) {
        for (const argMatch of argMatches) {
          const text = argMatch?.[1];
          const regex = argMatch?.[2];
          const occurence = argMatch?.[3] && _.toNumber(argMatch[3]);
          const targetMatches = regex && text && [...text.matchAll(new RegExp(regex, 'g'))];
          if (targetMatches && occurence) {
            const targetMatch = targetMatches[occurence - 1];
            converted = targetMatch ? targetMatch[1] || targetMatch[0] || '' : '';
          }
        }
      }
      break;
    }
  }

  converted = liquid.parseAndRenderSync(converted, results);

  switch (type) {
    case 'array':
      try {
        converted = JSON.parse(converted as string);
      } catch {}
      break;
    case 'number':
      converted = _.toNumber(converted);
      break;
  }

  return converted;
}

export function convertValue({
  format,
  value,
}: {
  format: variableTypes.Variable['format'];
  value: any;
}) {
  switch (format) {
    case 'boolean':
      return value === true || _.toLower(value) === 'true' || false;
    case 'number':
      return _.toNumber(value);
    case 'text':
    default:
      return value;
  }
}

export function getAccessorSource(accessor: string): variableTypes.Source {
  if (_.startsWith(accessor, 'zendesk_')) {
    return {
      integration: 'zendesk',
      object: 'ticket',
    };
  } else if (_.startsWith(accessor, 'salesforce_')) {
    return {
      integration: 'salesforce',
      object: 'Case',
    };
  } else if (_.startsWith(accessor, 'freshchat_')) {
    if (_.startsWith(accessor, 'freshchat_agent_')) {
      return {
        integration: 'freshchat',
        object: 'agent',
      };
    } else if (_.startsWith(accessor, 'freshchat_customer_')) {
      return {
        integration: 'freshchat',
        object: 'customer',
      };
    } else {
      // Default to Freshchat Conversation
      return {
        integration: 'freshchat',
        object: 'conversation',
      };
    }
  } else if (_.startsWith(accessor, 'freshdesk_')) {
    if (_.startsWith(accessor, 'freshdesk_agent_')) {
      return {
        integration: 'freshdesk',
        object: 'agent',
      };
    } else if (_.startsWith(accessor, 'freshdesk_cannedResponse_')) {
      return {
        integration: 'freshdesk',
        object: 'cannedResponse',
      };
    } else if (_.startsWith(accessor, 'freshdesk_customer_')) {
      return {
        integration: 'freshdesk',
        object: 'customer',
      };
    } else {
      // Default to Freshdesk Ticket
      return {
        integration: 'freshdesk',
        object: 'ticket',
      };
    }
  } else if (
    _.startsWith('asset') ||
    _.startsWith('infoleaseasset') ||
    _.startsWith('parent.infoleaseasset')
  ) {
    return {
      integration: 'asset',
      object: 'instance',
    };
  } else if (
    _.startsWith('filing_') ||
    _.startsWith('uccfiling_') ||
    _.startsWith('parent.uccfiling')
  ) {
    return {
      integration: 'filing',
      object: 'instance',
    };
  } else {
    const sourceParts = _.chain(accessor).split('.').value();
    return {
      integration: sourceParts[0] as integrationTypes.Name,
      object: sourceParts[1] as string,
    };
  }
}

export function findAccessorsInTemplate(template: string): string[] {
  return _.chain(cleanTemplate(template || ''))
    .split('<%= ')
    .drop()
    .map((s) => _.replace(s, / %>(.|\n)*/g, ''))
    .value();
}

export const findVariableSource = (name: string) => {
  if (_.startsWith(name, 'salesforce_') || _.startsWith(name, 'salesforce.Case')) {
    return 'salesforce.Case';
  } else if (_.startsWith(name, 'salesforce.')) {
    return `salesforce.${_.split(name, '.')[1]}`;
  } else if (_.startsWith(name, 'zendesk_') || _.startsWith(name, 'zendesk.ticket')) {
    return 'zendesk.ticket';
  } else if (
    _.startsWith(name, 'recurly.') ||
    _.startsWith(name, 'salesforce.') ||
    _.startsWith(name, 'zendesk.')
  ) {
    return _.chain(name).split('.').slice(0, 2).join('.').value();
  }

  return null;
};

export const findVariablesInTemplate = (template: string) => {
  return _.chain(cleanTemplate(template || ''))
    .split('<%= ')
    .drop()
    .map((s) => _.replace(s, / %>(.)*/g, ''))
    .value();
};
