import * as variableTypes from '@shared/variable/types';

export const DEFAULT_OPTIONS: variableTypes.Option[] = [
  { accessor: 'user.email', label: 'User Email', type: 'text', updateable: false },
];

export const DEFAULT_INTEGRATION_OBJECTS: variableTypes.VariableObjectType[] = [
  'conversation',
  'ticket',
];
