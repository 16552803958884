export interface ArenaLogoProps {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}
export function ArenaLogo({ width = 200, height = 50, style = {} }: ArenaLogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.31 82.91"
      height={height}
      width={width}
      style={style}
    >
      <g id="Layer_2" data-name="Layer 2">
        <path
          className="cls-1"
          d="M384.68,70a5.25,5.25,0,1,1,5.25-5.25A5.26,5.26,0,0,1,384.68,70m0-9.95a4.7,4.7,0,1,0,4.7,4.7,4.71,4.71,0,0,0-4.7-4.7"
          style={{ fill: '#4f595b' }}
          transform="translate(-106.62 -35.05)"
        />
        <path
          className="cls-1"
          d="M385.27,62.06a1.8,1.8,0,0,1,1.23.39,1.33,1.33,0,0,1,.45,1.06,1.73,1.73,0,0,1-.23.89,1.22,1.22,0,0,1-.73.52h0a.87.87,0,0,1,.39.16.85.85,0,0,1,.24.26,1.2,1.2,0,0,1,.13.33c0,.13.05.26.07.39s0,.27,0,.41,0,.28,0,.42a2.34,2.34,0,0,0,.09.38,1.09,1.09,0,0,0,.17.32h-.82a.38.38,0,0,1-.1-.23,3,3,0,0,1,0-.33c0-.12,0-.25,0-.39a2.7,2.7,0,0,0-.05-.41,3.82,3.82,0,0,0-.08-.39.86.86,0,0,0-.15-.32.61.61,0,0,0-.26-.21.89.89,0,0,0-.43-.09h-1.81V67.6h-.73V62.06Zm.15,2.51a.92.92,0,0,0,.41-.15.74.74,0,0,0,.28-.3,1,1,0,0,0,.1-.49,1,1,0,0,0-.23-.69,1,1,0,0,0-.75-.26h-1.84v1.93h1.54a2.56,2.56,0,0,0,.49,0"
          style={{ fill: '#4f595b' }}
          transform="translate(-106.62 -35.05)"
        />
        <path
          className="cls-2"
          d="M234.09,87.75q-3.94,5.81-11.79,5.8a14.91,14.91,0,0,1-11.58-4.94,17.45,17.45,0,0,1-4.35-12,17.45,17.45,0,0,1,4.35-12,15.29,15.29,0,0,1,11.91-4.94,13.09,13.09,0,0,1,11.46,5.86V60.1H239V93h-4.94ZM223,89.07a10.41,10.41,0,0,0,8.43-3.69,13.1,13.1,0,0,0,3-8.82,13.1,13.1,0,0,0-3-8.82A10.41,10.41,0,0,0,223,64.05a10.94,10.94,0,0,0-8.56,3.69,12.79,12.79,0,0,0-3.09,8.82,12.79,12.79,0,0,0,3.09,8.82A10.94,10.94,0,0,0,223,89.07Z"
          style={{ fill: '#4e585a' }}
          transform="translate(-106.62 -35.05)"
        />
        <path
          className="cls-2"
          d="M265,59.57v4.94h-.59a10.54,10.54,0,0,0-8.69,4.28,15.42,15.42,0,0,0-3.16,9.74V93h-4.94V60.1h4.94v8.1a12.45,12.45,0,0,1,12.11-8.63Z"
          style={{ fill: '#4e585a' }}
          transform="translate(-106.62 -35.05)"
        />
        <path
          className="cls-2"
          d="M284.13,89.07q6.64,0,9.55-5.53h5.4a15.9,15.9,0,0,1-5.44,7.24,15.59,15.59,0,0,1-9.58,2.77A15.93,15.93,0,0,1,272,88.61a16.85,16.85,0,0,1-4.54-12,17.17,17.17,0,0,1,4.34-12,17,17,0,0,1,24,0,17.13,17.13,0,0,1,4.34,12v2H272.48A11.9,11.9,0,0,0,276.1,86,11.12,11.12,0,0,0,284.13,89.07Zm-.39-25a10.88,10.88,0,0,0-7.64,2.83,11.72,11.72,0,0,0-3.56,7.05h22.39a11.72,11.72,0,0,0-3.56-7.05A10.87,10.87,0,0,0,283.74,64.05Z"
          style={{ fill: '#4e585a' }}
          transform="translate(-106.62 -35.05)"
        />
        <path
          className="cls-2"
          d="M311.06,60.1v5.13a16.47,16.47,0,0,1,4.34-4,10.64,10.64,0,0,1,5.87-1.65,15.72,15.72,0,0,1,5.66.92,11.6,11.6,0,0,1,4.15,2.77,14.21,14.21,0,0,1,4.08,10.6V93h-4.94V74a10.08,10.08,0,0,0-2.57-7.24,8.76,8.76,0,0,0-6.65-2.7,9.8,9.8,0,0,0-7,2.77,9.64,9.64,0,0,0-2.9,7.24V93h-4.94V60.1Z"
          style={{ fill: '#4e585a' }}
          transform="translate(-106.62 -35.05)"
        />
        <path
          className="cls-2"
          d="M369.07,87.75q-4,5.81-11.79,5.8a14.94,14.94,0,0,1-11.59-4.94,17.49,17.49,0,0,1-4.34-12,17.49,17.49,0,0,1,4.34-12,15.33,15.33,0,0,1,11.92-4.94,13.09,13.09,0,0,1,11.46,5.86V60.1H374V93h-4.94Zm-11.13,1.32a10.43,10.43,0,0,0,8.43-3.69,13.15,13.15,0,0,0,3-8.82,13.15,13.15,0,0,0-3-8.82,10.43,10.43,0,0,0-8.43-3.69,10.93,10.93,0,0,0-8.56,3.69,12.79,12.79,0,0,0-3.09,8.82,12.79,12.79,0,0,0,3.09,8.82A10.93,10.93,0,0,0,357.94,89.07Z"
          style={{ fill: '#4e585a' }}
          transform="translate(-106.62 -35.05)"
        />
        <polygon
          className="cls-3"
          points="59.73 24.91 69.15 19.46 35.53 0 13.28 13.36 22.7 18.8 35.53 10.88 59.73 24.91"
          style={{ fillRule: 'evenodd' }}
        />
        <polygon
          className="cls-2"
          style={{ fill: '#4e585a' }}
          points="22.57 75.41 22.57 64.53 9.43 56.6 9.43 31.97 0 26.52 0 62.04 22.57 75.41"
        />
        <polygon
          className="cls-4"
          points="55.85 47.96 55.88 57.59 35.69 69.15 35.69 82.91 26.88 77.46 26.89 64.33 55.85 47.96"
          style={{ fill: '#6ebe4c' }}
        />
        <polygon
          className="cls-4"
          points="22.57 59.39 14.03 54.33 14.07 29.57 0 21.3 8.95 15.92 22.56 23.8 22.57 59.39"
          style={{ fill: '#6ebe4c' }}
        />
        <polygon
          className="cls-2"
          points="69.59 24.15 69.5 35.53 60.03 40.98 26.62 21.63 35.89 16.07 59.72 29.79 69.59 24.15"
          style={{ fill: '#4e585a' }}
        />
        <polygon
          className="cls-2"
          points="40.01 82.87 69.59 65.89 69.59 40.28 60.16 45.66 60.16 60.76 40 72.3 40.01 82.87"
          style={{ fill: '#4e585a' }}
        />
      </g>
    </svg>
  );
}
