export interface ArenaIconProps {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}
export function ArenaIcon({ width = 24, height = 24, style }: ArenaIconProps) {
  return (
    <svg
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      style={style}
      width={width}
    >
      <rect width="48" height="48" rx="4" fill="#3C4647" />
      <path
        clipRule="evenodd"
        d="M31.2901 19.2107L34.0169 17.6331L24.2849 12L17.8442 15.8673L20.571 17.442L24.2849 15.1494L31.2901 19.2107Z"
        fill="#5BB73B"
        fillRule="evenodd"
      />
      <path
        d="M20.5333 33.829V30.6795L16.7297 28.384V21.2544L14 19.6768V29.9587L20.5333 33.829Z"
        fill="white"
      />
      <path
        d="M30.1669 25.883L30.1756 28.6706L24.3312 32.0169V36L21.781 34.4224L21.7839 30.6216L30.1669 25.883Z"
        fill="#5BB73B"
      />
      <path
        d="M20.5333 29.1916L18.0613 27.7269L18.0728 20.5596L14 18.1657L16.5908 16.6083L20.5305 18.8894L20.5333 29.1916Z"
        fill="#5BB73B"
      />
      <path
        d="M34.1442 18.9907L34.1182 22.2849L31.3769 23.8625L21.7057 18.2612L24.3891 16.6518L31.2872 20.6233L34.1442 18.9907Z"
        fill="white"
      />
      <path
        d="M25.5817 35.9884L34.1442 31.0732V23.6599L31.4145 25.2172V29.5882L25.5788 32.9287L25.5817 35.9884Z"
        fill="white"
      />
    </svg>
  );
}
