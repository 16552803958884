import { Icon, Text } from '@client/elements';
import { Pressable, SxProp } from 'dripsy';

export interface CheckboxProps {
  checked: boolean;
  label: string;
  onPress: (checked: boolean) => void;
  sx?: SxProp;
}

export function Checkbox({ checked, label, onPress, sx }: CheckboxProps) {
  function onCheckmarkPress() {
    onPress(!checked);
  }

  return (
    <Pressable
      onPress={onCheckmarkPress}
      sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', m: '$1', ...sx }}
    >
      {checked ? <Icon name="checkbox" /> : <Icon name="square-outline" />}
      <Text>{label}</Text>
    </Pressable>
  );
}
