import { ApiClient, PowerClient, useError, useMount, usePrevious, useTrigger } from '@client';
import { useCompany, useUser } from '@client/context';
import { Alert, Loading, Text, View } from '@client/elements';
import { Form, Field, FormArray } from '@client/form';
import * as arenaTypes from '@shared/arena/types';
import * as connectionUtils from '@shared/connection/utils';
import * as powerqTypes from '@shared/powerq/types';
import * as salesforceTypes from '@shared/salesforce/types';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ConfigureAttribute } from './ConfigureAttribute';
import { ConfigureAttributes } from './ConfigureAttributes';

const powerq = 'salesforce-arena-quality';

export interface ConfigureProps {
  initialValues?: ConfigureValues;
  onSubmit: (values: ConfigureValues) => Promise<void>;
}

type Config = powerqTypes.Config<typeof powerq>;
type ConfigStepAttributes = Pick<Config, 'arenaStepAttributes'>;
type ConfigProcess = Pick<
  Config,
  | 'arenaDescriptionVariableAccessor'
  | 'arenaNameVariableAccessor'
  | 'arenaOwnerId'
  | 'arenaPrefixId'
  | 'arenaProcessType'
  | 'arenaTemplateId'
>;
type ConfigSalesforce = Pick<Config, 'salesforceObjectName'>;

export type ConfigureValues = powerqTypes.Config<typeof powerq>;

export function Configure({ initialValues, onSubmit }: ConfigureProps) {
  const { companyId } = useCompany();
  const { connectionId } = useUser();
  const { error, handleError } = useError();

  const [arenaInputs, setArenaInputs] = useState<arenaTypes.QualityConfigInputs>();
  const [ready, setReady] = useState(false);
  const [salesforceObjectMetadata, setSalesforceObjectMetadata] =
    useState<salesforceTypes.SObjectMetadata>();
  const [salesforceObjects, setSalesforceObjects] = useState<salesforceTypes.SObject[]>();

  const [configProcess, setConfigProcess] = useState<ConfigProcess>();
  const [configSalesforce, setConfigSalesforce] = useState<ConfigSalesforce>();
  const [configStepAttributes, setConfigStepAttributes] = useState<ConfigStepAttributes>();

  const [loading, setLoading] = useState(false);

  const arenaOwnerOptions = useMemo(
    () => _.map(arenaInputs?.owners, (o) => ({ label: o.fullName, value: o.guid })),
    [arenaInputs?.owners]
  );
  const arenaTemplateOptions = useMemo(
    () => _.map(arenaInputs?.templates, (t) => ({ label: t.name, value: t.guid })),
    [arenaInputs?.templates]
  );
  const arenaTemplateSteps = useMemo(
    () =>
      _.chain(arenaInputs?.templates)
        .find((t) => t.guid === configProcess?.arenaTemplateId)
        .get('steps')
        .value(),
    [arenaInputs?.templates, configProcess?.arenaTemplateId]
  );
  const salesforceObjectOptions = useMemo(
    () =>
      _.chain(salesforceObjects)
        .filter((o) => !!o.updateable)
        .map((o) => ({ label: o.name, value: o.name }))
        .value(),
    [salesforceObjects]
  );

  const currentObjectName = useMemo(
    () => (connectionId && connectionUtils.getElements(connectionId)?.context) || null,
    [connectionId]
  );

  // This should be the last step with the other configs already
  const handleSubmit = useCallback(async (values: ConfigureValues) => onSubmit(values), [onSubmit]);

  useTrigger(configProcess, async () => {
    setLoading(true);
    try {
      if (!configSalesforce) throw new Error('Salesforce config not set');
      const { data } = await ApiClient.company(companyId).endpoint({
        type: 'salesforce-fetch-object-metadata',
        integration: 'salesforce',
        request: { objectName: configSalesforce.salesforceObjectName },
      });
      setSalesforceObjectMetadata(data);
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  });

  useTrigger(configSalesforce, async () => {
    setLoading(true);
    try {
      const { data } = await ApiClient.company(companyId).endpoint({
        type: 'arena-fetch-quality-inputs',
        integration: 'arena',
        request: null,
      });
      setArenaInputs(data);
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
  });

  useMount(async () => {
    try {
      const { data } = await ApiClient.company(companyId).endpoint({
        type: 'salesforce-list-objects',
        integration: 'salesforce',
        request: null,
      });
      setSalesforceObjects(data);
    } catch (err) {
      handleError(err);
    }
    setReady(true);
  });

  if (!ready || loading) return <Loading />;

  if (!configSalesforce) {
    return (
      <>
        <Text sx={{ m: '$2' }} variant="h3">
          Select Source Salesforce Object
        </Text>
        <Form<ConfigSalesforce>
          initialValues={{
            salesforceObjectName:
              initialValues?.salesforceObjectName || currentObjectName || undefined,
          }}
          onSubmit={setConfigSalesforce}
        >
          <Field
            label="Object Name"
            name="salesforceObjectName"
            options={salesforceObjectOptions}
            type="select-single"
          />
        </Form>
        {error && <Alert {...error} />}
      </>
    );
  }

  if (!configProcess) {
    return (
      <>
        <Text variant="h3">Configure Arena Process Inputs</Text>
        <Form<ConfigProcess> initialValues={initialValues} onSubmit={setConfigProcess}>
          <Field
            label="Quality Template"
            name="arenaTemplateId"
            options={_.map(arenaInputs?.templates, (t) => ({ label: t.name, value: t.guid }))}
            type="select-single"
          />
          <Field
            label="Owner (leave blank for default)"
            name="arenaOwnerId"
            options={_.map(arenaInputs?.owners, (t) => ({ label: t.fullName, value: t.guid }))}
            type="select-single"
          />
          <Field
            label="Type"
            name="arenaProcessType"
            options={_.chain(arenaInputs?.processAttributes)
              .find((a) => a.apiName === 'type')
              .get('possibleValues')
              .map((v) => ({ label: v, value: v }))
              .value()}
            type="select-single"
          />
        </Form>
        {error && <Alert {...error} />}
      </>
    );
  }

  if (!configStepAttributes) {
    if (!salesforceObjectMetadata) return <Loading />;
    return (
      <ConfigureAttributes
        onSubmit={setConfigStepAttributes}
        salesforceObjectMetadata={salesforceObjectMetadata}
        stepAttributes={arenaInputs?.stepAttributes || []}
        templateSteps={arenaTemplateSteps}
      />
    );
  }

  const template = _.find(arenaInputs?.templates, (t) => t.guid === configProcess?.arenaTemplateId);
  const owner = _.find(arenaInputs?.owners, (o) => o.guid === configProcess?.arenaOwnerId);

  return (
    <Form
      label="Review Configuation"
      labelSubmit="Save"
      onSubmit={() =>
        handleSubmit({
          ...configProcess,
          ...configSalesforce,
          ...configStepAttributes,
          type: powerq,
        })
      }
    >
      <Text>{`Arena Object: ${template?.name}`}</Text>
      <Text>{`Arena Template: ${template?.name}`}</Text>
      <Text>{`Arena Owner: ${owner?.fullName || '(default)'}`}</Text>
      <Text>{`Arena Type: ${configProcess.arenaProcessType || '(none)'}`}</Text>
      <Text variant="h3">Mapped Attributes</Text>
      {_.map(configStepAttributes?.arenaStepAttributes, (mappedAttribute, index) => {
        const templateStep = _.find(template?.steps, (s) => s.guid === mappedAttribute.stepId);
        const step = templateStep?.name || '';
        const attribute = _.find(
          templateStep?.attributes,
          (a) => a.guid === mappedAttribute.attributeId
        )?.name;
        const [, object, field] = _.split(mappedAttribute.variableAccessor, '.');
        return (
          <View
            key={index}
            sx={{
              borderColor: '$neutral',
              borderRadius: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              m: '$1',
              p: '$1',
            }}
          >
            <Text>{`Arena Step: ${step}`}</Text>
            <Text>{`Arena Attribute: ${attribute}`}</Text>
            <Text>{`Salesforce ${object}: ${field}`}</Text>
          </View>
        );
      })}
    </Form>
  );
}
