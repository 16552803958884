// import { FirestoreClient } from '@client';
import { Text, View } from '@client/elements';
// import * as eventTypes from '@shared/event/types';
import * as flowTypes from '@shared/flow/types';

export interface Props {
  flow: flowTypes.Flow;
}

export function FlowCompleted({ flow }: Props) {
  return (
    <View>
      <Text>Flow Completed</Text>
      <Text>{flow.title}</Text>
    </View>
  );
}
