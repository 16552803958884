import { SxProp } from '@client';
import { View } from '@client/elements';

export interface DividerProps {
  sx?: SxProp;
  variant?: 'horizontal' | 'vertical';
}

export function Divider({ sx, variant = 'horizontal' }: DividerProps) {
  switch (variant) {
    case 'horizontal':
      return (
        <View
          sx={{
            borderColor: '$neutral',
            borderStyle: 'solid',
            borderWidth: 0.5,
            width: '100%',
            ...sx,
          }}
        />
      );
    case 'vertical':
      return (
        <View
          sx={{
            borderColor: '$neutral',
            borderStyle: 'solid',
            borderWidth: 0.5,
            height: '100%',
            ...sx,
          }}
        />
      );
    default:
      return null;
  }
}
