import { View, ViewProps } from '@client/elements';

export interface HeaderProps extends ViewProps {
  center?: React.ReactElement;
  left?: React.ReactElement;
  right?: React.ReactElement;
}

export function Header({ center, left, right, ...props }: HeaderProps) {
  return (
    <View
      {...props}
      sx={{
        alignItems: 'center',
        bg: '$primaryLight',
        display: 'flex',
        flexDirection: 'row',
        height: 50,
        justifyContent: 'space-between',
        padding: '$2',
        width: '100%',
      }}
    >
      {left ?? <View />}
      {center ?? <View />}
      {right ?? <View />}
    </View>
  );
}
