import { Text as DripsyText, SxProp, Theme } from 'dripsy';

export interface TextProps {
  children: React.ReactNode;
  sx?: SxProp;
  variant?: keyof Theme['text'];
}

export function Text({ children, sx, ...props }: TextProps) {
  return (
    <DripsyText {...props} sx={{ ...sx }}>
      {children}
    </DripsyText>
  );
}
