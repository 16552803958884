import { ApiClient, AuthClient } from '@client';
import { Text, View } from '@client/elements';
import { Field, Form } from '@client/form';
import { useCallback } from 'react';

interface Props {
  companyId: string;
  onSubmit: (values: ConnectArenaValues) => Promise<void>;
}

export interface ConnectArenaValues {
  email: string;
  password: string;
  workspaceId: string;
}

export function ConnectArena({ companyId, onSubmit }: Props) {
  return (
    <View>
      <Text variant="h3">Connect Arena Workspace</Text>
      <Form<ConnectArenaValues>
        initialValues={{ email: '', password: '', workspaceId: '' }}
        onSubmit={onSubmit}
      >
        <Field label="Workspace ID" name="workspaceId" required />
        <Field label="Email" name="email" required />
        <Field label="Password" name="password" required type="password" />
      </Form>
    </View>
  );
}
