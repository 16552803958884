import { TextInput, TextInputProps } from '@client/elements';
import { useForm } from '@client/form';
import { useCallback, useMemo } from 'react';

interface Props extends TextInputProps {
  disabled?: boolean;
  error?: string;
  label: string;
  name: string;
}

export default function FieldPlainText({ error, name, type, ...props }: Props) {
  const { getValue, onChangeFieldValue } = useForm();

  const handleChange = useCallback(
    (e) => onChangeFieldValue(name, e.target.value), // Registers value to form state
    [name, onChangeFieldValue]
  );

  const defaultValue = useMemo(() => getValue(name), [getValue, name]);

  return (
    <TextInput
      {...props}
      defaultValue={defaultValue}
      errorMessage={error}
      multiline
      onChange={handleChange}
      type={type}
    />
  );
}
