import * as styleTypes from '@shared/style/types';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

interface Props {
  children: React.ReactNode;
  theme: styleTypes.Theme;
}

interface Value {
  getStyle: (args: {
    base?: styleTypes.SX;
    style?: styleTypes.Style;
    sx?: styleTypes.SX;
  }) => styleTypes.Style;
}

const StyleContext = createContext<Value | undefined>(undefined);

export function StyleProvider({ children, theme }: Props) {
  // const [dark, setDark] = useState(false);

  const applyThemeColor = useCallback(
    (v: 'primary' | 'secondary' | string | undefined, key: keyof styleTypes.Style) => {
      const style: styleTypes.Style = v
        ? v === 'primary'
          ? { [key]: theme.colors.primary }
          : v === 'secondary'
          ? { [key]: theme.colors.secondary }
          : { [key]: v }
        : {};
      return style;
    },
    [theme]
  );

  const applyThemeRadius = useCallback(
    (v: number | undefined, key: keyof styleTypes.Style) => {
      const style: styleTypes.Style = v
        ? typeof v === 'number'
          ? { [key]: theme.radius * v }
          : { [key]: v }
        : {};
      return style;
    },
    [theme]
  );

  const applyThemeSpacing = useCallback(
    (v: number | string | undefined, key: keyof styleTypes.Style) => {
      const style: styleTypes.Style = v
        ? typeof v === 'number'
          ? { [key]: theme.spacing * v }
          : { [key]: v }
        : {};
      return style;
    },
    [theme]
  );

  const convertSxToStyle = useCallback(
    (sx?: styleTypes.SX) => {
      const style: styleTypes.Style = {
        ...applyThemeColor(sx?.bg, 'backgroundColor'),
        ...applyThemeRadius(sx?.borderRadius, 'borderRadius'),
        ...applyThemeColor(sx?.color, 'color'),
        ...(sx?.height && { height: sx.height }),
        ...applyThemeSpacing(sx?.m, 'margin'),
        ...applyThemeSpacing(sx?.p, 'padding'),
        ...(sx?.width && { width: sx.width }),
      };
      return style;
    },
    [applyThemeColor, applyThemeRadius, applyThemeSpacing]
  );

  const getStyle = useCallback(
    ({
      base,
      style,
      sx,
    }: {
      base?: styleTypes.SX;
      style?: styleTypes.Style;
      sx?: styleTypes.SX;
    }) => {
      const _style: styleTypes.Style = {
        ...style,
        ...convertSxToStyle(base),
        ...convertSxToStyle(sx),
      };
      return _style;
    },
    [convertSxToStyle]
  );

  const value = useMemo(() => ({ getStyle }), [getStyle]);

  return <StyleContext.Provider value={value}>{children}</StyleContext.Provider>;
}

/**
 * Takes both style and sx props as inputs, returns a themed style prop
 * @returns
 */
export const useStyle = () => {
  const context = useContext(StyleContext);
  if (context === undefined) {
    throw new Error('useForm must be within FormProvider');
  }
  return context;
};
