import { View, ViewProps } from '@client/elements';
import * as svgs from '@client/svgs';
import { AirtableIcon } from '@client/svgs/AirtableIcon';
import { EasypostIcon } from '@client/svgs/EasypostIcon';
import { FreshworksIcon } from '@client/svgs/FreshworksIcon';
import { GoogleIcon } from '@client/svgs/GoogleIcon';
import { KustomerIcon } from '@client/svgs/KustomerIcon';
import { Logo } from '@client/svgs/Logo';
import { SalesforceIcon } from '@client/svgs/SalesforceIcon';
import { ShipstationIcon } from '@client/svgs/ShipstationIcon';
import { ZendeskIcon } from '@client/svgs/ZendeskIcon';
import { Ionicons } from '@expo/vector-icons';

export interface IconProps {
  color?: string;
  height?: number;
  name:
    | 'airtable-icon'
    | 'airtable-icon-small'
    | 'arena-icon'
    | 'arena-logo'
    | 'easypost-icon'
    | 'freshworks-icon'
    | 'google-icon'
    | 'kustomer-icon'
    | 'logo'
    | 'salesforce-icon'
    | 'shipstation-icon'
    | 'zendesk-icon'
    | keyof typeof Ionicons.glyphMap;
  size?: number;
  sx?: ViewProps['sx'];
  width?: number;
}

function renderIcon({ color, height, name, size = 24, width, ...props }: IconProps) {
  const _props = { height: height || size, width: width || size, ...props };
  switch (name) {
    case 'airtable-icon':
      return <AirtableIcon {..._props} />;
    case 'airtable-icon-small':
      return <AirtableIcon {..._props} small />;
    case 'arena-icon':
      return <svgs.ArenaIcon {..._props} />;
    case 'arena-logo':
      return <svgs.ArenaLogo {..._props} />;
    case 'easypost-icon':
      return <EasypostIcon {..._props} />;
    case 'freshworks-icon':
      return <FreshworksIcon {..._props} />;
    case 'google-icon':
      return <GoogleIcon {..._props} />;
    case 'kustomer-icon':
      return <KustomerIcon {..._props} />;
    case 'logo':
      return <Logo {..._props} />;
    case 'salesforce-icon':
      return <SalesforceIcon {..._props} />;
    case 'shipstation-icon':
      return <ShipstationIcon {..._props} />;
    case 'zendesk-icon':
      return <ZendeskIcon {..._props} />;
    default:
      return <Ionicons color={color} name={name} size={size} />;
  }
}

export function Icon(props: IconProps) {
  return <View sx={props.sx}>{renderIcon(props)}</View>;
}
