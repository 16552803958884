import { ApiClient } from '@client';
import * as salesforceTypes from '@shared/salesforce/types';

const API_VERSION = '56.0';

async function fetchCredentials() {
  if (Sfdc?.canvas.oauth.loggedin()) {
    return Sfdc.canvas.oauth.client();
  } else {
    const { data: clients } = await ApiClient.currentUser
      .integration('salesforce')
      .clientsWithData.get();
    const client = clients[0];
    if (client) {
      Sfdc.canvas.oauth.token(client.data.token.access_token);
      return Sfdc.canvas.oauth.client();
    }
  }
  throw new Error('Error authenticating local salesforce');
}

export const SalesforceClient = {
  ajax: async (url: string): Promise<any> => {
    const credentials = await fetchCredentials();
    return new Promise((resolve, reject) => {
      Sfdc.canvas.client.ajax(url, {
        client: credentials,
        success: (msg) => {
          if (msg.status === 200) {
            resolve(msg.payload);
          } else {
            reject(new Error('Error authenticating Salesforce client'));
          }
        },
      });
    });
  },
  context: async (): Promise<salesforceTypes.CanvasContext> => {
    const credentials = await fetchCredentials();
    return new Promise((resolve, reject) => {
      Sfdc.canvas.client.ctx((msg) => {
        if (msg.status === 200) {
          resolve(msg.payload);
        } else {
          reject(new Error('Error authenticating Salesforce client'));
        }
      }, credentials);
    });
  },
  resize: async (payload: { height?: string }) => {
    const credentials = await fetchCredentials();
    Sfdc.canvas.client.resize(credentials, payload);
  },
  // fetchRecord: async (object:string): Promise<any> => {
  //   const credentials = await fetchCredentials();
  //   return new Promise((resolve, reject) => {
  //     Sfdc.canvas.client.ctx((ctx) => {
  //       if (ctx.status === 200) {
  //         Sfdc.canvas.client.ajax((msg) => {
  //           if (msg.status === 200) {
  //             resolve(msg.payload);
  //           } else {
  //             reject(new Error('Error fetching record'));
  //           }
  //         }, credentials);
  //       });
  //     }, credentials);
  //   });
  // },
};
