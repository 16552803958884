import { theme, SxProp } from '@client';
import { Divider, IconButton, Pressable, Text, View } from '@client/elements';
import { TextInput } from 'dripsy';
import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';

export interface SelectSingleProps {
  disabled?: boolean;
  error?: string;
  label?: string;
  onChange: (value: null | number | string) => void;
  options: { label: string; value: number | string }[];
  placeholder?: string;
  sx?: SxProp;
  value: null | number | string;
}

export function SelectSingle({
  label,
  onChange,
  options,
  placeholder,
  sx,
  value,
  ...props
}: SelectSingleProps) {
  const inputRef = useRef<HTMLDivElement>();
  const wrapperRef = useRef<HTMLDivElement>();

  const [filter, setFilter] = useState({ options, value: '' });
  const [inputHeight, setInputHeight] = useState<number>();
  const [open, setOpen] = useState(false);

  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);

  const handleFocus = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSelect = useCallback(
    (_value: number | string) => {
      onChange(_value);
      setOpen(false);
    },
    [onChange]
  );

  const handleFilterText = useCallback(
    (e) => {
      const _value = _.toLower(e.target.value);
      setFilter({
        options: _value
          ? _.filter(options, (o) => _.toLower(o.label).indexOf(_value) >= 0)
          : options,
        value: _value,
      });
    },
    [options]
  );

  const inputValue = useMemo(() => {
    return value ? _.find(options, (o) => o.value === value)?.label || '' : filter.value;
  }, [filter, options, value]);

  const zIndex = useMemo(() => (open ? 9 : 2), [open]);

  return (
    <View
      innerRef={wrapperRef}
      sx={{
        display: 'flex',
        m: '$1',
        position: 'relative',
        zIndex,
        ...sx,
      }}
    >
      {label && <Text variant="label">{label}</Text>}
      <View
        sx={{
          alignItems: 'center',
          borderColor: '$neutral',
          borderRadius: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          display: 'flex',
          flexDirection: 'row',
          p: '$1',
        }}
      >
        <TextInput
          {...({} as any)} // Needed to get around this issue for now: https://github.com/nandorojo/dripsy/issues/260
          multiline
          onBlur={handleBlur}
          onChange={handleFilterText}
          onContentSizeChange={(event) => {
            setInputHeight(event.nativeEvent.contentSize.height);
          }}
          onFocus={handleFocus}
          placeholder={placeholder}
          ref={inputRef}
          sx={{
            borderStyle: 'none',
            flexGrow: 1,
            outlineWidth: 0,
            p: '$1',
            ...(inputHeight && { height: inputHeight }),
          }}
          value={inputValue}
        />
        {value && (
          <IconButton
            color={theme.colors.$neutral}
            name="close-outline"
            onPress={() => onChange(null)}
            sx={{ mr: '$1' }}
          />
        )}
        <Divider variant="vertical" />
        <IconButton
          name="chevron-down-outline"
          onPress={() => setOpen((s) => !s)}
          sx={{ ml: '$1' }}
        />
      </View>
      <View sx={{ position: 'relative', zIndex }}>
        {open && (
          <View
            sx={{
              backgroundColor: '$light',
              borderColor: '$neutral',
              borderRadius: 5,
              borderStyle: 'solid',
              borderWidth: 1,
              display: 'flex',
              left: 0,
              maxHeight: 300,
              overflowY: 'auto',
              p: '$1',
              position: 'absolute',
              width: inputRef.current?.getBoundingClientRect().width,
              zIndex,
            }}
          >
            {_.map(filter.options, (option, index) => (
              <Pressable key={index} onPress={() => handleSelect(option.value)} sx={{ p: '$1' }}>
                <Text>{option.label}</Text>
              </Pressable>
            ))}
          </View>
        )}
      </View>
    </View>
  );
}
