export interface Props {
  width: number;
  height: number;
  style?: React.CSSProperties;
}
export function EasypostIcon(props: Props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="760.003px"
      height="190.025px"
      viewBox="0 0 760.003 190.025"
      enableBackground="new 0 0 760.003 190.025"
    >
      <g>
        <g>
          <path
            fill="#2C70B4"
            d="M81.744,56.371c-3.843-5.156-8.614-9.206-14.31-12.16c-5.698-2.955-12.116-4.433-19.252-4.433
			c-6.865,0-13.213,1.375-19.046,4.122c-5.835,2.75-10.915,6.631-15.238,11.645C9.574,60.562,6.176,66.541,3.706,73.476
			C1.234,80.415,0,88.004,0,96.251c0,8.521,1.199,16.249,3.602,23.184c2.4,6.939,5.798,12.85,10.193,17.725
			c4.391,4.88,9.709,8.655,15.957,11.337c6.246,2.681,13.283,4.021,21.105,4.021c7.276,0,14.447-1.517,21.519-4.537
			c7.069-3.02,12.798-7.347,17.193-12.983l-20.178-16.898c-2.06,2.338-4.668,4.189-7.825,5.562
			c-3.159,1.377-6.729,2.063-10.708,2.063c-2.606,0-5.181-0.38-7.721-1.136c-2.542-0.757-4.838-1.921-6.897-3.503
			c-2.06-1.577-3.778-3.674-5.146-6.289c-1.375-2.607-2.199-5.771-2.473-9.481h64.655c0.135-1.095,0.239-2.366,0.309-3.812
			c0.069-1.439,0.104-3.193,0.104-5.253c0-7.695-1.03-14.976-3.09-21.848C88.539,67.534,85.587,61.522,81.744,56.371z
			 M29.033,83.885c0.545-5.497,2.331-10.098,5.353-13.809c3.018-3.711,7.137-5.566,12.355-5.566c3.157,0,5.9,0.552,8.236,1.651
			c2.333,1.099,4.289,2.574,5.868,4.43c1.577,1.855,2.778,3.915,3.604,6.183c0.823,2.269,1.371,4.637,1.647,7.111H29.033z
			 M176.583,49.568c-3.875-3.229-8.498-5.668-13.876-7.315c-5.381-1.651-11.365-2.475-17.954-2.475
			c-4.395,0-8.375,0.241-11.942,0.722c-3.572,0.482-6.969,1.169-10.193,2.062c-3.226,0.896-6.315,2.029-9.265,3.402
			c-2.955,1.373-6.007,2.955-9.164,4.736l10.915,19.788c2.47-1.373,4.803-2.507,7-3.4c2.194-0.893,4.427-1.614,6.693-2.167
			c2.264-0.547,4.664-0.96,7.206-1.233c2.538-0.276,5.455-0.416,8.751-0.416c5.213,0,9.091,1.341,11.633,4.02
			c2.54,2.681,3.811,5.947,3.811,9.79v5.36c-2.331-1.1-5.137-2.092-8.417-2.99c-3.287-0.893-7.392-1.338-12.313-1.338
			c-6.158,0-11.873,0.756-17.142,2.266c-5.272,1.514-9.887,3.746-13.855,6.698c-3.968,2.958-7.081,6.698-9.337,11.232
			c-2.259,4.537-3.386,9.755-3.386,15.665c0,6.591,1.164,12.297,3.5,17.103c2.333,4.814,5.388,8.795,9.164,11.958
			c3.774,3.159,8.064,5.529,12.87,7.111c4.803,1.577,9.746,2.37,14.825,2.37c5.077,0,9.746-0.9,14.002-2.682
			c4.253-1.785,7.686-4.049,10.295-6.8l-0.204,7.213h28.621V77.701c0-6.044-1.072-11.438-3.215-16.179
			S180.451,52.797,176.583,49.568z M160.196,118.507c-1.493,2.612-3.971,4.917-7.433,6.907c-3.46,1.99-7.496,2.987-12.109,2.987
			c-5.973,0-10.182-1.442-12.622-4.328c-2.447-2.885-3.664-6.253-3.664-10.098c0-4.397,1.389-7.761,4.172-10.099
			c2.778-2.338,6.816-3.504,12.114-3.504c5.019,0,8.922,0.378,11.703,1.131c2.781,0.759,5.392,1.891,7.839,3.403V118.507z
			 M274.682,95.426c-3.295-2.607-7.141-4.808-11.531-6.596c-4.395-1.784-8.992-3.502-13.795-5.154
			c-4.808-1.507-8.686-2.811-11.636-3.915c-2.953-1.099-5.218-2.092-6.793-2.987c-1.579-0.891-2.646-1.786-3.193-2.681
			c-0.55-0.891-0.823-1.886-0.823-2.987c0-2.333,0.995-4.087,2.987-5.256c1.987-1.164,5.868-1.751,11.633-1.751
			c4.53,0,9.577,0.722,15.133,2.164c5.562,1.442,11.017,3.606,16.37,6.492l9.062-22.465c-5.766-3.159-12.149-5.699-19.151-7.624
			c-7-1.925-13.934-2.888-20.797-2.888c-6.315,0-12.116,0.62-17.399,1.858c-5.285,1.234-9.85,3.159-13.69,5.768
			c-3.846,2.611-6.833,5.944-8.959,9.996c-2.129,4.054-3.191,8.899-3.191,14.531c0,5.77,0.995,10.581,2.987,14.426
			c1.988,3.85,4.838,7.111,8.544,9.79c3.706,2.679,8.201,5.016,13.486,7.006c5.284,1.995,11.151,3.952,17.606,5.872
			c6.04,1.791,10.193,3.438,12.457,4.95c2.266,1.512,3.398,3.502,3.398,5.975c0,2.75-0.998,4.81-2.985,6.183
			c-1.992,1.378-5.8,2.06-11.429,2.06c-7,0-13.556-0.854-19.663-2.574c-6.109-1.716-12.046-4.151-17.812-7.315l-9.472,21.64
			c5.766,3.845,12.594,6.907,20.489,9.171c7.893,2.268,16.163,3.404,24.812,3.404c6.04,0,11.738-0.589,17.091-1.754
			c5.354-1.168,10.02-3.094,14.002-5.77c3.98-2.682,7.137-6.184,9.472-10.512c2.333-4.332,3.5-9.583,3.5-15.767
			c0-5.357-0.962-9.927-2.883-13.707C280.582,101.233,277.976,98.037,274.682,95.426z M349.424,94.187
			c-1.512,4.263-2.883,8.59-4.119,12.988c-1.234,4.397-2.196,7.97-2.883,10.716c-0.687-2.611-1.646-6.184-2.883-10.716
			c-1.234-4.536-2.607-8.864-4.117-12.988l-17.708-52.14h-31.299l42.005,108.202l-14.412,38.125h28.003l55.596-146.327h-30.476
			L349.424,94.187z M482.03,53.483c-3.846-4.741-8.308-8.206-13.383-10.407c-5.084-2.196-10.502-3.298-16.272-3.298
			c-5.214,0-9.644,0.926-13.275,2.783c-3.642,1.854-6.833,3.95-9.579,6.286l0.209-6.8h-28.625v147.979l28.625-0.482v-45.477
			c2.598,2.338,5.845,4.327,9.746,5.975c3.9,1.65,8.247,2.477,13.034,2.477c6.703,0,12.659-1.341,17.854-4.021
			c5.205-2.682,9.584-6.457,13.137-11.337c3.562-4.875,6.263-10.683,8.108-17.413c1.847-6.73,2.774-14.152,2.774-22.261
			c0-10.029-1.1-18.685-3.294-25.967C488.891,64.235,485.871,58.224,482.03,53.483z M448.878,125.725
			c-8.238,0-14.621-3.094-19.148-9.277V75.642c2.06-2.748,4.389-4.947,7-6.596c2.603-1.649,6.108-2.473,10.502-2.473
			c5.626,0,10.121,2.473,13.484,7.417c3.362,4.947,5.047,12.983,5.047,24.111C465.763,116.518,460.131,125.725,448.878,125.725z
			 M594.043,73.476c-2.473-6.935-5.868-12.914-10.191-17.931c-4.322-5.014-9.509-8.895-15.549-11.645
			c-6.039-2.746-12.561-4.122-19.561-4.122s-13.484,1.375-19.459,4.122c-5.97,2.75-11.118,6.631-15.441,11.645
			c-4.323,5.017-7.724,10.996-10.191,17.931c-2.473,6.939-3.711,14.528-3.711,22.775c0,8.243,1.238,15.837,3.711,22.771
			c2.468,6.938,5.868,12.881,10.191,17.83c4.323,4.945,9.472,8.795,15.441,11.541c5.975,2.746,12.459,4.124,19.459,4.124
			s13.521-1.378,19.561-4.124c6.04-2.746,11.227-6.596,15.549-11.541c4.323-4.949,7.719-10.892,10.191-17.83
			c2.473-6.935,3.706-14.528,3.706-22.771C597.749,88.004,596.516,80.415,594.043,73.476z M567.877,107.068
			c-0.831,3.507-2.078,6.596-3.739,9.277c-1.67,2.677-3.785,4.811-6.346,6.388c-2.569,1.581-5.585,2.37-9.05,2.37
			c-6.935,0-12.032-2.714-15.293-8.141c-3.257-5.428-4.885-12.33-4.885-20.712s1.628-15.319,4.885-20.818
			c3.261-5.492,8.358-8.243,15.293-8.243c6.93,0,12.065,2.783,15.396,8.345c3.321,5.566,4.991,12.469,4.991,20.716
			C569.129,99.96,568.711,103.566,567.877,107.068z M674.963,95.426c-3.293-2.607-7.139-4.808-11.531-6.596
			c-4.393-1.784-8.989-3.502-13.795-5.154c-4.806-1.507-8.684-2.811-11.634-3.915c-2.95-1.099-5.218-2.092-6.796-2.987
			c-1.581-0.891-2.644-1.786-3.191-2.681c-0.552-0.891-0.82-1.886-0.82-2.987c0-2.333,0.992-4.087,2.982-5.256
			c1.99-1.164,5.867-1.751,11.634-1.751c4.532,0,9.578,0.722,15.136,2.164c5.557,1.442,11.017,3.606,16.369,6.492l9.06-22.465
			c-5.766-3.159-12.148-5.699-19.148-7.624c-7.004-1.925-13.935-2.888-20.8-2.888c-6.312,0-12.115,0.62-17.395,1.858
			c-5.288,1.234-9.852,3.159-13.693,5.768c-3.85,2.611-6.832,5.944-8.961,9.996c-2.13,4.054-3.191,8.899-3.191,14.531
			c0,5.77,0.997,10.581,2.986,14.426c1.99,3.85,4.844,7.111,8.545,9.79c3.711,2.679,8.201,5.016,13.489,7.006
			c5.278,1.995,11.151,3.952,17.604,5.872c6.039,1.791,10.19,3.438,12.459,4.95c2.264,1.512,3.4,3.502,3.4,5.975
			c0,2.75-1.002,4.81-2.987,6.183c-1.995,1.378-5.803,2.06-11.43,2.06c-7,0-13.559-0.854-19.668-2.574
			c-6.104-1.716-12.041-4.151-17.808-7.315l-9.472,21.64c5.766,3.845,12.594,6.907,20.488,9.171
			c7.891,2.268,16.161,3.404,24.812,3.404c6.039,0,11.735-0.589,17.093-1.754c5.354-1.168,10.015-3.094,13.999-5.77
			c3.98-2.682,7.135-6.184,9.473-10.512c2.328-4.332,3.497-9.583,3.497-15.767c0-5.357-0.96-9.927-2.88-13.707
			C680.863,101.233,678.257,98.037,674.963,95.426z M751.25,123.457c-2.264,1.233-4.703,1.855-7.311,1.855
			c-3.433,0-6.039-0.858-7.82-2.579c-1.791-1.717-2.682-5.182-2.682-10.409V68.837h25.948v-26.79h-25.948V0l-28.62,14.222v27.825
			h-18.118v26.79h18.118v51.113c0,4.81,0.687,9.207,2.073,13.192c1.383,3.984,3.316,7.417,5.808,10.302
			c2.491,2.886,5.529,5.121,9.124,6.703c3.6,1.577,7.607,2.37,12.033,2.37c4.388,0,8.572-0.622,12.557-1.855
			c3.979-1.238,7.139-2.815,9.472-4.74l4.119-28.857C756.432,120.089,753.514,122.218,751.25,123.457z"
          />
        </g>
      </g>
    </svg>
  );
}
