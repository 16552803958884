export interface Props {
  height: number;
  width: number;
  style?: React.CSSProperties;
}

export function Logo(props: Props) {
  return (
    <svg viewBox="0 0 936 936" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_101_2)">
        <path
          d="M597.5 886.4C597.5 868.7 602.3 851.7 611.2 836.9L321.5 547.3C305.8 557.1 288.1 562.1 270.2 562.1C245.3 562.1 220.5 552.7 201.6 533.7C183.3 515.4 173.2 491 173.2 465.1C173.2 439.2 183.3 414.8 201.6 396.5C234.2 363.9 284.3 359.6 321.8 383.1L662.5 42.5C603.2 15.3 537.3 0 467.8 0C209.4 0 0 209.4 0 467.8C0 726.2 209.4 935.6 467.8 935.6C514.5 935.6 559.6 928.7 602.1 915.9C599.1 906.4 597.5 896.6 597.5 886.4Z"
          fill="url(#paint0_linear_101_2)"
        />
        <path
          d="M236 430.8C226.8 440 221.8 452.1 221.8 465.1C221.8 478.1 226.8 490.2 236 499.4C254.9 518.3 285.7 518.3 304.6 499.4C323.5 480.5 323.5 449.7 304.6 430.8C295.1 421.4 282.7 416.6 270.3 416.6C257.8 416.7 245.4 421.4 236 430.8Z"
          fill="url(#paint1_linear_101_2)"
        />
        <path
          d="M620.1 422.2C602.4 439.9 602.4 468.7 620.1 486.4C637.8 504.1 666.6 504.1 684.3 486.4C702 468.7 702 439.9 684.3 422.2C675.5 413.4 663.8 408.9 652.2 408.9C640.5 408.9 628.9 413.4 620.1 422.2Z"
          fill="url(#paint2_linear_101_2)"
        />
        <path
          d="M733.2 407.6C753.9 443.5 749.2 490.1 718.6 520.7C700.3 539 676.2 548.2 652.2 548.2C628.1 548.2 604.1 539 585.8 520.7C549.2 484.1 549.2 424.5 585.8 387.9C616.5 357.3 663 352.6 698.9 373.3L859.7 212.5C820.8 152.9 768.7 102.7 707.5 66.1L354.9 418.6C370.8 447.7 370.8 482.9 354.8 511.9L645.8 802.9C682.9 781.2 731.4 785.9 763.2 817.8C765.5 820.1 767.3 822.8 769.3 825.3C870.9 739.5 935.6 611.2 935.6 467.8C935.6 391.6 917.2 319.6 884.9 256L733.2 407.6Z"
          fill="url(#paint3_linear_101_2)"
        />
        <path
          d="M694.5 837.9C682.1 837.9 669.7 842.6 660.2 852.1C651 861.3 646 873.4 646 886.4C646 899.4 651 911.5 660.2 920.7C679.1 939.6 709.9 939.6 728.8 920.7C747.7 901.8 747.7 871 728.8 852.1C719.4 842.7 706.9 837.9 694.5 837.9Z"
          fill="url(#paint4_linear_101_2)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_101_2"
          x1="180.667"
          y1="945.655"
          x2="544.132"
          y2="41.9041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006D95" />
          <stop offset="0.5116" stopColor="#1776A7" />
          <stop offset="1" stopColor="#3B8EB3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_101_2"
          x1="91.4356"
          y1="909.769"
          x2="454.901"
          y2="6.01769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006D95" />
          <stop offset="0.5116" stopColor="#1776A7" />
          <stop offset="1" stopColor="#3B8EB3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_101_2"
          x1="416.427"
          y1="1040.47"
          x2="779.892"
          y2="136.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006D95" />
          <stop offset="0.5116" stopColor="#1776A7" />
          <stop offset="1" stopColor="#3B8EB3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_101_2"
          x1="422.313"
          y1="1042.84"
          x2="785.778"
          y2="139.088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006D95" />
          <stop offset="0.5116" stopColor="#1776A7" />
          <stop offset="1" stopColor="#3B8EB3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_101_2"
          x1="602.461"
          y1="1115.29"
          x2="965.927"
          y2="211.539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006D95" />
          <stop offset="0.5116" stopColor="#1776A7" />
          <stop offset="1" stopColor="#3B8EB3" />
        </linearGradient>
        <clipPath id="clip0_101_2">
          <rect width="935.5" height="935.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
