import { ErrorClient, handleError, startOAuthSession, useMountedState } from '@client';
import {
  AuthButtonGoogle,
  AuthButtonKustomer,
  AuthButtonSalesforce,
  AuthButtonZendesk,
} from '@client/components';
import { Alert, Icon, Loading, Text, View } from '@client/elements';
import * as powerqTypes from '@shared/powerq/types';
import { useCallback, useState } from 'react';

interface Props {
  onSuccess?: () => void;
  options: {
    powerq?: powerqTypes.Type;
    email?: boolean;
    google?: boolean;
    kustomer?: boolean;
    salesforce?: boolean;
    salesforceLabel?: string;
    zendesk?: boolean;
  };
}

function Buttons({
  onAuth,
  options,
}: {
  onAuth: (url: string) => Promise<void>;
  options: Props['options'];
}) {
  return (
    <>
      {options.google && <AuthButtonGoogle onAuth={onAuth} />}
      {options.kustomer && <AuthButtonKustomer onAuth={onAuth} />}
      {options.salesforce && (
        <AuthButtonSalesforce label={options.salesforceLabel} onAuth={onAuth} />
      )}
      {options.zendesk && <AuthButtonZendesk onAuth={onAuth} />}
    </>
  );
}

export function AuthForm({ onSuccess, options }: Props) {
  const mounted = useMountedState();
  const [error, setError] = useState<ErrorClient>();
  const [loading, setLoading] = useState(false);

  const onAuth = useCallback(
    async (url) => {
      try {
        setLoading(true);
        await startOAuthSession(url);
      } catch (err) {
        setError(handleError(err));
      }
      if (mounted()) {
        setLoading(false);
        onSuccess?.();
      }
    },
    [mounted, onSuccess]
  );

  if (loading) return <Loading />;

  switch (options.powerq) {
    case 'salesforce-arena-quality':
      return (
        <View
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '$3',
          }}
        >
          <View sx={{ display: 'flex', flexDirection: 'row' }}>
            <Icon height={40} name="arena-icon" sx={{ mb: '$3', mr: '$3' }} width={40} />
            <View
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 40,
              }}
            >
              <Text variant="h3">Arena Quality</Text>
              <Text variant="caption">Powered by FlowEQ</Text>
            </View>
          </View>
          <Text sx={{ mb: '$2' }} variant="h3">
            Let's get started
          </Text>
          <Buttons
            onAuth={onAuth}
            options={{ salesforce: true, salesforceLabel: 'Authorize Salesforce' }}
          />
        </View>
      );
    default:
      return (
        <View
          sx={{
            borderColor: '$dark',
            borderRadius: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            m: '$2',
            p: '$2',
          }}
        >
          <Text sx={{ m: '$1' }} variant="h3">
            Sign In Below
          </Text>
          <Buttons onAuth={onAuth} options={options} />
          {/* <Text variant="subtitle2">
            {'By signing in, you agree to our '}
            <LinkHTML href="https://www.brightreps.com/terms/">Terms of Service</LinkHTML>
          </Text> */}
          {error && <Alert message={error.message} />}
        </View>
      );
  }
}
