import { ApiClient, AuthClient } from '@client';
import { AuthForm } from '@client/components';
import { CompanyProvider, UserProvider, VariableProvider, useCompany } from '@client/context';
import { Icon, IconButton, Menu, MenuProps, Text, View, Wizard } from '@client/elements';
import { storage } from '@shared';
import * as companyTypes from '@shared/company/types';
import * as environmentTypes from '@shared/environment/types';
import * as userTypes from '@shared/user/types';
import { useMemo } from 'react';

interface Props {
  onUpdateConfig?: () => void;
}

export function Header({ onUpdateConfig }: Props) {
  const options = useMemo(() => {
    const _options: MenuProps['options'] = [
      {
        iconName: 'exit',
        label: 'Sign Out',
        onPress: AuthClient.signOut,
      },
    ];
    if (onUpdateConfig) {
      _options.push({
        iconName: 'settings-outline',
        label: 'Update Config',
        onPress: onUpdateConfig,
      });
    }
    return _options;
  }, [onUpdateConfig]);

  return (
    <View
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 1, // Gives the header a higher z-index for menu dropdowns
      }}
    >
      <View sx={{ display: 'flex', flexDirection: 'row' }}>
        <Icon height={40} name="arena-icon" sx={{ mb: '$3', mr: '$3' }} width={40} />
        <View
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 40,
          }}
        >
          <Text variant="h3">Arena Quality</Text>
          <Text variant="caption">Powered by FlowEQ</Text>
        </View>
      </View>
      <Menu options={options} />
    </View>
  );
}
