import { Text, View, SxProp } from 'dripsy';

export interface AlertProps {
  // children: React.ReactNode;
  details?: object;
  message: string;
  sx?: SxProp;
  variant?: 'error' | 'success' | 'warning';
}

export function Alert({ message, variant, ...props }: AlertProps) {
  switch (variant) {
    case 'success': {
      return (
        <View
          {...props}
          sx={{
            bg: '$success',
            borderRadius: 5,
            m: '$2',
            p: '$2',
            ...props.sx,
          }}
        >
          <Text sx={{ textAlign: 'center', color: '$light' }} variant="h3">
            {message}
          </Text>
        </View>
      );
    }
    case 'warning': {
      return (
        <View
          {...props}
          sx={{
            bg: '$secondary',
            borderRadius: 5,
            m: '$2',
            p: '$2',
            ...props.sx,
          }}
        >
          <Text sx={{ textAlign: 'center', color: '$dark' }} variant="h3">
            {message}
          </Text>
        </View>
      );
    }
    case 'error':
    default: {
      return (
        <View
          {...props}
          sx={{
            bg: '$error',
            borderRadius: 5,
            m: '$2',
            p: '$2',
            ...props.sx,
          }}
        >
          <Text sx={{ textAlign: 'center', color: '$light' }} variant="h3">
            {message}
          </Text>
        </View>
      );
    }
  }
}
