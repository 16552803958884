export interface Props {
  height: number;
  style?: React.CSSProperties;
  width: number;
}

export function ZendeskIcon(props: Props) {
  return (
    <svg viewBox="0 0 364 261" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#03363D">
        <polygon points="174.22 41.22 174.22 154.08 80.74 154.08" />
        <path d="M174.22.71C174.22 26.5237892 153.293789 47.45 127.48 47.45 101.666211 47.45 80.74 26.5237892 80.74.71L174.22.71 174.22.71zM189.62 154.08C189.62 128.266211 210.546211 107.34 236.36 107.34 262.173789 107.34 283.1 128.266211 283.1 154.08L189.62 154.08z" />
        <polygon points="189.62 113.57 189.62 .71 283.11 .71" />
        <path d="M241.91,250.63 C246.717333,250.737482 251.372243,248.940219 254.86,245.63 L261.28,252.56 C257.04,256.92 251.16,260.16 242.02,260.16 C226.35,260.16 216.23,249.77 216.23,235.7 C216.101676,229.189077 218.624692,222.905526 223.220066,218.291295 C227.815439,213.677065 234.088606,211.128339 240.6,211.23 C256.16,211.23 264.98,223.07 264.21,239.49 L227.43,239.49 C228.73,246.31 233.52,250.66 241.9,250.66 L241.91,250.63 Z M253.11,231.66 C252.11,225.29 248.32,220.6 240.7,220.6 C233.63,220.6 228.7,224.6 227.43,231.66 L253.11,231.66 Z" />
        <polygon points=".4 250.12 28.71 221.36 1.07 221.36 1.07 212.34 41.83 212.34 41.83 221.55 13.52 250.3 42.21 250.3 42.21 259.33 .4 259.33" />
        <path d="M74 250.63C78.8073327 250.737482 83.4622431 248.940219 86.95 245.63L93.37 252.56C89.13 256.92 83.25 260.16 74.11 260.16 58.44 260.16 48.32 249.77 48.32 235.7 48.1916761 229.189077 50.714692 222.905526 55.3100656 218.291295 59.9054393 213.677065 66.1786065 211.128339 72.69 211.23 88.25 211.23 97.07 223.07 96.3 239.49L59.52 239.49C60.82 246.31 65.61 250.66 73.99 250.66L74 250.63zM85.2 231.66C84.2 225.29 80.41 220.6 72.79 220.6 65.72 220.6 60.79 224.6 59.52 231.66L85.2 231.66 85.2 231.66zM157.42 235.69C157.42 220.69 168.65 211.25 181.01 211.25 187.018266 211.170039 192.75443 213.750764 196.68 218.3L196.68 190.58 206.68 190.58 206.68 259.19 196.68 259.19 196.68 252.7C192.826956 257.439462 187.027862 260.169746 180.92 260.12 168.92 260.12 157.42 250.63 157.42 235.69M197.24 235.6C197.020464 227.414281 190.244472 220.935197 182.057133 221.082412 173.869793 221.229627 167.331104 227.948119 167.405987 236.13644 167.48087 244.32476 174.141338 250.922545 182.33 250.92 190.92 250.92 197.24 244.06 197.24 235.6M270.72 249.22L279.78 244.52C282.22108 248.871759 286.872116 251.512992 291.86 251.38 297.52 251.38 300.45 248.47 300.45 245.18 300.45 241.42 294.98 240.57 289.03 239.35 281.03 237.66 272.7 235.02 272.7 225.35 272.7 217.92 279.77 211.06 290.91 211.16 299.68 211.16 306.2 214.64 309.91 220.27L301.51 224.88C299.150727 221.385583 295.153196 219.358453 290.94 219.52 285.56 219.52 282.82 222.15 282.82 225.16 282.82 228.54 287.16 229.48 293.96 230.99 301.7 232.68 310.47 235.22 310.47 244.99 310.47 251.47 304.81 260.21 291.41 260.12 281.6 260.12 274.71 256.17 270.74 249.22" />
        <polygon points="337.62 238.32 329.69 246.97 329.69 259.18 319.69 259.18 319.69 190.57 329.69 190.57 329.69 235.5 350.92 212.19 363.1 212.19 344.69 232.3 363.57 259.18 352.25 259.18" />
        <path d="M127.19,211.24 C115.3,211.24 105.34,218.95 105.34,231.73 L105.34,259.18 L115.53,259.18 L115.53,233 C115.53,225.29 119.96,220.68 127.53,220.68 C135.1,220.68 138.86,225.29 138.86,233 L138.86,259.18 L149,259.18 L149,231.73 C149,218.95 139,211.24 127.15,211.24" />
      </g>
    </svg>
  );
}
