import * as errorTypes from '@shared/error/types';
import { Liquid } from 'liquidjs';
import localforage from 'localforage';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import xmljs from 'xml-js';

export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';

export const Base64 = {
  decode: (str: string) => Buffer.from(str, 'base64').toString('utf-8'),
  encode: (str: string) => Buffer.from(str, 'utf-8').toString('base64'),
};

export const convert = {
  date: {
    addYears: ({
      date,
      years,
      format,
    }: {
      date: Date | number | string;
      years: number;
      format: 'iso-date' | 'iso-datetime';
    }): Date | string => {
      const currentDate = new Date(date);
      const newDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + years));
      switch (format) {
        case 'iso-date':
          return newDate.toISOString().substring(0, 10);
        case 'iso-datetime':
          return newDate.toISOString();
        default:
          return newDate;
      }
    },
  },
  jsToXml: <T extends xmljs.ElementCompact>(js: T) => xmljs.js2xml(js, { compact: true }),
  xmlToJs: <T>(xml: string) => xmljs.xml2js(xml, { compact: true }) as T,
};

const liquid = new Liquid();
liquid.registerFilter('show_if_true', (condition: boolean | string, text: string) =>
  condition === true || (_.isString(condition) && _.toLower(condition) === 'true') ? text : ''
);
export { liquid };

export function encodeQueryParams(
  // TODO: type more like this, doesn't seem to work with static interface keys
  obj?: { [key: string]: any }
) {
  if (!obj) return '';
  const params = [];
  for (const key in obj) {
    const value = obj[key];
    if (value) {
      params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
    }
  }
  return params.join('&');
}

export function isIsoDate(str: string) {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str);
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const storage = {
  async clear() {
    return localforage.clear();
  },
  async getItem<T>(id: string) {
    return localforage.getItem(id) as unknown as T | null;
  },
  async removeItem(id: string) {
    return localforage.removeItem(id);
  },
  async setItem(id: string, value: any) {
    return localforage.setItem(id, value);
  },
};

export function splitWords({ max = 100, text }: { max?: number; text: string }) {
  const arr = text?.split(' ') || [];
  const result = [];
  let subStr = arr[0] || '';
  for (let i = 1; i < arr.length; i++) {
    const word = arr[i];
    if (subStr.length + word.length + 1 <= max) {
      subStr = subStr + ' ' + word;
    } else {
      result.push(subStr);
      subStr = word;
    }
  }
  if (subStr.length) {
    result.push(subStr);
  }
  return result;
}

export const isApiError = (x: any): x is errorTypes.ErrorApi => {
  return typeof x.code === 'number';
};

export function handleError(err: any, fallbackMessage?: string) {
  // const err = _err as apiTypes.Error;
  if (IS_DEVELOPMENT) {
    console.error('ERROR: ', { message: err.message, data: err?.response?.data });
  }
  console.log(err.message);
  const data = err?.response?.data;
  const details = data?.details;
  const message =
    err?.message ||
    data?.message ||
    fallbackMessage ||
    (details ? 'Error, press for details' : 'Error, please try again');

  if (data && 'type' in data) {
    return {
      details,
      message,
      type: data.type,
    };
  }
  return {
    details,
    message,
  };
}

export function formatDate(date: Date, format: 'YYYYMMDDHHmmSS') {
  switch (format) {
    case 'YYYYMMDDHHmmSS':
      return _.chain(date.toISOString())
        .split('.')
        .first()
        .replace(/(T|-|:)/g, '')
        .value();
    default:
      return assertExhaustive<string>(format);
  }
}

export function removeUndefined<T>(obj: object): T {
  return _.pickBy(obj, (v) => v !== undefined) as unknown as T;
}

export type CallbackRoute =
  | 'filing-ucc-xml-az'
  | 'filing-ucc-xml-ca'
  | 'filing-ucc-xml-il'
  | 'filing-ucc-xml-ma'
  | 'filing-ucc-xml-ny';
export type TokenType = 'api' | 'callback';
export type TokenPayload =
  | { type: 'api'; companyId: string; userId: string }
  | {
      type: 'callback';
      companyId: string;
      route: CallbackRoute;
    };

export function assertExhaustive<T>(value: never, message?: string): T {
  throw new Error(message || `Reached unexpected case in exhaustive switch: ${value}`);
}

export function generateId() {
  return uuidv4();
}
