import { ApiClient, APP_URL, useMount } from '@client';
import { Button, Icon, TextInput, View } from '@client/elements';
import { storage } from '@shared';
import React, { useCallback, useState } from 'react';

export type Props = {
  onAuth: (url: string) => void;
};

export function AuthButtonZendesk({ onAuth }: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [subdomain, setSubdomain] = useState('');

  useMount(async () => {
    const _subdomain = await storage.getItem<string>('zendeskSubdomain');
    if (_subdomain) {
      setSubdomain(_subdomain);
    }
  });

  const handlePress = useCallback(async () => {
    if (subdomain) {
      await storage.setItem('zendeskSubdomain', subdomain);
      const {
        data: { url },
      } = await ApiClient.auth.url.post({
        provider: 'zendesk',
        // redirect: `${APP_URL}/auth/callback/zendesk`,
        redirect: `${APP_URL}/auth/zendesk`, // Temporarily changing this until global oauth client updated
        subdomain,
      });
      onAuth(url);
    } else {
      setErrorMessage('Subdomain is required');
    }
  }, [onAuth, subdomain]);

  return (
    <View sx={{ width: 230 }}>
      <Button
        disabled={!subdomain}
        icon={<Icon height={30} name="zendesk-icon" width={30} />}
        label="Zendesk Sign In"
        onPress={handlePress}
        sx={{ width: 220 }}
        variant="outlined"
      />
      <TextInput
        errorMessage={errorMessage}
        label="Subdomain"
        onChangeText={(v) => {
          setSubdomain(v);
          if (v) setErrorMessage('');
        }}
        sx={{ width: 220 }}
        value={subdomain}
      />
    </View>
  );
}
