import { FlatList as DripsyFlatList, SxProp, View } from 'dripsy';
import { ListRenderItem } from 'react-native';

export interface FlatListProps<T> {
  data: Partial<T & { onPress?: () => void }>[];
  height?: number;
  renderItem: ListRenderItem<T & { onPress?: () => void }>;
  sx?: SxProp;
}

export function FlatList<T>({ height, renderItem, sx, ...props }: FlatListProps<T>) {
  const _renderItem = renderItem as ListRenderItem<unknown>; // Dripsy doesn't seem to recognize the generic type
  return (
    <View
      sx={{
        flex: 1,
        ...(height && { height, maxHeight: height }),
      }}
    >
      <DripsyFlatList renderItem={_renderItem} sx={{ bg: '$light', ...sx }} {...props} />
    </View>
  );
}
