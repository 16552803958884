import { useMount, VariableClient } from '@client';
import { useUser } from '@client/context';
import * as companyTypes from '@shared/company/types';
import * as connectionTypes from '@shared/connection/types';
import * as environmentTypes from '@shared/environment/types';
import * as userTypes from '@shared/user/types';
import * as variableConstants from '@shared/variable/constants';
import * as variableTypes from '@shared/variable/types';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

interface Props {
  children: React.ReactNode;
  company: companyTypes.Company;
  connection?: connectionTypes.Connection; // This will change frequently in sidekick
  environment: environmentTypes.Environment;
  stepId?: string;
  user: userTypes.User;
}

interface Value {
  getCache: () => variableTypes.Cache;
  resetCache: () => variableTypes.Cache;
  updateCache: (cache: variableTypes.Cache) => variableTypes.Cache;
  //
  onRequestResults: <T = variableTypes.Values>(payload: variableTypes.ResultsRequest) => Promise<T>;
  options: variableTypes.Option[];
  // ready: boolean;
  // trigger: boolean; // Used to indicate that the results just came in the current state update, helpful to make sure we only run certain effects once
  // values: variableTypes.Values | null;
}

const VariableContext = React.createContext<Value | undefined>(undefined);

export function VariableProvider({ children, company, connection, environment, user }: Props) {
  const cacheRef = useRef<variableTypes.Cache>({ fields: {}, labels: {}, values: {} });

  const getCache = useCallback(() => {
    return cacheRef.current;
  }, []);
  const resetCache = useCallback(() => {
    cacheRef.current = { fields: cacheRef.current.fields, labels: {}, values: {} };
    return cacheRef.current;
  }, []);
  const updateCache = useCallback((update: variableTypes.Cache) => {
    cacheRef.current = update;
    return cacheRef.current;
  }, []);

  const { connectionId } = useUser();

  const [options, setOptions] = useState<variableTypes.Option[]>(variableConstants.DEFAULT_OPTIONS);
  // const [values, setValues] = useState<variableTypes.Values | null>(null);

  // const previousValues = usePrevious(values);

  const handleRequestResults = useCallback(
    async (payload: variableTypes.ResultsRequest) => {
      if (connectionId) {
        const cache = resetCache(); // Reset values before requesting results
        const results = await VariableClient.fetchResults({
          cache,
          companyId: company.id,
          connectionKey: connectionId,
          environment,
          request: payload,
          user,
        });
        updateCache(cache);
        return results;
      }
    },
    [company, connectionId, environment, resetCache, updateCache, user]
  ) as Value['onRequestResults'];

  const handleUpdateOptions = useCallback(async (sources: variableTypes.Sources) => {
    if (sources.zendesk) {
      // TODO: Return zendesk field options
    }
  }, []);

  // const ready = useMemo(() => !!values, [values]);
  // const trigger = useMemo(() => !!values && !previousValues, [previousValues, values]);

  const value: Value = useMemo(
    () => ({
      getCache,
      resetCache,
      updateCache,
      //
      onRequestResults: handleRequestResults,
      options,
      // ready,
      // trigger,
      // values,
    }),
    [
      getCache,
      resetCache,
      updateCache,
      //
      handleRequestResults,
      options,
      // , ready, trigger, values
    ]
  );

  useMount(async () => {});

  // Each time the step changes within sidekick, we want to reset the values
  // useEffect(() => {
  //   setValues(null);
  // }, [stepId]);

  return <VariableContext.Provider value={value}>{children}</VariableContext.Provider>;
}

export const useVariable = () => {
  const context = useContext(VariableContext);
  if (context === undefined) {
    throw new Error('useVariable must be within VariableProvider');
  }
  return context;
};
