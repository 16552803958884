import { useMount, SxProp } from '@client';
import { Icon, Pressable, SelectSingle, Text, TextInput, View } from '@client/elements';
import { useForm } from '@client/form';
// import { TextInput } from 'dripsy';
import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Animated } from 'react-native';

interface Props<T extends number | string> {
  disabled?: boolean;
  error?: string;
  multiple?: boolean;
  label?: string;
  name: string;
  options: { label: string; value: number | string }[];
  placeholder?: string;
  onSelect?: (value: T) => void;
  sx?: SxProp;
}

export default function FieldSelectSingle<T extends number | string>({
  label,
  multiple,
  name,
  onSelect,
  options,
  sx,
}: Props<T>) {
  const { getValue, onChangeFieldValue } = useForm();

  const [value, setValue] = useState(getValue(name));

  const handleChange = useCallback(
    (_value) => {
      onChangeFieldValue(name, _value);
      setValue(_value);
      onSelect?.(_value);
    },
    [name, onChangeFieldValue, onSelect]
  );

  return <SelectSingle label={label} onChange={handleChange} options={options} value={value} />;
}
