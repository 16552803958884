import * as connectionTypes from '@shared/connection/types';
import * as flowConstants from '@shared/flow/constants';
import * as flowTypes from '@shared/flow/types';
import * as providerTypes from '@shared/provider/types';
import * as stepTypes from '@shared/step/types';
import _ from 'lodash';
import { nanoid } from 'nanoid';

export function buildStepsArray({
  decisions,
  version,
}: {
  decisions: connectionTypes.Decisions;
  version: flowTypes.Version;
}): stepTypes.Step[] {
  const steps: stepTypes.Step[] = [];

  const appendNextStep = (currentStep: stepTypes.Step) => {
    const currentLinks = version.links?.[currentStep.uid] || [];
    let nextStep: stepTypes.Step | null = null;
    if (currentLinks.length === 1) {
      // No decision needs to be made
      nextStep = version.steps?.[currentLinks[0]] || null;
    } else if (currentLinks.length > 1) {
      // Decision must have been made
      const currentDecision = decisions?.[currentStep.uid] ?? null; // Important that we keep 0 as a number here
      nextStep =
        (typeof currentDecision === 'number' && version?.steps?.[currentLinks[currentDecision]]) ||
        null;
    }
    if (nextStep) {
      steps.push(nextStep);
      appendNextStep(nextStep);
    }
  };

  const rootStep = (version.root && version.steps?.[version.root]) || null;
  if (rootStep) {
    steps.push(rootStep);
    appendNextStep(rootStep);
  }

  return steps;
}

export const createDocumentFlow = (flow: flowTypes.Flow): flowTypes.Flow => {
  return {
    id: flow.id || '',
    categoryId: flow.categoryId || '',
    createdAt: flow.createdAt || new Date().toISOString(),
    criteriaActive: flow.criteriaActive || false,
    criteriaBuckets: flow.criteriaBuckets || [],
    criteriaMatchAutoStart: flow.criteriaMatchAutoStart || false,
    criteriaMatchNotificationPlainText: flow.criteriaMatchNotificationPlainText || '',
    criteriaMatchSidekickRichText: flow.criteriaMatchSidekickRichText || '',
    deletedAt: flow.deletedAt || null,
    description: flow.description || '',
    filterActive: flow.filterActive || false,
    filterCriteria: flow.filterCriteria || [null],
    filterGroups: flow.filterGroups || [],
    isAutoRunEligible: flow.isAutoRunEligible || false,
    isDeleted: flow.isDeleted || false,
    isFavorite: flow.isFavorite || false,
    sortPriority: flow.sortPriority || 0,
    title: flow.title || '',
    versionDraftId: flow.versionDraftId || '0',
    versionPublishedId: flow.versionPublishedId || '0',
  };
};

export function createGettingStartedCategory() {
  return {
    sortOrder: null,
    title: 'Welcome to BrightReps',
  };
}

export function createGettingStartedFlow(categoryId: string) {
  return {
    categoryId,
    criteriaActive: false,
    criteriaBuckets: [],
    description:
      'Welcome to BrightReps Sidekick! Here is how to get up and running with your new Sidekick.',
    filterGroups: [],
    isFavorite: false,
    sortPriority: 0,
    title: 'Getting set up on Sidekick',
    versionDraftId: '0',
    versionPublishedId: '0',
  };
}

export function createGettingStartedVersion() {
  const links: { [uid: string]: string[] } = {};
  const steps: { [uid: string]: stepTypes.Step } = {};
  const uids = [];

  let i = 0;
  for (; i < 6; i++) {
    uids.push(nanoid());
  }

  const root = uids[0];

  steps[uids[0]] = {
    name: 'RichText',
    props: {
      richtext:
        '<p>Visit the <a href="https://app.brightreps.com/" target="_blank">BrightReps Admin Portal</a> to setup your company for Sidekick.&nbsp;Using this tutorial we will show you how to:</p><ol><li>Update your company information</li><li>Build your processes on Flow Builder</li><li>Configure your integrations in the Integrations Lab</li><li>Invite&nbsp;other team members</li></ol><p>For additional information on the Admin Portal you can reference the <a href="https://hello.brightreps.com/knowledge-base/new-admin-page" target="_blank">Admin Portal Guide</a></p><p><img src="https://firebasestorage.googleapis.com/v0/b/brightreps.appspot.com/o/images%2Fadmin-portal.jpg?alt=media&token=3b52cccb-f30e-4b14-a6aa-64376e87ad3c"></p>',
      title: 'Visit the BrightReps Admin Portal',
    },
    uid: uids[0],
  };
  steps[uids[1]] = {
    name: 'RichText',
    props: {
      richtext:
        '<p>From the Admin Portal home page, select "My Company" to view or update company settings, billing history, and your company user roster.</p><ol><li>Company Settings - View and update Company Name and BrightReps Plan</li><li>Billing - View billing history for user licenses and transactional services (ex. shipping label)</li><li>Manage Team - View and update user permissions and status for all users at your company</li></ol><p><img src="https://firebasestorage.googleapis.com/v0/b/brightreps.appspot.com/o/images%2Fmanage-team.jpg?alt=media&token=29b420a4-d4c9-4138-b3dd-437871ed35bb"></p>',
      subtitle: 'Select "Company" to update company information',
      title: 'Company',
    },
    uid: uids[1],
  };
  steps[uids[2]] = {
    name: 'DecisionTree',
    props: {
      decisions: [{ label: 'Yes' }, { label: 'No' }],
      title: 'Do you want to use Sidekick integrations?',
    },
    uid: uids[2],
  };
  steps[uids[3]] = {
    name: 'RichText',
    props: {
      richtext:
        '<ol><li>From the Admin Portal home page, select "Integrations Lab"</li><li>Select the corresponding tile of the integration you want to setup</li><li>Visit the <a href="https://hello.brightreps.com/knowledge-base" target="_blank">BrightReps Resource Center</a> for instructions on how to obtain the API credentials for each integration you wish to setup (on the respective integration page under Products &amp; Features)</li><li>Enter the required API credentials for that integration.&nbsp;Once setup, the tile will turn green to indicate that the integration is now enabled.&nbsp;</li></ol>',
      subtitle: 'Select Integrations Lab to start enter required credentials for integrations',
      title: 'Integrations Lab',
    },
    uid: uids[3],
  };
  steps[uids[4]] = {
    name: 'RichText',
    props: {
      richtext:
        '<ol><li>From the Admin Portal home page, select "Flow Builder"</li><li>From the Flow Manager page, select "New Category" to create a new process flow category</li><li>Double click or select the select icon on a flow to update the flow on Flow Builder</li><li>From the Flow Builder page, click "+" on the left to add a step and select the step type to add</li><li>Steps can be re-arranged by dragging and dropping from the left</li><li>Click "Publish Changes" to publish changes real-time to Sidekick</li></ol><p><img src="https://firebasestorage.googleapis.com/v0/b/brightreps.appspot.com/o/images%2Fflow-builder.jpg?alt=media&token=0fcbecfa-d7f3-44a3-92d9-ef1668f9c497"></p><p>For additional information on step types visit the <a href="https://hello.brightreps.com/knowledge-base/new-admin-page" target="_blank">Admin Portal Reference Guide</a></p>',
      subtitle: 'Select flow builder to start creating flows to use on Sidekick',
      title: 'Flow Builder',
    },
    uid: uids[4],
  };
  steps[uids[5]] = {
    name: 'RichText',
    props: {
      richtext:
        '<ol><li>From the Admin Portal home page, select "Flow Builder"</li><li>From the Flow Manager page, select "New Category" to create a new process flow category</li><li>Double click or select the select icon on a flow to update the flow on Flow Builder</li><li>From the Flow Builder page, click "+" on the left to add a step and select the step type to add</li><li>Steps can be re-arranged by dragging and dropping from the left</li><li>Click "Publish Changes" to publish changes real-time to Sidekick</li></ol><p><img src="https://firebasestorage.googleapis.com/v0/b/brightreps.appspot.com/o/images%2Fflow-builder.jpg?alt=media&token=0fcbecfa-d7f3-44a3-92d9-ef1668f9c497"></p><p>For additional information on step types visit the <a href="https://hello.brightreps.com/knowledge-base/new-admin-page" target="_blank">Admin Portal Reference Guide</a></p>',
      subtitle: 'Select flow builder to start creating flows to use on Sidekick',
      title: 'Flow Builder',
    },
    uid: uids[5],
  };

  links[uids[0]] = [uids[1]];
  links[uids[1]] = [uids[2]];
  links[uids[2]] = [uids[3], uids[5]];
  links[uids[3]] = [uids[4]];
  links[uids[4]] = [flowConstants.EMPTY_NODE_PLACEHOLDER];
  links[uids[5]] = [flowConstants.EMPTY_NODE_PLACEHOLDER];

  return {
    root,
    links,
    steps,
  };
}

export function findFirstIncompleteStep({
  connectionFlowVersion,
  steps,
}: {
  connectionFlowVersion: connectionTypes.Version;
  steps: stepTypes.Step[];
}): stepTypes.Step | null {
  for (const step of steps) {
    if (!connectionFlowVersion?.steps?.complete) {
      return step;
    }
  }
  return null;
}

export const isFlowAutoRunEligible = (version: flowTypes.Version | null) => {
  if (!version?.steps || _.isEmpty(version.steps)) return false;

  return _.every(version.steps, (s: stepTypes.Step) => {
    // Skip over blank steps
    if (s.name.length === 0) {
      return true;
    }
    // Only some provider steps are currently auto-run
    if (
      s.name === 'ProviderStep' &&
      flowConstants.AUTO_RUN_PROVIDER_STEPS.includes(s.props?.provider?.slug as any)
    ) {
      return true;
    }
    // Find all auto-run-step matches
    return flowConstants.AUTO_RUN_STEPS.includes(s.name);
  });
};

export function willFlowBeComplete({
  connectionFlowVersion,
  decisions,
  stepId,
  version,
}: {
  connectionFlowVersion: connectionTypes.Version;
  decisions: connectionTypes.Decisions;
  stepId: string;
  version: flowTypes.Version;
}) {
  const nextConnectionFlowVersion = {
    ...connectionFlowVersion,
    steps: {
      ...connectionFlowVersion?.steps,
      [stepId]: { complete: true, inProgress: false },
    },
  };
  const nextSteps = buildStepsArray({ decisions, version });
  return !findFirstIncompleteStep({
    connectionFlowVersion: nextConnectionFlowVersion,
    steps: nextSteps,
  });
}
