import { Icon, IconButton, IconProps, Pressable, Text, View } from '@client/elements';
import { useState } from 'react';

export interface MenuProps {
  options: {
    iconName: IconProps['name'];
    label: string;
    onPress: () => void;
  }[];
}

function Options({ onClose, options }: { onClose: () => void; options: MenuProps['options'] }) {
  return (
    <View
      sx={{
        borderColor: '$neutral',
        borderRadius: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        minWidth: 200,
        right: 0,
        top: 35,
      }}
    >
      <View
        sx={{
          position: 'relative',
        }}
      >
        {options.map((option, index) => (
          <Pressable
            key={index}
            onPress={() => {
              option.onPress();
              onClose();
            }}
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              minHeight: 50,
              p: '$2',
            }}
          >
            <Icon name={option.iconName} size={40} sx={{ mr: '$2' }} />
            <Text variant="h3">{option.label}</Text>
          </Pressable>
        ))}
      </View>
    </View>
  );
}

export function Menu({ options }: MenuProps) {
  const [open, setOpen] = useState(false);
  return (
    <View sx={{ position: 'relative', zIndex: 9 }}>
      <IconButton
        name="menu-outline"
        onPress={() => setOpen((s) => !s)}
        sx={{
          borderColor: '$neutral',
          borderRadius: 5,
          borderStyle: 'solid',
          borderWidth: 1,
          padding: '$1',
        }}
      />
      {open && <Options onClose={() => setOpen(false)} options={options} />}
    </View>
  );
}
