import { AuthClient } from '@client';
import { Header, IconButton, View } from '@client/elements';
import React from 'react';

export interface Props {
  children: React.ReactNode;
}

export function TopNav({ children }: Props) {
  return (
    <View>
      <Header right={<IconButton name="exit" onPress={AuthClient.signOut} />} />
      {children}
    </View>
  );
}
