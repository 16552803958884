import {
  ApiClient,
  ErrorClient,
  PowerClient,
  SalesforceClient,
  handleError,
  useMount,
} from '@client';
import {
  CompanyProvider,
  UserProvider,
  VariableProvider,
  useCompany,
  useUser,
} from '@client/context';
import { Alert, Icon, IconButton, Loading, Menu, Text, View, Wizard } from '@client/elements';
import { ArenaCreateQualityProcess } from '@client/steps/ArenaCreateQualityProcess';
import { storage } from '@shared';
import * as companyTypes from '@shared/company/types';
import * as environmentTypes from '@shared/environment/types';
import * as integrationTypes from '@shared/integration/types';
import * as stepTypes from '@shared/step/types';
import * as userTypes from '@shared/user/types';
import _ from 'lodash';
import { useCallback, useState } from 'react';

import { Configure, ConfigureValues } from './Configure';
import { ConnectArena, ConnectArenaValues } from './ConnectArena';
import { Header } from './Header';

const powerq = 'salesforce-arena-quality';

interface Props {
  environment: environmentTypes.Environment;
  user: userTypes.User;
}

function SalesforceArenaQuality({ environment, user }: Props) {
  const { company, companyId } = useCompany();
  const { connectionId } = useUser();

  const [arenaEnabled, setArenaEnabled] = useState(false);
  const [config, setConfig] = useState<ConfigureValues>();
  // const [clientSalesforce, setClientSalesforce] = useState<integrationTypes.ClientCompanySummary>();
  const [error, setError] = useState<ErrorClient>();
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [updatingConfig, setUpdatingConfig] = useState(false);

  const handleCheckArena = useCallback(async () => {
    setLoading(true);
    const { data } = await ApiClient.company(companyId).integration('arena').get();
    console.log({ data });
    setArenaEnabled(data.enabled);
    setLoading(false);
  }, [companyId]);

  const handleConfigure = useCallback(
    async (values: ConfigureValues) => {
      await PowerClient.setConfig({ companyId, config: values, type: powerq });
      console.log('VALUES: ', values);
      const data = await PowerClient.fetchConfig({
        companyId,
        type: powerq,
      });
      setConfig(data);
      setUpdatingConfig(false);
    },
    [companyId]
  );

  const handleConnectArena = useCallback(
    async (values: ConnectArenaValues) => {
      try {
        await ApiClient.company(companyId)
          .integration('arena')
          .instance(values.workspaceId)
          .post({
            type: 'arena-company',
            data: {
              email: values.email,
              password: values.password,
              workspaceId: values.workspaceId,
            },
          });
        setArenaEnabled(true);
      } catch (err) {
        setError(handleError(err));
      }
      // setLoading(false);
    },
    [companyId]
  );

  useMount(async () => {
    await SalesforceClient.resize({ height: '500px' });
    await handleCheckArena();
    const data = await PowerClient.fetchConfig({
      companyId,
      type: powerq,
    });
    setConfig(data);
    setReady(true);
  });

  if (!ready || loading)
    return (
      <View sx={{ p: '$3', position: 'relative' }}>
        <Header />
        <Loading />
      </View>
    );

  // If client hasn't been created yet, render a component to connect arena
  if (!arenaEnabled) {
    return (
      <View sx={{ p: '$3', position: 'relative' }}>
        <Header />
        <ConnectArena companyId={companyId} onSubmit={handleConnectArena} />
        {error && <Alert {...error} />}
      </View>
    );
  }

  // If the step hasn't been configured yet, render a component to map the inputs
  if (!config || updatingConfig) {
    return (
      <View sx={{ p: '$3', position: 'relative' }}>
        <Header />
        <Configure initialValues={config} onSubmit={handleConfigure} />
        {error && <Alert {...error} />}
      </View>
    );
  }

  const step: stepTypes.Step<'ArenaCreateQualityProcess'> = {
    name: 'ArenaCreateQualityProcess',
    props: {
      autoComplete: false,
      autoRun: false,
      inputs: {
        attributes: _.reduce(
          config.arenaStepAttributes,
          (m, a) => ({
            ...m,
            [a.attributeId]: a.variableAccessor ? `<%= ${a.variableAccessor} %>` : '',
          }),
          {}
        ),
        description: config.arenaDescriptionVariableAccessor
          ? `<%= ${config.arenaDescriptionVariableAccessor} %>`
          : '',
        name: config.arenaNameVariableAccessor ? `<%= ${config.arenaNameVariableAccessor} %>` : '',
        ownerId: config.arenaOwnerId,
        prefixId: config.arenaPrefixId || '',
        templateId: config.arenaTemplateId,
        type: config.arenaProcessType || '',
      },
      title: 'Create Quality Process',
    },
    uid: '',
  };

  console.log({ step });

  return (
    <VariableProvider company={company} environment="salesforce" user={user}>
      <View sx={{ p: '$3', position: 'relative' }}>
        <Header onUpdateConfig={() => setUpdatingConfig(true)} />
        <ArenaCreateQualityProcess
          companyId={companyId}
          flowId=""
          onPrepareCompletion={() => console.log('PREPARE')}
          onStepComplete={async () => console.log('COMPLETE')}
          step={step}
          stepId=""
          userId={user.id}
        />
        {error && <Alert {...error} />}
      </View>
    </VariableProvider>
  );
}

export default function ({ environment, user, ...props }: Props) {
  const { company } = useCompany();
  return (
    <VariableProvider company={company} environment={environment} user={user}>
      <CompanyProvider company={company} userId={user.id}>
        <SalesforceArenaQuality environment={environment} user={user} {...props} />
      </CompanyProvider>
    </VariableProvider>
  );
}
