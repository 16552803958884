import { useMountedState } from '@client';
import { Button, Text, View } from '@client/elements';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

interface Props<Values> {
  children: React.ReactNode;
  disabled?: boolean;
  initialValues?: Partial<Values>;
  label?: string;
  labelSubmit?: string;
  onSubmit: (values: Values) => Promise<void> | void;
  schema?: { [key in keyof Values]?: { required?: boolean } | undefined }; // Optional validation
}

type Errors<Values = { [path: string]: string }> = {
  [key in keyof Values]?: string | undefined;
};

interface Value {
  errors?: Errors; // TODO: Pass Values type
  getValue: (path: string) => any;
  getValues: () => any;
  onChangeFieldValue: (field: string, value: any) => void;
}

const FormContext = React.createContext<Value | undefined>(undefined);

export function FormProvider<Values>({
  children,
  disabled,
  initialValues,
  label,
  labelSubmit,
  onSubmit,
  schema,
}: Props<Values>) {
  const [errors, setErrors] = useState<Errors<Values>>();
  const [loading, setLoading] = useState(false);

  const mounted = useMountedState();

  const getValues = useCallback(() => values.current as Values, []);
  const getValue = useCallback((path: string) => _.get(getValues(), path), [getValues]);
  const getErrors = useCallback(() => {
    const values = getValues();
    const _errors: Errors<Values> = {};
    _.forEach(schema, (s, f) => {
      if (s?.required && !_.get(values, f)) {
        _.set(_errors, f, 'Required');
      }
    });
    if (_.size(_errors) > 0) {
      return _errors;
    }
    return undefined;
  }, [getValues, schema]);

  const handleSubmit = useCallback(async () => {
    const _errors = getErrors();
    if (_errors) {
      setErrors(_errors);
    } else {
      setLoading(true);
      const values = getValues();
      await onSubmit(values);
    }

    if (mounted()) {
      setLoading(false);
    }

    // setLoading(false);
  }, [getErrors, getValues, mounted, onSubmit]);

  const onChangeFieldValue = useCallback((name, value) => {
    values.current = _.set(values.current || {}, name, value) as Values;
  }, []);

  const values = useRef<Partial<Values>>(initialValues || {});

  const value = useMemo(
    () => ({ errors, getValue, getValues, onChangeFieldValue }),
    [errors, getValue, getValues, onChangeFieldValue]
  );

  return (
    <FormContext.Provider value={value}>
      <View sx={{ display: 'flex', flexDirection: 'column', padding: '$2' }}>
        {label && (
          <Text sx={{ m: '$1', mb: '$2' }} variant="h3">
            {label}
          </Text>
        )}
        {children}
        <Button
          disabled={disabled}
          label={labelSubmit || 'Submit'}
          loading={loading}
          onPress={handleSubmit}
          sx={{ mt: '$3' }}
        />
      </View>
    </FormContext.Provider>
  );
}

export const useForm = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useForm must be within FormProvider');
  }
  return context;
};
