import { AuthClient, FirestoreClient } from '@client/firebase/services';
import { EXPO_PUBLIC_API_URL, EXPO_PUBLIC_APP_URL } from '@env';
import { IS_DEVELOPMENT, storage } from '@shared';
import * as apiTypes from '@shared/api/types';
import * as powerqTypes from '@shared/powerq/types';
import { DripsyProvider, makeTheme } from 'dripsy';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import { useCallback, useEffect, useRef, EffectCallback, useState } from 'react';
import { Platform } from 'react-native';

export { ApiClient } from '@client/api/services';
export { FirestoreClient } from '@client/firebase/services';
export { SalesforceClient } from '@client/salesforce/services';
export { VariableClient } from '@client/variable/services';

export { useStyle } from '@client/context/style';
export type { SxProp } from 'dripsy';
export { createParam } from 'solito';
export { useRouter } from 'solito/router';

export { AuthClient, DripsyProvider };

export const PowerClient = {
  fetchConfig: async <T extends powerqTypes.Type>({
    companyId,
    type,
  }: {
    companyId: string;
    type: T;
  }) => {
    const configDoc = await FirestoreClient.companyPowerq({ companyId }).doc(type).get();
    return configDoc.data() as powerqTypes.Config<T>;
  },
  setConfig: async <T extends powerqTypes.Type>({
    companyId,
    config,
    type,
  }: {
    companyId: string;
    config: powerqTypes.Config<T>;
    type: T;
  }) => {
    await FirestoreClient.companyPowerq({ companyId }).doc(type).set(config);
    return PowerClient.fetchConfig({ companyId, type });
  },
};

export interface ErrorClient {
  details?: object;
  message: string;
}

export const theme = {
  colors: {
    $dark: '#4B4847',
    $error: '#f44336',
    $light: 'white',
    $neutral: '#B0BEC5',
    $neutralDark: '#90A4AE',
    $neutralLight: '#CFD8DC',
    $primary: '#1776a7',
    $primaryDark: '#0E4666',
    $primaryLight: '#3BA3C7',
    $secondary: '#ffe8d6',
    $success: '#66bb6a',
  },
  space: {
    // recommended: set 0 first, then double for consistent nested spacing
    $0: 0,
    $1: 4,
    $2: 8,
    $3: 16,
    $4: 32,
    $5: 64,
    $6: 128,
    $7: 256,
  },
  fontSizes: {
    $0: 12,
    $1: 14,
    $2: 16,
    $3: 18,
    $4: 24,
    $5: 28,
    $6: 32,
  },
  button: {
    primary: {
      bg: '$primary',
    },
    outlined: {
      bg: '$light',
      borderRadius: 10,
      padding: '$1',
      margin: '$1',
    },
  },
  text: {
    caption: {
      color: '$neutralLight',
      fontSize: '$0',
    },
    h1: {
      fontSize: '$5', // 16px, from `fontSizes` above
    },
    h2: {
      fontSize: '$4',
    },
    h3: {
      fontSize: '$3',
    },
    h4: {
      fontSize: '$2',
    },
    h5: {
      fontSize: '$1',
    },
    label: {
      color: '$neutralDark',
      fontSize: '$2',
      mb: '$2',
    },
    p: {
      fontSize: '$0', // 12px from `fontSizes`
      mb: '$3', // 16px from `space`
    },
  },
};
// Augment the dripsy theme
type CustomTheme = typeof theme;
declare module 'dripsy' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DripsyCustomTheme extends CustomTheme {}
}

export const dripsyTheme = makeTheme<typeof theme>(theme);

export const API_URL =
  process.env.NEXT_PUBLIC_API_URL ||
  process.env.EXPO_PUBLIC_API_URL ||
  Constants?.expoConfig?.extra?.apiUrl ||
  EXPO_PUBLIC_API_URL;
export const APP_URL =
  process.env.NEXT_PUBLIC_APP_URL ||
  process.env.EXPO_PUBLIC_APP_URL ||
  Constants?.expoConfig?.extra?.appUrl ||
  Constants?.experienceUrl ||
  EXPO_PUBLIC_APP_URL;

console.log({ APP_URL });

export const PLATFORM = Platform.OS;

export function handleError(_err: any, fallbackMessage?: string) {
  const err = _err as apiTypes.Error;
  if (IS_DEVELOPMENT) {
    console.error('ERROR: ', { message: err.message, data: err?.response?.data });
  }
  console.log(err.message);
  const data = err?.response?.data;
  const details = data?.details;
  const message =
    err?.message ||
    data?.message ||
    fallbackMessage ||
    (details ? 'Error, press for details' : 'Error, please try again');

  if (data && 'type' in data) {
    return {
      details,
      message,
      type: data.type,
    };
  }
  return {
    details,
    message,
  };
}

export async function startOAuthSession(url: string) {
  /*const result = */ await WebBrowser.openAuthSessionAsync(url);
  const customToken = await storage.getItem('customToken');
  if (customToken) {
    await AuthClient.signInWithCustomToken(customToken as string);
  }
}

export function useEffectOnce(effect: EffectCallback) {
  useEffect(effect, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useError() {
  const [error, setError] = useState<{
    details?: object;
    message: string;
  }>();
  return {
    error,
    handleError: (err: any) => {
      setError(handleError(err));
    },
  };
}

export function useMount(fn: () => void) {
  useEffectOnce(() => {
    fn();
  });
}

export function useMountedState(): () => boolean {
  const mountedRef = useRef<boolean>(false);
  const get = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return get;
}

// Runs this function only when the current state is truthy while the previous wasn't
export function useTrigger(currentState: any, fn: () => void) {
  const previousState = usePrevious(currentState);
  useEffect(() => {
    if (!!currentState && !previousState) {
      fn();
    }
  }, [currentState, fn, previousState]);
}

export function useWhileMounted(): (fn: () => void | Promise<void>) => void {
  const mounted = useMountedState();
  const whileMounted = (fn: () => void | Promise<void>) => {
    if (mounted()) {
      fn();
    }
  };
  return whileMounted;
}

export function usePrevious<T>(state: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}

export function useUnmount(fn: () => any): void {
  const fnRef = useRef(fn);

  // update the ref each render so if it change the newest callback will be invoked
  fnRef.current = fn;

  useEffectOnce(() => () => fnRef.current());
}
