import { ScrollView as DripsyScrollView, SxProp } from 'dripsy';

export interface ScrollViewProps {
  children: React.ReactNode;
  sx?: SxProp;
}

export function ScrollView({ children, sx, ...props }: ScrollViewProps) {
  return null;
  // return (
  //   <DripsyScrollView sx={{ bg: '$light', ...sx }} {...props}>
  //     {children}
  //   </DripsyScrollView>
  // );
}
