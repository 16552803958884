import { DripsyProvider, dripsyTheme } from '@client';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { AppProvider } from './src/context/app';

export default function App() {
  return (
    <SafeAreaProvider>
      <DripsyProvider theme={dripsyTheme}>
        <AppProvider />
      </DripsyProvider>
    </SafeAreaProvider>
  );
}
